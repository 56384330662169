<div class="map-page__wrapper">
    <div class="map-page">
        <mgl-map
            class="map-page__map"
            movingMethod="jumpTo"
            [style]="'mapbox://styles/mapbox/light-v10'"
            [zoom]="[mapSettings.zoom]"
            [minZoom]="mapSettings.minzoom"
            [maxZoom]="mapSettings.maxzoom"
            [center]="mapSettings.center"
            [maxBounds]="mapSettings.bounds"
            [pitchWithRotate]="false"
            [dragRotate]="false"
            [renderWorldCopies]="true"
            [attributionControl]="false"
            (mapLoad)="mapboxLoad($event)"
            (mapClick)="mapFacade.closeListPanel()"
        >
            <mgl-control mglNavigation [showZoom]="true" [showCompass]="false"></mgl-control>

            <mgl-control mglAttribution
                [compact]="true"
                [customAttribution]="'© CityAir'"
            ></mgl-control>

            <!-- Subdivisions -->
            <mgl-vector-source
                id="subdivisions"
                url="mapbox://cityair.9u5spt6d"
            ></mgl-vector-source>

            <mgl-layer
                id="subdivisions"
                source="subdivisions"
                sourceLayer="subdivisions"
                type="fill"
                [paint]="{
                    'fill-color': regionsPaintProperties
                }"
            ></mgl-layer>

            <mgl-layer
                id="subdivisions-borders"
                source="subdivisions"
                sourceLayer="subdivisions"
                type="line"
                [paint]="{
                    'line-width': 1,
                    'line-color': 'rgba(3, 127, 3, 0.5)',
                    'line-opacity': 0.9
                }"
            ></mgl-layer>

            <mgl-layer
                id="subdivisions-borders-glow"
                source="subdivisions"
                sourceLayer="subdivisions"
                type="line"
                [paint]="{
                    'line-width': [
                        'case', ['boolean', ['feature-state', 'hover'], false],
                        2, 0
                    ],
                    'line-offset': 2,
                    'line-blur': 2,
                    'line-color': 'rgba(255, 255, 255, 0.8)'
                }"
            ></mgl-layer>

            <!-- Facility areas -->
            <mgl-geojson-source
                id="facilities"
                [data]="facilitiesGeoJson"
            ></mgl-geojson-source>

            <mgl-layer
                id="facilities"
                source="facilities"
                type="circle"
                [paint]="{
                    'circle-radius': [
                        'interpolate', ['linear'], ['zoom'],
                        5, ['+', ['/', ['get', 'emission'], 10], 12.6],
                        8, ['+', ['/', ['get', 'emission'], 7], 12],
                        12, ['get', 'emission']
                    ],
                    'circle-color': {
                        property: 'category',
                        type: 'categorical',
                        stops: emissionCategories.fill
                    },
                    'circle-opacity': 0.6,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': {
                        property: 'category',
                        type: 'categorical',
                        stops: emissionCategories.border
                    },
                    'circle-stroke-opacity': 0.9
                }"
            ></mgl-layer>

            <!-- Facility markers -->
            <ng-container *ngIf="facilitiesGeoJson?.features.length">
                <ng-container *ngFor="let f of facilitiesGeoJson.features">
                    <mgl-marker [lngLat]="f.geometry.coordinates">
                        <marker-circle
                            [color]="emissionCategories.point[f.properties.category]"
                        ></marker-circle>
                    </mgl-marker>

                    <ng-container *ngIf="selectedFacilityId === f.properties.id">
                        <mgl-marker [lngLat]="f.geometry.coordinates">
                            <marker-pin
                                [color]="emissionCategories.point[f.properties.category]"
                            ></marker-pin>
                        </mgl-marker>

                        <mgl-popup anchor="left"
                            [offset]="popupOffset"
                            [lngLat]="f.geometry.coordinates"
                            [closeButton]="false"
                            (close)="deselectFacility(f.properties.id)"
                        >
                            <popup-facility
                                [report]="selectMapReport(f.properties.id) | async"
                                (closeAction)="deselectFacility(f.properties.id)"
                            ></popup-facility>
                        </mgl-popup>
                    </ng-container>
                </ng-container>
            </ng-container>

            <!-- Subdivision/forestry markers -->
            <ng-container *ngIf="selectedSubdivision">
                <mgl-marker [lngLat]="selectedSubdivision.coordinates">
                    <marker-circle
                        [color]="selectedSubdivision.color"
                    ></marker-circle>
                </mgl-marker>

                <mgl-marker [lngLat]="selectedSubdivision.coordinates">
                    <marker-pin
                        [color]="selectedSubdivision.color"
                    ></marker-pin>
                </mgl-marker>

                <mgl-popup anchor="left"
                    [offset]="popupOffset"
                    [lngLat]="selectedSubdivision.coordinates"
                    [closeButton]="false"
                >
                    <popup-subdivision
                        [region]="selectedSubdivision.region"
                        [emission]="selectedSubdivision.emission"
                        (closeAction)="deselectSubdivision()"
                    ></popup-subdivision>
                </mgl-popup>
            </ng-container>
        </mgl-map>

        <ct-annual-timeline
            [years]="yearsList$ | async"
            [currentYear]="mapFacade.getCurrentYear()"
            [loadingYear]="loadingYear"
            (selectedYear)="updateYearlyData($event)"
        ></ct-annual-timeline>
    </div>

    <ca-sidebar [isActive]="isSidebarOpen">
        <ca-sidebar-toggle-button
            (toggle)="$event ? showPage() : hidePage()"
        ></ca-sidebar-toggle-button>

        <ng-container *ngIf="mapFacade.getListPanel() as panelName">
            <carbon-trail-list-panel
                [pageName]="panelName"
                (toClose)="mapFacade.closeListPanel()"
            >
                <regions-list
                    *ngIf="panelName === 'regions'"
                    [regions]="mapFacade.getRegions()"
                    [selectedRegionIds]="mapFacade.getSelectedRegionIds()"
                    (add)="mapFacade.addSelectedRegionIds($event)"
                    (remove)="mapFacade.removeSelectedRegionIds($event)"
                    (removeAll)="mapFacade.clearSelectedRegionIds()"
                ></regions-list>

                <emissions-list
                    *ngIf="panelName === 'emissions'"
                    [selectedEmissions]="mapFacade.getSelectedEmissions()"
                    (add)="mapFacade.addSelectedEmission($event)"
                    (remove)="mapFacade.removeSelectedEmissions($event)"
                    (removeAll)="mapFacade.clearSelectedEmissions()"
                ></emissions-list>

                <organizations-list
                    *ngIf="panelName === 'organizations'"
                    [selectedOrganizations]="summaries$ | async"
                    [organizations]="visibleROs"
                    (add)="addSelectedRO($event)"
                    (remove)="removeSelectedRO($event)"
                    (selectAll)="selectAllRO($event)"
                ></organizations-list>
            </carbon-trail-list-panel>
        </ng-container>

        <ca-page-container class="map-page__container">
            <router-outlet></router-outlet>
        </ca-page-container>
    </ca-sidebar>

    <div class="map-page__overlay">
        <div class="map-page__controls">
            <lang-selector [setLangCb]="changeLang" [isRu]="isRu"></lang-selector>
            <ct-logout [user]="getUser()" (logout)="logOut()"></ct-logout>
        </div>
    </div>
</div>
