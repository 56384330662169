<div class="strategy_page">
    <div class="title">{{TEXT.STRATEGY.title}}</div>
    <div class="name_document">
        <a class="name_document__link" download href="https://static.net-zero.cityair.io/assets/docs/Климатическая_программа_Сахалинской_области_2025_версия_25_07_2021.pdf">
            <div class="name_document__img_pdf"></div>
            <div class="name_document__text">{{TEXT.STRATEGY.nameDoc}}</div>
        </a>
        <div class="name_document__updated"> • {{TEXT.STRATEGY.updated}}</div>
        <div class="name_document__date_updated">13.09.2021</div>
    </div>
    <div class="sub_title">{{TEXT.STRATEGY.subTitle}}</div>
    <div class="text">
        <div class="text__string">В мире происходят крупные и быстрые перемены, связанные с переходом к низкоуглеродной модели устойчивого развития. </div>
        <div class="text__string">Для нас, как островного региона, последствия изменения климата несут серьезный риск, и мы острее, чем многие другие, ощущаем неотложную необходимость гармонии с нашей уникальной природой, сохранения ее красоты и биоразнообразия.</div>
        <div class="text__string">Забота о климате в современной парадигме напрямую увязана с переходом на климатически дружественные технологии, возобновляемые источники энергии и неископаемое топливо, прежде всего на водород.</div>
        <div class="text__string">Для экономики Сахалинской области набирающий обороты мировой энергетический переход является серьезным вызовом, поскольку добыча и экспорт нефти, газа, угля сейчас дают основной вклад в бюджет региона, создают (с учетом сопряженных отраслей) рабочие места и доходы для значительной части населения. </div>
        <div class="text__string">Происходящие в мире глобальные перемены непосредственно влияют на конкурентоспособность сахалинских предприятий и свидетельствуют о необходимости соответствующих преобразований во всех затрагиваемых сферах социально-экономического развития Сахалинской области.</div>
        <div class="text__string">В этих новых условиях мы сделали решительный шаг в направлении декарбонизации экономики, чтобы открыть новое будущее, чтобы обеспечить дальнейший рост региона. </div>
        <div class="text__string">Наша стратегия предусматривает достижение углеродной нейтральности Сахалинской области в 2025 году.</div>
        <div class="text__string">Достижение углеродной нейтральности обеспечивается конкретными мероприятиями по полной газификации региона местным природным газом, переводом значительной части транспорта на газомоторное и водородное топливо, увеличением доли электротранспорта с развертыванием соответствующей инфраструктуры. При этом мы опираемся на задел, который у нас был создан ранее в рамках целевых программ по энергосбережению и ресурсосбережению, газификации, а также подготовленных проектов модернизации коммунальных систем теплоснабжения, водоснабжения, канализации населенных пунктов области, модернизации системы управления отходами. </div>
        <div class="text__string">Особо хочу отметить масштабный комплексный проект реновации застройки областного центра – города Южно-Сахалинска. Новый генеральный план города создан российскими и зарубежными архитекторами с учетом новых требований к качеству жизни в городе, в том числе экологических и климатических требований. Мне приятно отметить, что г. Южно-Сахалинск в июне 2021 года присоединился к Глобальному соглашению мэров по вопросам климата и энергетики (Global Covenant of Mayors for Climate & Energy).</div>
        <div class="text__string">На период после 2025 года мы ставим целью переход к развитию с отрицательными нетто-выбросами. Мы намерены раскрыть пока еще мало использованный огромный потенциал возобновляемых источников энергии Сахалина и Курил, создать новую для нас отрасль водородной энергетики и проводить структурную перестройку экономики в пользу несырьевых отраслей с низкоуглеродными или безуглеродными производствами. Одновременно повышать поглощательную способность природных экосистем, в том числе создавать карбоновые лесные и морские фермы.</div>
        <div class="text__string">Климатическая программа Сахалинской области на период до 2025 года с перспективой до 2035 года является отправным базовым документом для реализации нашей политики декарбонизации экономики и развития чистой энергетики. Мы будем совершенствовать наши цели и инициативы с учетом научных знаний и тенденций в технологическом развитии, прислушиваться к мнению населения и бизнеса.</div>
        <div class="text__string">Сахалинская область получает поддержку со стороны Правительства Российской Федерации в качестве региона, проводящего эксперимент по пилотированию низкоуглеродных технологий и методов специального регулирования выбросов парниковых газов, формированию системы обращения углеродных единиц и квот. Готовится федеральный закон, который будет определять правовые основы применения специальных методов регулирования выбросов парниковых газов, включая их квотирование, на территории Сахалинской области. </div>
        <div class="text__string">Сахалинская область сотрудничает с другими регионами России и открыта для международного сотрудничества в этой важной и общей для всей планеты задачи сохранения климата и снижения климатических рисков.</div>
        <div class="text__string">Для достижения амбициозной цели углеродной нейтральности имеет большое значение слаженная совместная работа правительства области вместе с предприятиями и организациями. В регионе много предприятий, разделяющий ценности устойчивого развития и активно реализующих корпоративные стратегии сокращений выбросов парниковых газов.</div>
        <div class="text__string">Климатические изменения тесно связаны с нашей жизнью и коллективные действия позволят достичь целей в интересах общества и каждого жителя области.</div>
        <div class="text__string">Климатические изменения тесно связаны с нашей жизнью и коллективные действия позволят достичь целей в интересах общества и каждого жителя области.</div>
    </div>
    <div class="text text__valery_limarenko">{{TEXT.STRATEGY.valeryLimarenko}}</div>
    <div class="text">{{TEXT.STRATEGY.governorOfTheSakhalinRegion}}</div>
</div>