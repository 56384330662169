import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    FilterData,
    MultipleSearchfilterPipe,
    MultipleSearchFnsPipe,
    SafeHtmlPipe,
    SortingPipe,
    FilterByPipe,
    EmissionPipe,
    EmissionThousandPipe,
    DynPipe,
} from './pipes';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        DynPipe,
        EmissionPipe,
        EmissionThousandPipe,
        FilterData,
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        FilterByPipe,

    ],
    declarations: [
        DynPipe,
        EmissionPipe,
        EmissionThousandPipe,
        FilterData,
        MultipleSearchfilterPipe,
        MultipleSearchFnsPipe,
        SafeHtmlPipe,
        SortingPipe,
        FilterByPipe,
    ],
})
export class PipesModule {}
