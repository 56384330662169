import { Component } from '@angular/core';
import { ReportsFacadeService } from 'src/modules/core/services/facade/reports-facade.service';
import { TEXT } from 'src/locales/text';
import { POPUPS } from 'src/namespace';
import { ANIMATION_HIDE, ANIMATION_TOP } from 'src/config';
import { RO_MENU_ITEMS } from 'src/navigation/navigation-config';


@Component({
    selector: 'carbon-trail-manager-main',
    templateUrl: './ro-reports-page.component.html',
    styleUrls: ['../../styles/reports.less'],
    animations: [ANIMATION_TOP, ANIMATION_HIDE]
})
export class RoReportsPageComponent {
    TEXT = TEXT;
    POPUPS = POPUPS;

    RO_MENU_ITEMS = RO_MENU_ITEMS;

    constructor(public facade: ReportsFacadeService) {
        facade.mainStart();
        facade.getFacilities();
    }
}
