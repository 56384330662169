import { Component } from '@angular/core';

import { TEXT } from '../../../../locales/text';

@Component({
    selector: 'ct-balance-65-logo',
    templateUrl: 'balance-65-logo.component.html',
    styleUrls: ['balance-65-logo.component.less'],
})
export class Balance65LogoComponent {
    TEXT = TEXT;
}
