import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SidebarStateService {
    isActive: boolean;

    setStatus(isActive: boolean) {
        this.isActive = isActive;
    }
}
