import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators';

import { REPORT_YEAR } from 'src/config';
import { CARD_TYPE, EMISSIONS, Region } from 'src/namespace';
import { TEXT } from 'src/locales/text';
import { MapFacadeService } from 'src/modules/state-map/services/facade.service';
import { RoSummaryReportService } from 'src/store/services/ro-summary-report.service';
import { ROSummaryReport, Fadaster, Facility } from 'src/store/models';
import { FadasterService } from 'src/store/services/fadaster.service';
import { FacilityEmissionService } from 'src/store/services/facility-emission.service';

export class SelectedEmission {
    id: EMISSIONS;
    name: string;
}

@Component({
    selector: 'balance-excerpt-page',
    templateUrl: 'balance-excerpt-page.component.html',
    styleUrls: ['balance-excerpt-page.component.less']
})
export class BalanceExcerptPageComponent implements OnInit, OnDestroy {
    listSelectedRegion: Region[];
    listSelectedEmission: SelectedEmission[];

    TEXT = TEXT;
    CARD_TYPE = CARD_TYPE;
    REPORT_YEAR = REPORT_YEAR;

    tagsListLabels = {
        all: () => TEXT.others.all,
        expand: (n: number) => `${TEXT.others.expand} ${n}`,
        collapse: () => TEXT.others.collapse
    };

    onDestroy$ = new Subject<void>();

    summaries$: Observable<ROSummaryReport[]>;

    roWidgetConfig: {
        totalEmission: number;
        selectedList: string[];
        selectedEmission: number;
        selectedAll: boolean;
    } = {
        totalEmission: null,
        selectedList: null,
        selectedEmission: null,
        selectedAll: true
    };

    fadaster$: Observable<Fadaster>;

    constructor(
        readonly mapFacade: MapFacadeService,
        private roSummaryService: RoSummaryReportService,
        private fadasterService: FadasterService,
        private facilityEmissionService: FacilityEmissionService<Facility>,
    ) {
        this.summaries$ = roSummaryService.filteredEntities$;

        this.roSummaryService.entities$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((ss) => {
            this.roWidgetConfig.totalEmission = ss.reduce((acc, s) => acc + s.emissions, 0);
        });

        combineLatest([this.summaries$, this.facilityEmissionService.entities$]).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(([ss, fems]) => {
            const { size } = new Set(fems.map(f => f.facility.organization.id));

            const selectedAll = ss.length === size;

            this.roWidgetConfig = {
                ...this.roWidgetConfig,
                selectedEmission: ss.reduce((acc, s) => acc + s.emissions, 0),
                selectedList: !selectedAll ? ss.map(s => s.name) : [TEXT.cardName.regulatedSources],
                selectedAll
            };
        });

        this.fadaster$ = this.fadasterService.entities$.pipe(
            map(fs => fs.find(v => v.year === mapFacade.getCurrentYear())),
            filter(f => !!f)
        );
    }

    ngOnInit() {
        this.mapFacade.selectedYear$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((year) => {
            this.updateYearlyData(year);
        });
    }

    ngOnDestroy() {
        this.mapFacade.closeListPanel();

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    updateYearlyData(year: number) {
        this.fadasterService.getWithQuery({
            year: `${year}`
        });
    }
}
