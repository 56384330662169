import { Directive } from '@angular/core';

type Direction = -1 | 0 | 1;

export type SortingColumn<T> = {
    props: any;
    predicate: (d: T) => string | number | boolean;
    direction: Direction;
};

@Directive()
export class Sortable<T> {
    sortingColumns = [];

    sortingColumn: SortingColumn<T> = {
        props: null,
        predicate: null,
        direction: 0
    };

    sortBy(column: SortingColumn<T>, sortingColumns: SortingColumn<T>[]) {
        sortingColumns.map(sc => {
            if (sc.props.title !== column.props.title) {
                sc.direction = 0;
            }
        });

        column.direction = this.changeDirection(this.sortingColumn === column ? column.direction : 0);
        this.sortingColumn = column;
    }

    private changeDirection(direction: Direction) {
        return direction === 0 ? 1 : -direction as Direction;
    }
}
