<div class="popup-subdivision">
    <div class="popup-subdivision-icon"></div>
    <div class="popup-subdivision__content">
        <div class="popup-subdivision__name">{{region?.name}}</div>
        <div class="popup-subdivision__info">
            <ct-contribution-value type="sequestration" [value]="region?.sequestration"></ct-contribution-value>
            <ct-contribution-value type="emission" [value]="emission"></ct-contribution-value>
        </div>
    </div>
    <div class="popup-subdivision__actions">
        <ca-cross-button (click)="closeAction.emit()"></ca-cross-button>
    </div>
</div>
