<dropdown-menu
    [label]="TEXT.table.year"
    [value]="yearsItem?.label"
    [(showDropdown)]="yearsDropdownOpened"
    (clickOutside)="yearsDropdownOpened = false"
>
    <ca-selectbox-radio
        [(listItems)]="listYears"
        (listItemsChange)="filterBy($event); yearsDropdownOpened = false"
    ></ca-selectbox-radio>
</dropdown-menu>
