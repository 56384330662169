<div class="filter-block__wrapper">
    <carbon-trail-filter-block [arrOfStatuses]="TEXT.statusManager"
                               [arrYearsForFilter]="arrYearsForFilter"
                               [filterObject]="filterObjectChange"
                               [placeholder]="TEXT.others.searchManager"
                               (filterObjectChange)="getFilterObjectChange($event)"
    ></carbon-trail-filter-block>
    <ca-button
            class="btn_add_report"
            [type]="'primary'"
            (clickAction)="facade.goToCreateReport()"
            [disabled]="!emptySum"
    >
        <div class="btn_add_report__img"></div>
        <div class="btn_add_report__text">{{TEXT.header.createReport}}</div>
    </ca-button>
</div>
<div class="body-container scroll scroll-transparent">

    <carbon-trail-table-widget
            [ngClass]="{
                'widget': true,
                'widget-show': !filterObjectChange.year || filterObjectChange.year === REPORT_YEAR
            }"
            [arrOfStatuses]="TEXT.statusManager"
            [emptySum]="emptySum"
            [emissionSum]="numberProcessing(emissionSum)"
            [awaitingSum]="awaitingSum"
            [approvedSum]="approvedSum"
            [reworkSum]="reworkSum"
            [tableLength]="allCount"
            [lastYear]="REPORT_YEAR"
    ></carbon-trail-table-widget>

    <carbon-trail-table-manager
            [currentYear]="facade.getCurrentYear()"
            [filterStatus]="filterObjectChange.status"
            [filterText]="filterObjectChange.search"
            [filterYear]="filterObjectChange.year"
            [collectionUpdate]="collection.onUpdateReports$"
            (create)="facade.goToCreateReport($event)"
            (edit)="facade.goToEditReport($event)"
            (revoke)="revoke($event)"
    ></carbon-trail-table-manager>
</div>
