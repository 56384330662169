import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';

export type ButtonSize = 'small' | 'medium';

@Component({
    selector: 'ca-text-button',
    templateUrl: 'text-button.component.html',
    styleUrls: ['text-button.component.less']
})
export class TextButtonComponent {
    @Input() text: string;
    @Input() size: ButtonSize = 'medium';
    @Input() disabled?: boolean;
    @Output() clickAction = new EventEmitter<Event>();

    @HostListener('click', ['$event'])
    click(e: Event) {
        if (!this.disabled) {
            this.clickAction.emit(e);
        }
    }
}
