import { Component, Input } from '@angular/core';

@Component({
    selector: 'lang-selector',
    templateUrl: 'lang-selector.component.html',
    styleUrls: ['lang-selector.component.less']
})
export class LangSelectorComponent {
    @Input() setLangCb: (lang: string) => void;
    @Input() isMobileBanner: boolean;
    @Input() isRu: boolean;

    isOpenSelectLang = false;
}
