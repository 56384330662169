import { Component } from '@angular/core';
import {
    ROLE,
    TabModel
} from 'src/namespace';

import { AuthService } from 'src/modules/core/services/auth.service';
import {
    EXECUTIVE_MENU_ITEMS,
    RO_MENU_ITEMS
} from 'src/navigation/navigation-config';

@Component({
    selector: 'common-page',
    templateUrl: 'common-page.component.html',
    styleUrls: ['common-page.component.less']
})
export class CommonPageComponent {
    tabs: TabModel[];

    constructor(private authService: AuthService) {
        const { role } = this.getUser();

        this.tabs = {
            [ROLE.ro]: RO_MENU_ITEMS,
            [ROLE.executive]: EXECUTIVE_MENU_ITEMS,
            [ROLE.government]: EXECUTIVE_MENU_ITEMS,
        }[role] || [];
    }

    getUser() {
        return this.authService.getUser();
    }

    logOut() {
        this.authService.removeRefreshToken();
        this.authService.removeAccessToken();
        this.authService.logout();
        location.reload();
    }
}
