import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChevronComponent } from './chevron.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [ChevronComponent],
    exports: [ChevronComponent]
})
export class ChevronModule {}
