import { Component, OnInit, Input } from '@angular/core';
import { TEXT } from '../../../../../../locales/text';

@Component({
    selector: 'message-report-sent-error',
    templateUrl: './message-report-sent-error.component.html',
    styleUrls: ['./message-report-sent-error.component.less']
})
export class MessageReportSentErrorComponent implements OnInit {
    @Input() nameReport = '';

    TEXT = TEXT;

    constructor() {
    }

    ngOnInit(): void {
    }

}
