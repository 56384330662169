import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArrowModule } from '../arrow/arrow.module';
import { LangSelectorComponent } from './lang-selector.component';
import { DirectivesModule } from 'src/directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        ArrowModule,
    ],
    declarations: [LangSelectorComponent],
    exports: [LangSelectorComponent]
})
export class LangSelectorModule {}
