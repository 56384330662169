import {
    Component,
    Input,
} from '@angular/core';
import { Sorter } from 'src/libs/ui/sorting';
import { EmissionPipe } from 'src/pipes/pipes';


@Component({
    selector: 'ct-data-grid',
    templateUrl: './data-grid.component.html',
    styleUrls: ['../../../styles/table-common.less'],
    providers: [
        { provide: 'emissionPipe', useClass: EmissionPipe }
    ],
})
export class DataGridComponent {
    @Input() rows: any[];
    @Input() columns: any[];
    @Input() template;
    @Input() tableClass: string;
    @Input() trHeaderClass: string;
    @Input() trClass: string;
    @Input() sorter: Sorter<any>;
}
