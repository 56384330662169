import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TEXT } from '../../../../locales/text';

@Component({
    selector: 'carbon-trail-list-panel',
    templateUrl: './list-panel.component.html',
    styleUrls: ['./list-panel.component.less']
})
export class ListPanelComponent implements AfterViewInit {
    @Input() pageName: string;
    @Output() toClose: EventEmitter<void> = new EventEmitter();

    @ViewChild('scrollElem') scrollElem: ElementRef;

    isScrolled = false;

    TEXT = TEXT;

    constructor() {
    }

    ngAfterViewInit() {
        this.scrollElem.nativeElement.addEventListener('scroll', (e) => {
            this.isScrolled = !!this.scrollElem?.nativeElement.scrollTop;
        });
    }
}
