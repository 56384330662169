import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '../../directives/directives.module';
import { TooltipsModule } from '../tooltips/tooltips.module';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarToggleButtonComponent } from './components/sidebar-toggle-button/sidebar-toggle-button.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { SidebarStateService } from './sidebar-state.service';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        TooltipsModule,
    ],
    providers: [
        SidebarStateService,
    ],
    exports: [
        SidebarComponent,
        SidebarToggleButtonComponent,
        PageContainerComponent,
    ],
    declarations: [
        SidebarComponent,
        SidebarToggleButtonComponent,
        PageContainerComponent,
    ]
})
export class SidebarModule {}
