import { createAction } from '@ngrx/store';


export enum CoreActions {
    onUserLoggedIn = '[Carbon] onUserUpdated',
    onErrorCaught = '[Carbon] onErrorCaught',
}

export const onUserLoggedIn = createAction(
    CoreActions.onUserLoggedIn,
);

export const onErrorCaught = createAction(
    CoreActions.onErrorCaught,
);
