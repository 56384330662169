import { Component, Input } from '@angular/core';

export type ArrowSize = 'medium' | 'small';
export type ArrowDirection = 'up' | 'down' | 'left' | 'right';
export type ArrowType = 'default' | 'action';

@Component({
    selector: 'ca-arrow',
    templateUrl: 'arrow.component.html',
    styleUrls: ['arrow.component.less']
})
export class ArrowComponent {
    @Input() size: ArrowSize = 'medium';
    @Input() direction: ArrowDirection = 'down';
    @Input() type: ArrowType = 'default';
    @Input() disabled?: boolean;
}
