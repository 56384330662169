<div class="ro-page">
    <div class="ro-page__header">
        <h1 class="ro-page__title">{{TEXT.PAGE_TITLES.regulatedOrganizations}}</h1>

        <div class="ro-page__actions">
            <div class="ro-page__filters">
                <searchbar
                        class="ro-page__filters-searchbar"
                        [placeholder]="TEXT.others.search"
                        (textChange)="search($event)"
                ></searchbar>

                <selectbox-year
                        class="ro-page__filters-dropdown"
                        [list]="listYears"
                        (filterByItem)="filterByYear($event)"
                ></selectbox-year>

                <selectbox-organization-type
                        class="ro-page__filters-dropdown"
                        (filterByItem)="filterByOrgType($event)"
                ></selectbox-organization-type>
            </div>

            <button-add-item
                    class="ro-page__add-item"
                    *ngIf="canEdit"
                    caTooltip
                    caTooltipPlacement="top"
                    [caTooltipTemplate]="dummyButton"
            >
                {{TEXT.others.addOrganization}}
            </button-add-item>
        </div>
    </div>
    <div [ngClass]="{
        'ro-page__table scroll scroll-transparent': true
    }">
        <table-regulated-organizations
                [data]="summaries$ | async"></table-regulated-organizations>
    </div>
    <ng-template #dummyButton>
        <ca-tooltip [text]="TEXT.uoivBalance.dummyButton"></ca-tooltip>
    </ng-template>
</div>
