import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'src/pipes/pipes.module';

import { SortListItemModule } from 'src/components/sort-list-item/sort-list-item.module';
import { CoreModule } from 'src/modules/core/core.module';

import { TableNormativeActsComponent } from 'src/pages/table-normative-acts-page/table-normative-acts.component';
import { SearchResultPlaceholderModule } from 'src/components/search-result-placeholder/search-result-placeholder.module';

@NgModule({
    imports: [
        CommonModule,
        SortListItemModule,
        CoreModule,
        PipesModule,
        SearchResultPlaceholderModule,
    ],
    declarations: [
        TableNormativeActsComponent,
    ],
    exports: [TableNormativeActsComponent],
    providers: [],
})
export class TableNormativeActsModule {}
