import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Contact } from '../models';

@Injectable({ providedIn: 'root' })
export class ContactsService extends EntityCollectionServiceBase<Contact> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Contact', serviceElementsFactory);
    }
}
