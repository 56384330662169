<div class="balance-chart">
    <div class="balance-chart-wrapper">
        <canvas #chart
            [attr.height]="200"
            baseChart
            chartType="line"
            [datasets]="chartData"
            [options]="chartOptions"
            [plugins]="chartPlugins"
            [legend]="false"
            (chartClick)="chartClick($event)"
        ></canvas>

        <div class="balance-chart__runner"
            [style.visibility]="flagPosition.shift > 0 ? 'visible' : 'hidden'"
            [style.left.px]="flagPosition.left"
            [style.transform]="'translateX(' + flagPosition.shift + 'px)'"
            [style.height]="'calc(100% - ' + flagPosition.bottom + 'px)'"
            [style.bottom.px]="flagPosition.bottom"
        >
            <balance-chart-runner class="balance-chart__runner-flag">
                {{isPlanned(year) ? TEXT.uoivBalance.isPlanned : TEXT.uoivBalance.isFactual}}
            </balance-chart-runner>

            <balance-chart-point
                class="balance-chart__point"
                *ngFor="let p of overlayPoints"
                [color]="p.backgroundColor"
                [style.top.px]="p.y"
            ></balance-chart-point>

            <balance-chart-tooltip
                #tooltipElements
                class="balance-chart__point-tooltip"
                [class.balance-chart__point-tooltip--left]="isTooltipOverTheLeftHalf"
                *ngFor="let t of tooltips | sorting : tooltipsSortingPredicate : -1"
                [color]="t.backgroundColor"
                [style.top.px]="t.tooltipY"
            >
                {{t.value | number : '1.0-0'}}
            </balance-chart-tooltip>
        </div>
    </div>

    <div class="balance-chart__scale-label balance-chart__scale-label--y"
        [innerHTML]="TEXT.uoivBalance.kiloCO2Equiv"
        [style.top.px]="-scaleLabelPosition.y"
        [style.right.px]="-scaleLabelPosition.x"
    ></div>
</div>
