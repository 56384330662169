import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { REPORT_YEAR } from 'src/config';
import { EMISSIONS } from 'src/namespace';
import { ApiService } from '../../core/services/api/api.service';
import { CollectionService } from '../../core/services/collection/collection.service';

type ListPanelType = 'emissions' | 'regions' | 'organizations';

class State {
    currentYear: number;
    sequestration: number;
    other_emission: number;
    emission_reports: number;
    selectedRegionIds: number[] = [];

    selectedEmissions: EMISSIONS[] = [];

    listPanel: ListPanelType = null;
}

@Injectable({
    providedIn: 'root'
})
export class MapFacadeService {
    private state = new State();

    selectedYear$ = new BehaviorSubject<number>(REPORT_YEAR);

    constructor(private apiService: ApiService, private collection: CollectionService) {
        collection.onUpdateRegions$.subscribe((data) => {
            this.calcValues();
        });

        this.selectedYear$.subscribe((year) => {
            this.state.currentYear = year;
        });
    }

    getRegions = () => this.collection.getRegions();

    getSelectedRegionIds = () => this.state.selectedRegionIds;

    clearSelectedRegionIds = () => {
        this.state.selectedRegionIds = [];
        this.calcValues();
    }

    addSelectedRegionIds = (id: number) => {
        this.state.selectedRegionIds.push(id);
        this.calcValues();
    }

    removeSelectedRegionIds = (id: number) => {
        const index = this.state.selectedRegionIds.findIndex(_id => _id === id);
        this.state.selectedRegionIds.splice(index, 1);
        this.calcValues();
    }

    calcValues() {
        this.state.sequestration = this.collection.getRegionsAbsorption(this.state.selectedRegionIds);
        this.state.other_emission = this.collection.getRegionsEmissionOther(this.state.selectedRegionIds);
        this.state.emission_reports = this.collection.getRegionsEmissionReports(this.state.selectedRegionIds);
    }

    getBalanceAll() {
        return this.collection.getRegions().reduce((sum, r) => sum + r.emission_reports + r.other_emission - r.sequestration, 0);
    }

    getBalanceRegions() {
        return this.state.emission_reports + this.state.other_emission - this.state.sequestration;
    }

    getEmission() {
        const other = this.state.other_emission;
        const reports = this.state.emission_reports;

        if (!this.state.selectedEmissions.length)
            {return other + reports;}

        let sum = 0;

        if (this.state.selectedEmissions.includes(EMISSIONS.other_emission))
            {sum += other;}
        if (this.state.selectedEmissions.includes(EMISSIONS.emission_reports))
            {sum += reports;}

        return sum;
    }

    getAbsorption() {
        return this.state.sequestration;
    }

    async setCurrentYear(year: number) {
        this.selectedYear$.next(year);
    }

    getCurrentYear() {
        return this.state.currentYear;
    }

    getReportsForMap() {
        return this.collection.getMapReports();
    }

    getSelectedEmissions() {
        return this.state.selectedEmissions;
    }

    clearSelectedEmissions() {
        this.state.selectedEmissions = [];
    }

    addSelectedEmission(em: EMISSIONS) {
        this.state.selectedEmissions.push(em);
    }

    removeSelectedEmissions(em: EMISSIONS) {
        const index = this.state.selectedEmissions.findIndex(_id => _id === em);
        this.state.selectedEmissions.splice(index, 1);
    }

    toggleEmissions() {
        this.togglePanelPage('emissions');
    }

    toggleRegions() {
        this.togglePanelPage('regions');
    }

    toggleOrganizations() {
        this.togglePanelPage('organizations');
    }

    togglePanelPage(page: ListPanelType) {
        this.state.listPanel = this.state.listPanel === page ? null : page;
    }

    closeListPanel() {
        this.state.listPanel = null;
    }

    getListPanel() {
        return this.state.listPanel;
    }
}
