import { formatNumber } from '@angular/common';
import {
    Injector,
    Pipe,
    PipeTransform
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {
    }

    transform(value: string) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({
    name: 'multipleSearchfilter'
})
export class MultipleSearchfilterPipe implements PipeTransform {
    transform(items: any[], fields: string[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter(it => {
            const itsStr = fields.map(f => it[f]) + '';
            return itsStr.toLowerCase().indexOf(value.toLowerCase()) !== -1;
        });
    }
}

@Pipe({
    name: 'multipleSearchFns'
})
export class MultipleSearchFnsPipe implements PipeTransform {
    transform(items: any[], fields: ((item: any) => string)[], value: string): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null || value === '') {
            return items;
        }
        return items.filter(it => fields
            .map(fn => fn(it))
            .toString()
            .toLowerCase()
            .indexOf(value.toLowerCase()) !== -1
        );
    }
}

@Pipe({
    name: 'filterData'
})
export class FilterData implements PipeTransform {
    transform(items: any[], fields: string[], value: any[]): any {
        if (!items) {
            return [];
        }
        if (value === undefined || value === null) {
            console.error('FilterData value == undefined || value == null');
            return items;
        }
        if (fields.length !== value.length) {
            console.error('FilterData fields.length !== value.length');
            return items;
        }
        return items.filter(item => fields.every((field, i) => item[field] === value[i]));
    }
}

@Pipe({
    name: 'sorting'
})
export class SortingPipe implements PipeTransform {
    transform(items: any[], column: ((item: any) => string | number | boolean), direction = 1): any[] {
        if (!items) {
            return [];
        }

        if (!column) {
            return items;
        }

        return items.sort((a, b) => {
            const aValue = column(a);
            const bValue = column(b);

            const p1 = this.castToSortableValue(aValue);
            const p2 = this.castToSortableValue(bValue);

            if ((!p1 && p1 !== 0 && p1 !== '') || p1 > p2) {
                return Math.sign(direction);
            }

            if ((!p2 && p2 !== 0 && p1 !== '') || p1 < p2) {
                return -Math.sign(direction);
            }

            return 0;
        });
    }

    castToSortableValue = (val: any) => {
        if (typeof val === 'number') {
            return val;
        } else if (typeof val === 'undefined' || val === null) {
            return -1;
        } else {
            return val?.toString().toLowerCase();
        }
    }
}

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe<T> implements PipeTransform {
    transform(items: T[], predicate: (item: T) => boolean): T[] {
        return items?.length ? items.filter(predicate) : items;
    }
}

@Pipe({name: 'emission'})
export class EmissionPipe implements PipeTransform {
    transform(value?: number | string, digitsInfo?: string, locale?: string): string {
        if (typeof value === 'string') {
            if (value == '') {
                return '';
            }
            value = parseInt(value, 10);
        }
        if (!value && value !== 0) {
            return '';
        }
        return formatNumber(value, locale || 'ru_RU', '1.0-0');
    }
}

@Pipe({name: 'emission1k'})
export class EmissionThousandPipe implements PipeTransform {
    transform(value?: number, digitsInfo?: string, locale?: string): string {
        return formatNumber(Math.floor(value / 1000), locale || 'ru_RU', '1.0-0');
    }
}


@Pipe({
  name: 'dynPipe'
})
export class DynPipe implements PipeTransform {

    public constructor(private injector: Injector) {
    }

    transform(value: any, pipeToken: any): any {
        if (!pipeToken) {
            return value;
        }
        else {
            const pipe = this.injector.get(pipeToken);
            return pipe.transform(value);
        }
    }
}
