import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TEXT } from 'src/locales/text';

@Component({
    selector: 'ct-logout-menu',
    templateUrl: 'logout-menu.component.html',
    styleUrls: ['logout-menu.component.less']
})
export class LogoutMenuComponent {
    @Input() userName: string;
    @Input() email: string;
    @Output() logout = new EventEmitter<void>();

    TEXT = TEXT;
}
