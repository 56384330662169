import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputForDropdownModule } from '../input-for-dropdown/input-for-dropdown.module';
import { InputDropdownComponent } from './input-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        InputForDropdownModule,
    ],
    declarations: [InputDropdownComponent],
    exports: [InputDropdownComponent]
})
export class InputDropdownModule {}
