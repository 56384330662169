import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { SectorEmissionFadaster } from '../models';

@Injectable({ providedIn: 'root' })
export class SectorEmissionFadasterService extends EntityCollectionServiceBase<SectorEmissionFadaster> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('SectorEmissionFadaster', serviceElementsFactory);
    }
}
