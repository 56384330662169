import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { isRU, TEXT } from '../../../../locales/text';
import { AuthService } from '../../services/auth.service';
import { changeLang } from '../../../../utils';

@Component({
    selector: 'carbon-login-page',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.less']
})
export class LoginComponent {
    TEXT = TEXT;
    isRu = isRU;

    // reload перетирает эти переменные
    login: any;
    password: any;
    extraError: string;
    isWarning = false;
    loading = false;
    isDisplayPas = false;

    constructor(
        private authService: AuthService,
        private router: Router,
        private el: ElementRef
    ) {}

    async submit() {
        if (!this.login || !this.password) {
            return this.isWarning = true;
        }

        this.loading = true;
        const isOk = await this.authService.login(this.login.trim(), this.password);
        this.loading = false;

        if (!isOk) {
            this.isWarning = true;
        } else {
            this.router.navigate(['']);
        }
    }

    focusOrSubmit() {
        if (this.login && !this.password) {
            this.el.nativeElement.querySelector('input.ng-invalid').focus();
        } else {
            this.submit();
        }
    }

    changeLang = changeLang;
}
