import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';

export type ChangeDirectionType = 'up' | 'down' | 'unknown';

@Component({
    selector: 'ct-balance-change-indicator',
    templateUrl: './balance-change-indicator.component.html',
    styleUrls: ['./balance-change-indicator.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BalanceChangeIndicatorComponent implements OnChanges {
    @Input() previous!: number;

    @Input() current!: number;

    direction: ChangeDirectionType = 'unknown';

    ngOnChanges() {
        if (isNaN(this.previous)) {
            this.direction = 'unknown';
        } else {
            // if balance didn't change at all then this is a good sign so balance is going down
            this.direction = this.current > this.previous ? 'up' : 'down';
        }
    }
}
