import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'dropdown-menu',
    templateUrl: 'dropdown-menu.component.html',
    styleUrls: ['dropdown-menu.component.less'],
})
export class DropdownMenuComponent {
    @Input() label: string;

    @Input() value: string;

    @Input() showDropdown: boolean;

    @Output() showDropdownChange = new EventEmitter<boolean>();

    toggleDropdown() {
        this.showDropdownChange.emit(!this.showDropdown);
    }
}
