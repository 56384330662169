import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMapTo, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/modules/core/services/auth.service';
import { LoaderService } from 'src/modules/core/services/loader.service';

import { TEXT } from 'src/locales/text';
import { CheckboxItem, ROLE } from 'src/namespace';
import { joinFacilitiesToSectorsAndEmissions } from 'src/store/queries/facilities';
import { FacilityService } from 'src/store/services/facility.service';
import { FacilityYearlyViewModel } from 'src/store/models';
import { FacilityEmissionService } from 'src/store/services/facility-emission.service';
import { SectorService } from 'src/store/services/sector.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { REPORT_YEAR, YEARS_FOR_FILTER } from 'src/config';

@Component({
    selector: 'facilities-page',
    templateUrl: 'facilities-page.component.html',
    styleUrls: ['facilities-page.component.less'],
})
export class FacilitiesPageComponent implements OnInit {
    TEXT = TEXT;
    listYears: number[] = YEARS_FOR_FILTER
    // filter
    searchText = '';
    selectedSectorId = -1;

    joinedFacilities$: Observable<FacilityYearlyViewModel[]>;
    orgId$: Observable<string> = null;
    orgId: number;

    constructor(
        private facilitySrv: FacilityService,
        private facilityEmSrv: FacilityEmissionService<number>,
        private sectorSrv: SectorService,
        private viewContainerRef: ViewContainerRef,
        private loaderService: LoaderService,
        public route: ActivatedRoute,
        private authSrv: AuthService,
    ) {

        loaderService.waitLoadingComplete(
            viewContainerRef,
            [
                this.facilitySrv,
                this.facilityEmSrv,
                this.sectorSrv,
            ]
        );

        const {role, organization} = this.authSrv.getUser();
        if ( role === ROLE.ro && organization ) {
            this.orgId$ = of(organization.toString());
        } else {
            this.orgId$ = this.route.parent?.paramMap.pipe(
                map((params: ParamMap) => params.get('id')),
                take(1)
            );
        }
    }

    ngOnInit() {
        this.updateFilter();

        this.joinedFacilities$ = this.orgId$.pipe(
            tap(id => this.filterByOrgId(id)),
            tap(_ => this.loadData(REPORT_YEAR)),
        ).pipe(
            switchMapTo(joinFacilitiesToSectorsAndEmissions(
                this.facilitySrv.filteredEntities$,
                this.facilityEmSrv.entities$,
                this.sectorSrv.entities$,
            ))
        );
    }


    loadData(year: number) {
        this.facilityEmSrv.clearCache();
        this.facilitySrv.clearCache();
        this.facilitySrv.getAll();
        this.facilityEmSrv.getWithQuery({year: year.toString()});
    }

    search(searchText: string) {
        this.searchText = searchText;
        this.updateFilter();
    }

    filterByOrgId(orgId: string) {
        this.orgId = parseInt(orgId, 10);
        this.updateFilter();
    }

    filterByYear(item: CheckboxItem) {
        this.facilityEmSrv.clearCache();
        this.facilityEmSrv.getWithQuery({year: item.id.toString()});
    }

    filterByOrgType(item: CheckboxItem) {
        this.selectedSectorId = item.id as number;
        this.updateFilter();
    }

    updateFilter() {
        this.facilitySrv.setFilter({
            sector: this.selectedSectorId,
            text: this.searchText,
            organization: this.orgId,
        });
    }
}
