import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TEXT } from 'src/locales/text';
import { ANIMATION_OPACITY } from 'src/config';
@Component({
    selector: 'ct-error-popup',
    templateUrl: './error-popup.component.html',
    styleUrls: ['./error-popup.component.less'],
    animations: [ANIMATION_OPACITY]
})
export class ErrorPopupComponent implements OnInit {
    @Output() cbClose = new EventEmitter<boolean>()

    TEXT = TEXT;

    constructor() {
    }

    ngOnInit(): void {
    }

    close() {
        this.cbClose.emit();
    }
}


