import { Component, Input } from '@angular/core';

@Component({
    selector: 'marker-circle',
    templateUrl: 'marker-circle.component.html',
    styleUrls: ['marker-circle.component.less']
})
export class MarkerCircleComponent {
    @Input() color = 'white';
}
