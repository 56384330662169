import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Transaction } from '../models';

@Injectable({ providedIn: 'root' })
export class TransactionService extends EntityCollectionServiceBase<Transaction> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Transaction', serviceElementsFactory);
    }
}
