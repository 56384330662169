import {
    Component,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

import { CARD_TYPE } from 'src/namespace';

@Component({
    selector: 'balance-contribution-card',
    templateUrl: 'balance-contribution-card.component.html',
    styleUrls: ['balance-contribution-card.component.less']
})
export class BalanceContributionCardComponent {
    @Input() type: CARD_TYPE;
    @Input() label = '';
    @Input() showDetails = false;
    @Input() regulated = false;

    @Output() clickDetails = new EventEmitter<void>();

    @Output() removeTag = new EventEmitter<string>();
}
