import { Component, Input } from '@angular/core';
import { TableManagerComponent } from '../table-manager/table-manager.component';

import { STATUS_NAMES, TABLE_COLUMNS_NAME } from 'src/namespace';
import { TEXT } from 'src/locales/text';
import { SectorService } from 'src/store/services/sector.service';
import { EXECUTIVE_PAGES } from 'src/navigation/navigation-config';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, filter, switchMap } from 'rxjs/operators';
import { OrganizationService } from 'src/store/services/organization.service';
import { Organization } from 'src/store/models';

@Component({
    selector: 'carbon-trail-table-coord',
    templateUrl: './table-coord.component.html',
    styleUrls: ['../../../../../../styles/table-common.less']
})
export class TableCoordComponent extends TableManagerComponent {
    @Input() reportYear: number;
    @Input() canEdit: boolean;

    STATUS_NAMES = STATUS_NAMES;
    EXECUTIVE_PAGES = EXECUTIVE_PAGES;

    tableColumns = [
        {name: TABLE_COLUMNS_NAME.id, sort: true},
        {name: TABLE_COLUMNS_NAME.sector, sort: true, alignment: 'center'},
        {name: TABLE_COLUMNS_NAME.facility, sort: true},
        {name: TABLE_COLUMNS_NAME.organizationName, sort: true},
        {name: TABLE_COLUMNS_NAME.emission, sort: true, alignment: 'right'},
        {name: TABLE_COLUMNS_NAME.sent_at, sort: true},
        {name: TABLE_COLUMNS_NAME.approved_at, sort: true},
        {name: TABLE_COLUMNS_NAME.blame, sort: false},
        {name: TABLE_COLUMNS_NAME.comments, sort: false},
        {name: TABLE_COLUMNS_NAME.status, sort: true},
    ];

    header: {
        key: string;
        sort: -1 | 0 | 1 | undefined;
        name: string;
        props: {
            alignment: '' | 'left' | 'right' | 'center';
        };
    }[] = this.tableColumns.map(col => ({
            key: col.name,
            sort: col.sort ? (col.name === TABLE_COLUMNS_NAME.year ? 1 : 0) : undefined,
            name: TEXT.table[col.name],
            props: {
                alignment: col.alignment as '' | 'left' | 'right' | 'center'
            }
        }));

    organizationInfo: Organization;

    constructor(
        sectorService: SectorService,
        organizationService: OrganizationService,
        route: ActivatedRoute
    ) {
        super(sectorService);

        // TODO: works only inside RO listing
        // route.parent?.paramMap.pipe(
        //     map((params: ParamMap) => params.get('id')),
        //     filter(id => !!id),
        //     switchMap((orgId) => organizationService.getByKey(orgId + '/'))
        // ).subscribe((organization) => {
        //     this.organizationInfo = organization;
        // });
    }

    filterTable() {
        if (!this.tableData)
            {return;}

        this.table = this.tableData.filter(t => {
            const text = (this.filterText || '').toLowerCase();
            const nameFacility = (t.facility.name || '').toLowerCase();
            const nameCompany = (t.facility.organization?.name || '').toLowerCase();

            if (text && !(nameFacility.includes(text) || nameCompany.includes(text)))
                {return false;}
            if (this.filterStatus && t.status !== this.filterStatus)
                {return false;}
            if (this.filterYear && t.year !== this.filterYear)
                {return false;}

            // TODO: works only inside RO listing
            // if (!this.organizationInfo.facilities.includes(t.facility.id)) {
            //     return false;
            // }

            return true;
        });

        this.sort();
    }
}
