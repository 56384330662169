<div class="dropdown-menu">
    <div class="dropdown-menu-button" (click)="toggleDropdown()">
        <div class="dropdown-menu-label">{{label}}:</div>
        <div class="dropdown-menu-value">{{value}}</div>
        <ct-chevron [direction]="showDropdown ? 'up' : 'down'"></ct-chevron>
    </div>

    <div class="dropdown-menu-selectbox" *ngIf="showDropdown">
        <ng-content select="ca-selectbox-radio"></ng-content>
    </div>
</div>
