import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartPoint } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMapTo, takeUntil } from 'rxjs/operators';

import { LineStyles } from './chart-styles';
import { BALANCE_CHART_TYPE } from './balance-chart.settings';
import { REPORT_YEAR } from 'src/config';
import { TEXT } from 'src/locales/text';

const AXES_PADDINGS = {
    x: 20,
    y: 17
};

const TOOLTIPS_GAP_PX = 2;

type PointXY = {
    x: number;
    y: number;
};

type OverlayPoint = PointXY & {
    backgroundColor: string;
    value: number;
};

function createTooltipsPositions(ys: number[], h: number) {
    const pos: {
        tooltipY: number;
        y: number;
    }[] = [];

    ys.sort((a, b) => b - a).forEach((y, i) => {
        const prevPos = i ? pos[i - 1].y : Infinity;
        pos[i] = y + h < prevPos ? { tooltipY: y, y } : { tooltipY: prevPos - h, y };
    });

    return pos;
}

@Component({
    selector: 'balance-chart',
    templateUrl: 'balance-chart.component.html',
    styleUrls: ['balance-chart.component.less'],
})
export class BalanceChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() data: {
        data: ChartPoint[];
        options: {
            [key: string]: any;
        };
    }[];

    @Input() year: number;

    @Output() changeYear = new EventEmitter<number>();

    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    @ViewChildren('tooltipElements', { read: ElementRef }) tooltipElements: QueryList<ElementRef>;

    year$ = new BehaviorSubject<number>(0);

    flagPosition$ = new Subject<void>();

    onDestroy$ = new Subject<void>();

    TEXT = TEXT;

    chartData: ChartDataSets[] = [];

    chartOptions: ChartOptions;

    scaleLabelPosition = {
        x: 0,
        y: 0
    };

    flagPosition = {
        left: 0,
        shift: 0,
        bottom: 0
    };

    isTooltipOverTheLeftHalf = true;

    private _cachedScaleX: number[] = [];

    chartPlugins: Chart.PluginServiceRegistrationOptions[] = [
        {
            afterDraw: (chart) => {
                const xAxis = (chart as any).scales['x-axis-0'];
                const xAxisHeight = xAxis.height;
                const xAxisLabelWidth = xAxis.longestLabelWidth;
                const firstLabelPosition = xAxis._labelItems[0].x;

                this.updateScaleLabelPosition(
                    firstLabelPosition - xAxisLabelWidth / 2 - AXES_PADDINGS.y,
                    xAxisHeight - AXES_PADDINGS.x
                );

                this.flagPosition.bottom = xAxis.height - 2;

                const xs = chart.getDatasetMeta(0).data.map((d) => d._model.x);

                if (this.isScaleXChanged(xs)) {
                    this._cachedScaleX = xs;
                    this.flagPosition$.next();
                }
            }
        }
    ];

    isScaleXChanged(xs: number[]) {
        const _xs = this._cachedScaleX;
        return xs.length !== _xs.length || xs.some((x, i) => x !== _xs[i]);
    }

    updateScaleLabelPosition(x: number, y: number) {
        Object.assign(this.scaleLabelPosition, { x, y });
    }

    overlayPoints: OverlayPoint[] = [];

    tooltips: (OverlayPoint & { tooltipY: number })[] = [];

    ngOnInit() {
        this.chartOptions = {
            maintainAspectRatio: false,
            responsiveAnimationDuration: 0,
            layout: {
                padding: {
                    left: 0,
                    right: 50,
                    top: 0,
                    bottom: 0
                }
            },
            scales: {
                xAxes: [
                    {
                        type: 'linear',
                        gridLines: {
                            drawTicks: false,
                            lineWidth: 0
                        },
                        ticks: {
                            fontSize: 20,
                            fontFamily: 'Inter',
                            fontStyle: '500',
                            fontColor: '#4A4A4A',
                            padding: AXES_PADDINGS.x
                        }
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            borderDash: [1, 4],
                            color: '#8E8F93'
                        },
                        ticks: {
                            stepSize: 500,
                            fontSize: 16,
                            fontFamily: 'Inter',
                            fontColor: '#95A0B3',
                            padding: AXES_PADDINGS.y
                        },
                    }
                ]
            },
            hover: {
                mode: 'point',
                animationDuration: 0,
                onHover: function(e: MouseEvent) {
                    const point = this.getElementAtEvent(e);

                    const elem = e.target as HTMLElement;

                    elem.style.cursor = point.length ? 'pointer' : 'default';
                }
            },
            tooltips: {
                enabled: false
            },
            elements: {
                line: {
                    fill: false,
                    tension: 0,
                    borderWidth: 1
                },
            }
        };

        this.flagPosition$
            .pipe(
                takeUntil(this.onDestroy$),
                switchMapTo(this.year$)
            )
            .subscribe((year) => {
                this.updateFlagPosition(year || this.year);
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.chartData = this.data.map((d) => ({
                data: d.data,
                label: d.options.type,
                ...LineStyles[d.options.type],
            }));
        }

        if (changes.year) {
            this.year$.next(changes.year.currentValue);
        }
    }

    isActiveDataset(label: string) {
        return [
            `${BALANCE_CHART_TYPE.TOTAL_SEQUESTRATION}`,
            `${BALANCE_CHART_TYPE.TOTAL_EMISSIONS}`,
        ].includes(label);
    }

    updateFlagPosition(year: number) {
        const index = (this.chartData[0].data as PointXY[]).findIndex(({x}) => x === year);

        const { left } = this.chart.chart.chartArea;

        this.flagPosition.shift = left;
        this.flagPosition.left = this.chart.chart.getDatasetMeta(0).data[index]._model.x - left;

        const dsIndex = this.chartData.reduce(
            (acc, ds, i) => (this.isActiveDataset(`${ds.label}`) ? acc.concat(i) : acc),
            []
        );

        const points = dsIndex.map((idx) => {
            const {
                _model: { x, y, backgroundColor },
                _datasetIndex,
                _index,
            } = this.chart.chart.getDatasetMeta(idx).data[index];

            const value = (this.chartData[_datasetIndex].data[_index] as any).y;

            return {
                backgroundColor,
                y,
                x,
                value
            };
        });

        this.updateTooltips(points);

        this.overlayPoints = points;
    }

    updateTooltips(points: OverlayPoint[]) {
        let maxHeight = 0;
        let maxWidth = 0;

        this.tooltipElements.forEach((tooltip) => {
            const dims = tooltip.nativeElement.getBoundingClientRect();
            maxHeight = Math.max(dims.height, maxHeight);
            maxWidth = Math.max(dims.width, maxWidth);
        });

        this.tooltips = createTooltipsPositions(
            points.map((p) => p.y),
            maxHeight + TOOLTIPS_GAP_PX
        ).map((v) => {
            const point = points.find((p) => p.y === v.y);

            return {
                ...v,
                ...point
            };
        });

        this.isTooltipOverTheLeftHalf = points[0].x + maxWidth > this.chart.chart.width;
    }

    tooltipsSortingPredicate(point: { value: number }) {
        return point.value;
    }

    isPlanned(year: number) {
        return year > REPORT_YEAR;
    }

    chartClick(e: { active?: any; event?: MouseEvent }) {
        // maybe should search for the !hidden element instead
        const activeElement = e.active[0];

        if (activeElement && !activeElement.hidden) {
            const datasetIndex = activeElement._datasetIndex;
            const index = activeElement._index;
            const data = this.chartData[datasetIndex].data[index] as PointXY;

            this.changeYear.emit(data.x);
        }
    }
}
