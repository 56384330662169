<div class="filter-block__wrapper">
    <carbon-trail-filter-block [arrOfStatuses]="TEXT.statusCoord"
                               [arrYearsForFilter]="arrYearsForFilter"
                               [filterObject]="filterObjectChange"
                               [placeholder]="TEXT.others.searchCoord"
                               (filterObjectChange)="getFilterObjectChange($event)"
    ></carbon-trail-filter-block>
</div>
<div class="body-container scroll scroll-transparent">
    <carbon-trail-table-widget
            [ngClass]="{
                'widget': true,
                'widget-show': !filterObjectChange.year || filterObjectChange.year === REPORT_YEAR
            }"
            [arrOfStatuses]="TEXT.statusCoord"
            [emptySum]="emptySum"
            [emissionSum]="numberProcessing(emissionSum)"
            [awaitingSum]="awaitingSum"
            [approvedSum]="approvedSum"
            [reworkSum]="reworkSum"
            [tableLength]="allCount"
            [lastYear]="REPORT_YEAR"
    ></carbon-trail-table-widget>

    <carbon-trail-table-coord
            [filterStatus]="filterObjectChange.status"
            [filterText]="filterObjectChange.search"
            [filterYear]="filterObjectChange.year"
            [reportYear]="REPORT_YEAR"
            [collectionUpdate]="collection.onUpdateReports$"
            [canEdit]="canEdit"
            (edit)="facade.goToApproveReport($event)"
    ></carbon-trail-table-coord>
</div>
