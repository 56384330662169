import { Component, Input } from '@angular/core';
import { ReportsFacadeService } from '../../../services/facade/reports-facade.service';
import { CollectionService } from '../../../services/collection/collection.service';
import { FacilityEmission } from 'src/namespace';
import { CommonTableClass } from '../common-table.class';

@Component({
    selector: 'coord-container',
    templateUrl: './coord-container.component.html',
    styleUrls: ['./coord-container.component.less'],
})
export class CoordContainerComponent extends CommonTableClass {
    @Input() canEdit: boolean;

    constructor(public facade: ReportsFacadeService, public collection: CollectionService) {
        super();

        this.collection.onUpdateReports$.subscribe((data: FacilityEmission[]) => {
            this.update(data);
        });
    }
}
