import { Component, Input } from '@angular/core';
import { SectorSlugEnum } from 'src/store/models';
import { TEXT } from 'src/locales/text';

@Component({
    selector: 'organization-type-icon',
    templateUrl: 'organization-type-icon.component.html',
})
export class OrganizationTypeIconComponent {
    @Input() isGreyscale: boolean;
    @Input() slug: SectorSlugEnum;
    @Input() showTooltip = true;

    sectorSlugEnum = SectorSlugEnum;

    TEXT = TEXT;
}
