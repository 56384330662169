import { Component, Input } from '@angular/core';
import { TEXT } from 'src/locales/text';

export type ValueType = 'emission' | 'sequestration';

@Component({
    selector: 'ct-contribution-value',
    templateUrl: './contribution-value.component.html',
    styleUrls: ['./contribution-value.component.less'],
})
export class ContributionValueComponent {
    @Input() type!: ValueType;

    @Input() value!: number;

    TEXT = TEXT;
}
