import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import { TEXT } from 'src/locales/text';
import { CheckboxItem, FilterObject, STATUS_NAMES } from 'src/namespace'
import { REPORT_YEAR, YEARS_FOR_FILTER } from 'src/config';

@Component({
    selector: 'carbon-trail-filter-block',
    templateUrl: './filter-block.component.html',
    styleUrls: ['./filter-block.component.less']
})
export class FilterBlockComponent implements OnChanges {
    @Input() arrYearsForFilter: number[];
    @Input() arrOfStatuses: { [key in STATUS_NAMES]: string };
    @Input() filterObject: FilterObject;
    @Input() placeholder: string;

    @Output() filterObjectChange = new EventEmitter<FilterObject>();

    listYears: number[] = YEARS_FOR_FILTER;
    arrStatusForDropdownList: CheckboxItem[] = [];
    searchText = '';
    objectFilter: FilterObject = {
        year: REPORT_YEAR,
        status: '',
        search: ''
    }

    ngOnChanges() {
        this.createObjectsForFilter();
    }

    createObjectsForFilter() {
        this.arrYearsForFilter.sort((a, b) => b - a);

        this.arrStatusForDropdownList =
            [STATUS_NAMES.all, STATUS_NAMES.empty, STATUS_NAMES.rework, STATUS_NAMES.awaiting, STATUS_NAMES.approved].map(status => ({
                    id: status,
                    label: this.arrOfStatuses[status],
                    selected: this.filterObject.status === status
                }));
    }

    changesYear = (year: CheckboxItem) => {
        this.objectFilter.year = parseInt(year.label, 10);
        this.filterObjectChange.emit(this.objectFilter);
    }

    changesStatus = (status: CheckboxItem) => {
        this.objectFilter.status = status.id === STATUS_NAMES.all ? '' : status.id;
        this.filterObjectChange.emit(this.objectFilter);
    }

    changesSearch = (search) => {
        this.searchText = search;
        this.objectFilter.search = search;
        this.filterObjectChange.emit(this.objectFilter);
    }

    TEXT = TEXT;

}
