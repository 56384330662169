<div class="common-page">
    <div class="common-page-container">
        <div class="common-page-header">
            <carbon-trail-header
                [user]="getUser()"
                [tabs]="tabs"
                (logout)="logOut()">
            </carbon-trail-header>
        </div>

        <div class="common-page-content">
            <router-outlet></router-outlet>
        </div>
    </div>

<!--    <loader-map [@ANIMATION_HIDE] *ngIf="false" className="ct-loader"></loader-map>-->
</div>
