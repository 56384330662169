import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BalanceChangeIndicatorComponent } from './balance-change-indicator.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [BalanceChangeIndicatorComponent],
    exports: [BalanceChangeIndicatorComponent]
})
export class BalanceChangeIndicatorModule {}
