import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Facility } from '../models';

@Injectable({ providedIn: 'root' })
export class FacilityService extends EntityCollectionServiceBase<Facility> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Facility', serviceElementsFactory);
    }
}
