import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KebabMenuComponent } from './kebab-menu.component';
import { DirectivesModule } from '../../directives/directives.module';


@NgModule({
    exports: [KebabMenuComponent],
    declarations: [KebabMenuComponent],
    imports: [
        CommonModule,
        DirectivesModule,
    ]
})
export class KebabMenuModule {
}
