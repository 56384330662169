import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TEXT } from 'src/locales/text';
import { CheckboxItem } from 'src/namespace';
import { SectorService } from 'src/store/services/sector.service';

const NOT_SELECTED: CheckboxItem = {
    id: -1,
    label: TEXT.others.notSelected,
    selected: true
};

@Component({
    selector: 'selectbox-organization-type',
    templateUrl: 'selectbox-organization-type.component.html'
})
export class SelectboxOrganizationTypeComponent implements OnInit, OnDestroy {
    @Output() filterByItem = new EventEmitter<CheckboxItem>();

    TEXT = TEXT;

    listOrgTypes: CheckboxItem[];

    orgTypeItem: CheckboxItem;

    orgTypeDropdownOpened = false;

    onDestroy$ = new Subject<void>();

    constructor(private sectorService: SectorService) {
        sectorService.entities$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(data => {
            this.listOrgTypes = [NOT_SELECTED].concat(
                // TODO: solvents are not available yet
                data.filter(s => s.slug !== 'solvents').map(s => ({
                    id: s.id,
                    label: TEXT.ORGANIZATION_TYPE_NAMES_SHORT[s.slug],
                    selected: false
                }))
            ) as CheckboxItem[];
        });
    }

    ngOnInit() {
        this.sectorService.getAll();
        this.orgTypeItem = this.listOrgTypes[0];
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    filterBy(items: CheckboxItem[]) {
        this.orgTypeItem = items.find(it => it.selected);
        this.filterByItem.emit(this.orgTypeItem);
    }
}
