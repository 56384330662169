import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ANIMATION_OPACITY } from 'src/config';

@Component({
    selector: 'ct-input-dropdown',
    templateUrl: 'input-dropdown.component.html',
    styleUrls: ['input-dropdown.component.less'],
    animations: ANIMATION_OPACITY
})
export class InputDropdownComponent {
    @Input() inputText: string;
    @Input() label: string;
    @Input() isDisable = false;
    @Input() textError = '';
    @Input() textDescription = '';
    @Input() showDropdown: boolean;
    @Output() showDropdownChange = new EventEmitter<boolean>();

    toggleDropdown() {
        this.showDropdownChange.emit(!this.showDropdown);
    }
}
