<div class="wrapper">

    <div class="close" (click)="facade.setCurrentPopup(null)"> </div>

    <div class="coord-create-report">
        <div class="coord-create-report__number_block">
            <div class="coord-create-report__number">№ {{facilityEmission.id}}</div>
            <status-tags [status]="facilityEmission.status"></status-tags>
        </div>
        <div class="coord-create-report__date">{{formatDayMonthYear(facilityEmission.sent_at)}}</div>
        <div class="coord-create-report__name_report">{{TEXT.others.emissionReport}} • {{facilityEmission.year}}</div>
        <div class="coord-create-report__description_report">
            <div class="coord-create-report__description_report-inner">
                {{facilityEmission.facility?.name}} • {{facilityEmission.facility?.organization?.name}}
            </div>
        </div>
        <div class="coord-create-report__emission">{{TEXT.form.emission}}</div>
        <div class="coord-create-report__value_block">
            <div class="coord-create-report__value">{{facilityEmission.emission | emission}}</div>
            <div class="coord-create-report__unit" [innerHTML]="TEXT.table.emission"></div>
        </div>
    </div>
    <div class="coord-create-report__control_block">
        <textarea *ngIf="facilityEmission.status !== STATUS_NAMES.rework"
                  class="coord-create-report__control_textarea"
                  placeholder="{{TEXT.others.reportWillRequireRevision}}"
                  [(ngModel)]="textarea"
        ></textarea>
        <div class="coord-create-report__btn_block">
            <div *ngIf="facilityEmission.status !== STATUS_NAMES.rework">
                <ca-button
                        class="submit submit-black"
                        [type]="'primary'"
                        (clickAction)="rework()"
                >
                    <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
                    <ng-template #showContent>{{TEXT.others.sendForRevision}}</ng-template>
                </ca-button>
            </div>
            <div *ngIf="facilityEmission.status !== STATUS_NAMES.approved">
                <ca-button
                        class="submit"
                        [type]="'primary'"
                        (clickAction)="accept()"
                >
                    <ca-spinner *ngIf="isLoadingToApprove; else showContentToApprove"></ca-spinner>
                    <ng-template #showContentToApprove>{{TEXT.others.toApprove}}</ng-template>
                </ca-button>
            </div>
        </div>
    </div>

</div>
