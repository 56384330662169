<div class="balance-contribution-card">
    <div class="balance-contribution-card__header">
        <div class="balance-contribution-card__header-title">
            <div class="balance-contribution-card__header-icon balance-contribution-card__header-icon--{{type}}"></div>
            <div class="balance-contribution-card__header-label">{{label}}</div>
            <meatballs-menu *ngIf="showDetails" (click)="clickDetails.emit()"></meatballs-menu>
        </div>
        <div class="balance-contribution-card__header-item"
            [class.balance-contribution-card__header-item--regulated]="regulated"
        >
            <ng-content select="balance-value"></ng-content>
        </div>
    </div>
    <div class="balance-contribution-card__body">
        <ng-content></ng-content>
    </div>
</div>
