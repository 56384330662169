import { Component } from '@angular/core';

import { isRU, TEXT } from '../../../../locales/text';
import { changeLang } from '../../../../utils';

@Component({
    selector: 'carbon-trail-mobile-container',
    templateUrl: 'mobile-container.component.html',
    styleUrls: ['mobile-container.component.less']
})
export class MobileContainerComponent {
    TEXT = TEXT;
    isRu = isRU;

    changeLang = changeLang;
}
