import {
    Facility,
    FacilityEmission,
    Quota,
    Transaction,
} from 'src/store/models';
import { REPORT_YEAR } from 'src/config';


export class RoViewModel {
    total: number[];
    facilities: { name: string; emissions: number[] }[];
    balance: number[];
    quotas: number[];
    transactions: number[];

    constructor(
        protected years: number[],
        fs: { [id: number]: Facility },
        fems: FacilityEmission<number>[],
        qs: Quota[],
        tr: Transaction[]
    ) {
        const fEmsMap = new Map<number, number[]>();
        // build timeseries with length equals to years count
        this.balance = this.buildTimeSeries(null);
        this.quotas = this.buildTimeSeries(null);
        this.total = this.buildTimeSeries(0);
        this.transactions = this.buildTimeSeries(null);

        // map, key is year value (2020) and value is index in timeseries
        const yearToIdx = new Map(this.years.map((year, idx) => [year, idx]));

        // build Map where key is facility id and value is emissions timeseries
        // timeseries length is equal to showed years count
        // ex: {1 : [null, 2000, 4000]
        Object.keys(fs).forEach((k) => {
            fEmsMap.set(parseInt(k, 10), this.buildTimeSeries(null));
        });

        fems.forEach((fe) => {
            // get index of year in timeseries
            const yearIdx = yearToIdx.get(fe.year);
            const facilityId = fe.facility;
            if (!fEmsMap.has(facilityId)) {
                return; // usually when fs were not loaded
            } else {
                // remember for facility key emission in year by index
                fEmsMap.get(facilityId)[yearIdx] = fe.emission || null;
            }
            // for each emission increase balance
            this.balance[yearIdx] += fe.emission;
            // for each emission increase org total emission
            this.total[yearIdx] += fe.emission;
        });
        // fill quotas timeseries
        qs.forEach((q: Quota) => {
            this.quotas[yearToIdx.get(q.year)] = q.val;
        });

        tr.forEach((t: Transaction) => {
            const d = new Date(t.date);
            this.transactions[yearToIdx.get(d.getUTCFullYear())] += t.change;
        })

        // hide balance if no quota or it is future (by design)
        const indexCurrentYear = this.years.indexOf(REPORT_YEAR);
        this.balance = this.balance.map(
            (v, idx) => {
                if (idx > indexCurrentYear)
                    {return null;}

                return this.quotas[idx] ? v - this.quotas[idx] : null
            }
        );
        // finally builds array of facilities to display
        // key - facility name
        // value - facility emissions timeseries data
        this.facilities = Array.from(fEmsMap).map(([k, v]) => ({
                    name: fs[k].name,
                    emissions: v,
                }));
    }

    buildTimeSeries<T>(defVal: T): T[] {
        return new Array<T>(this.years.length).fill(defVal);
    }
}
