import { Component, OnInit } from '@angular/core';
import {
    ActivatedRoute,
    ParamMap
} from '@angular/router';
import {
    filter,
    map,
    switchMap,
} from 'rxjs/operators';
import { TabModel } from 'src/namespace';
import { TEXT } from 'src/locales/text';
import { PAGES, RO_SUB_MENU_ITEMS } from 'src/navigation/navigation-config';
import { Organization } from 'src/store/models';
import { OrganizationService } from 'src/store/services/organization.service';

@Component({
    selector: 'ro-header',
    templateUrl: './ro-header.component.html',
    styleUrls: ['./ro-header.component.less']
})
export class RoHeaderComponent implements OnInit {
    tabs: TabModel[] = RO_SUB_MENU_ITEMS;
    PAGES = PAGES;
    TEXT = TEXT;

    organization: Organization;

    constructor(orgSrv: OrganizationService, route: ActivatedRoute) {
        route.paramMap.pipe(
            map((params: ParamMap) => params.get('id')),
            filter(orgId => !! orgId),
            switchMap(id => orgSrv.getByKey(id + '/'))
        ).subscribe(org => this.organization = org);
    }

    ngOnInit(): void {}
}
