<div class="logout" (clickOutside)="isMenuOpened = false">
    <ct-logout-button
        (click)="isMenuOpened = !isMenuOpened"
        [userName]="name"
    ></ct-logout-button>

    <ct-logout-menu
        class="logout__menu"
        *ngIf="isMenuOpened"
        [@inOutAnimation]
        [userName]="name"
        [email]="user?.email"
        (logout)="logout.emit()"
    ></ct-logout-menu>
</div>
