<div class="page">
    <lang-selector [setLangCb]="changeLang" [isRu]="isRu"></lang-selector>

    <div class="page__wrapper">
        <div class="page__logo"></div>
        <div class="page__title" [innerHTML]="TEXT.header.balance"></div>
        <div class="page__sub_title">{{TEXT.loginPage.carbonBalance}}</div>
    </div>
    <div class="page__description" [innerHTML]="TEXT.others.mobileVersion"></div>
    <div class="page__sub_description" [innerHTML]="TEXT.others.openTheSiteOn"></div>
    <div class="page__by_line">
        <div>{{TEXT.loginPage.ministryOfEcology}}</div>
        <div class="page__by_line--subline">{{TEXT.loginPage.developedByCityAir}}</div>
    </div>
</div>