import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'searchbar',
    templateUrl: 'searchbar.component.html',
    styleUrls: ['searchbar.component.less'],
})
export class SearchbarComponent implements OnInit, OnChanges, OnDestroy {
    @Input() placeholder = '';
    @Input() text = '';
    @Input() debounceTime = 300;

    @Output() textChange = new EventEmitter<string>();

    form: FormGroup;
    focus: boolean;

    onDestroy$ = new Subject<void>();

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            textInput: [this.text],
        });

        this.form.controls.textInput.valueChanges
            .pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged(),
                takeUntil(this.onDestroy$)
            )
            .subscribe((value) => {
                this.text = value;
                this.textChange.emit(value);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.text && !changes.text.firstChange) {
            this.form.controls.textInput.patchValue(changes.text.currentValue);
        }
    }

    ngOnDestroy() {
        this.textChange.emit('');
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    clear() {
        this.form.controls.textInput.patchValue('');
    }

    onFocus() {
        this.focus = true;
    }

    onBlur() {
        this.focus = false;
    }
}
