<div class="ca-search-list">
    <div class="ca-search-list__wrapper">
        <searchbar
                class="facilities-page__filters-searchbar"
                [placeholder]="TEXT.others.searchDoc"
                (textChange)="onSearchTextChanged($event)"
        ></searchbar>
    </div>
</div>
<div class="body-container scroll scroll-transparent">
    <table class="table">
        <tr class="table__header">
            <th *ngFor="let column of sortingColumns; index as i">
                <ct-sort-list-item
                        *ngIf="column.predicate; else titleOnly"
                        [text]="column.props.title"
                        [sortingDirection]="column.direction"
                        (click)="sortBy(column, sortingColumns)"
                ></ct-sort-list-item>
                <ng-template #titleOnly>{{column.props.title}}</ng-template>
            </th>
        </tr>
        <tbody>
        <tr *ngFor="let doc of documents | sorting : sortingColumn.predicate : -sortingColumn.direction; trackBy: trackById"
            class="table__content_tr">

            <td class="table__font_weight_semi_bold table__font_color_gray">{{doc.num}}</td>

            <td>{{formatDayMonth(doc.order_date)}}</td>

            <td class="table__font_weight_semi_bold">
                <div class="table__inline">
                    <a class="table__link" href="{{doc.url}}"
                       target="_blank">{{doc.name}}</a>
                    <a href="{{doc.url}}" target="_blank"
                       class="table__external_reference"></a>
                </div>
                <ng-template #detailsTooltip>
                    <ct-comment-tooltip [text]="doc.name"></ct-comment-tooltip>
                </ng-template>
            </td>
        </tr>
        </tbody>
    </table>
    <div class="non-result" *ngIf="documents.length === 0">
        <ct-search-result-placeholder *ngIf="documents.length === 0"></ct-search-result-placeholder>
    </div>
</div>
