import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';

export type ButtonType = 'secondary' | 'outline' | 'cancel' | 'primary' | 'redOutline';

@Component({
    selector: 'ca-button',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.less']
})
export class ButtonComponent {
    @Input() type: ButtonType = 'secondary';
    @Input() disabled?: boolean;
    @Input() isLoading?: boolean;
    @Output() clickAction = new EventEmitter<Event>();

    @HostListener('click', ['$event'])
    click(e: Event) {
        if (!this.disabled) {
            this.clickAction.emit(e);
        }
    }
}
