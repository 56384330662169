import { Component, AfterContentChecked } from '@angular/core';

import { TooltipsService } from '../../../tooltips/tooltips.service';

@Component({
    selector: 'ca-tooltip-placement',
    templateUrl: 'tooltip-placement.component.html',
    styleUrls: ['tooltip-placement.component.less']
})
export class TooltipPlacementComponent implements AfterContentChecked {
    position: {
        x: number;
        y: number;
    };

    constructor(readonly tooltipsService: TooltipsService) {}

    ngAfterContentChecked() {
        this.position = this.tooltipsService.position;
    }
}
