<coord-container [canEdit]="canEdit"></coord-container>

<div [ngClass]="{enable: facade.getCurrentPopup()}">
    <coord-create-report [@inUpAnimation]
                         *ngIf="facade.getCurrentPopup() === POPUPS.approveReport"
    ></coord-create-report>
</div>

<div class="message-report-sent-wrapper">
    <message-report-sent-wrapper [isShow]="facade.getSuccessNotify().isShow" (closeNotify)="facade.hideSuccessNotify()">

        <message-report-sent-successfully [nameReport]="facade.getSuccessNotify().name"
                                          [statusReport]="TEXT.messageReportSend[facade.getSuccessNotify().status]"
        ></message-report-sent-successfully>

    </message-report-sent-wrapper>

    <message-report-sent-wrapper [isShow]="facade.getErrorNotify().isShow" (closeNotify)="facade.hideErrorNotify()">
        <message-report-sent-error [nameReport]="facade.getErrorNotify().name"></message-report-sent-error>
    </message-report-sent-wrapper>
</div>
<div class="manager-main__copyright"> {{TEXT.others.copyright}}</div>

<ct-loader [@ANIMATION_HIDE] *ngIf="facade.getShowLoader()"></ct-loader>
