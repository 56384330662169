<div class="header_block">
    <div class="total_data">
        <div class="total_data__label">{{TEXT.header.periodInWork}}</div>
        <div class="total_data__year">{{lastYear}}</div>
        <div class="total_data__emission">
            <div class="total_data__sub_title">{{TEXT.form.emission}}</div>
            <div class="total_data__sub_items">
                <span class="total_data__sub_items-number">{{emissionSum ? emissionSum: 0}} </span>
                <span class="total_data__sub_items-description" [innerHTML]="TEXT.others.widgetUnit"></span>
            </div>
        </div>
    </div>
    <reports-status class="header_block__widget"
            [arrOfStatuses]="arrOfStatuses"
            [awaitingSum]="awaitingSum"
            [emptySum]="emptySum"
            [approvedSum]="approvedSum"
            [reworkSum]="reworkSum"
    ></reports-status>
</div>