import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';

import { TEXT } from 'src/locales/text';
import { CheckboxItem, ROLE } from 'src/namespace';
import { RoSummaryReportService } from 'src/store/services/ro-summary-report.service';
import { ROSummaryReport } from 'src/store/models';
import { SectorService } from 'src/store/services/sector.service';
import { LoaderService } from 'src/modules/core/services/loader.service';
import { AuthService } from 'src/modules/core/services/auth.service';
import { REPORT_YEAR, YEARS_FOR_FILTER } from 'src/config';

@Component({
    selector: 'ro-list-page',
    templateUrl: 'ro-list-page.component.html',
    styleUrls: ['ro-list-page.component.less']
})
export class RoListPageComponent implements OnInit {
    TEXT = TEXT;

    searchColumns = ['id', 'name'];
    summaries$: Observable<ROSummaryReport[]>;

    listYears = YEARS_FOR_FILTER;

    searchText = '';
    searchSector = -1;

    canEdit = false;

    constructor(
        private roSummarySrv: RoSummaryReportService,
        private sectorSrv: SectorService,
        viewContainerRef: ViewContainerRef,
        loaderService: LoaderService,
        authService: AuthService,
    ) {
        const { role } = authService.getUser();

        this.canEdit = role === ROLE.executive;

        loaderService.waitLoadingComplete(
            viewContainerRef,
            [
                this.roSummarySrv,
                this.sectorSrv,
            ]
        );

        this.roSummarySrv.setFilter({orgIds: null, sector: null, year: null});
        this.summaries$ = this.roSummarySrv.filteredEntities$;
    }

    ngOnInit() {
        this.roSummarySrv.getWithQuery({year: REPORT_YEAR.toString()});
    }

    search(searchText: string) {
        this.searchText = searchText;
        this.updateFilter();
    }

    updateFilter() {
        this.roSummarySrv.setFilter({
            name: this.searchText,
            sector: this.searchSector
        });
    }

    filterByYear(item: CheckboxItem) {
        this.roSummarySrv.getWithQuery({year: item.id.toString()});
    }

    filterByOrgType(item: CheckboxItem) {
        this.searchSector = item.id as number;
        this.updateFilter();
    }
}
