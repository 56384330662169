import {
    combineLatest,
    Observable,
    OperatorFunction
} from 'rxjs';
import {
    map,
    switchMapTo
} from 'rxjs/operators';
import {
    Facility,
    FacilityEmission,
    FacilityYearlyViewModel,
    Sector
} from 'src/store/models';

export function joinFacilitiesToSectorsAndEmissions(
    fs$: Observable<Facility[]>,
    ems$: Observable<FacilityEmission<number>[]>,
    sectors$: Observable<Sector[]>,
): Observable<FacilityYearlyViewModel[]>
{
    return combineLatest([
        fs$,
        ems$,
        sectors$,
    ]).pipe(map(([fs, ems, sectors]) => fs.map(f => {
            const facilityEmission = ems.find(e => e.facility == f.id);
            return {
                ...f,
                emission: facilityEmission?.emission,
                status: facilityEmission?.status,
                sector: sectors.find(s => f.sector == s.id),
                sent_at: facilityEmission?.sent_at,
                approved_at: facilityEmission?.approved_at,
                comment: facilityEmission?.comment,
                sender: facilityEmission?.sender,
                email: facilityEmission?.sender.email,
                year: facilityEmission?.year,
            } as FacilityYearlyViewModel;
        }),
    ));
}
