import { FilterObject, FacilityEmission, STATUS_NAMES, Facility } from 'src/namespace';
import { LANGUAGE2, TEXT } from '../../../../locales/text';
import { REPORT_YEAR } from '../../../../config';

function reduceStatus(data: FacilityEmission[], name: STATUS_NAMES) {
    return data.reduce((sum, d) => {
        if (d.year === REPORT_YEAR && d.status === name)
            {return ++sum;}
        return sum;
    }, 0);
}

function reduceEmission(data: FacilityEmission[]) {
    return data.reduce((sum, d) => {
        if (d.year === REPORT_YEAR)
            {return sum + d.emission;}
        return sum;
    }, 0);
}

function calcAllCount(data: FacilityEmission[]) {
    return data.filter(d => d.year === REPORT_YEAR).length;
}

export class CommonTableClass {
    table: FacilityEmission[] = [];

    TEXT = TEXT;

    REPORT_YEAR = REPORT_YEAR;

    emptySum: number;
    awaitingSum: number;
    approvedSum: number;
    reworkSum: number;
    emissionSum: number;
    allCount: number;

    arrYearsForFilter: number[] = [];
    filterObjectChange: FilterObject = {
        year: REPORT_YEAR,
        status: '',
        search: ''
    }

    constructor() {
    }

    update(data: FacilityEmission[]) {
        const set = new Set();
        data.forEach(r => set.add(r.year));
        this.arrYearsForFilter = <number[]>Array.from(set).sort();

        this.awaitingSum = reduceStatus(data, STATUS_NAMES.awaiting);
        this.approvedSum = reduceStatus(data, STATUS_NAMES.approved);
        this.reworkSum = reduceStatus(data, STATUS_NAMES.rework);
        this.emptySum = reduceStatus(data, STATUS_NAMES.empty);

        this.emissionSum = reduceEmission(data);
        this.allCount = calcAllCount(data);
    }

    getFilterObjectChange = (obj: FilterObject) => {
        this.filterObjectChange = obj;
    }

    numberProcessing(num) {
        return (Math.round(num)).toLocaleString(LANGUAGE2);
    }
}
