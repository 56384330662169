import { Component, ElementRef, Input } from '@angular/core';
import { ANIMATION_OPACITY } from 'src/config';

declare const window: any;

@Component({
    selector: 'kebab-menu',
    template: `
        <div class="kebab_menu"
             [class.kebab_menu-disabled]="disabled"
             (click)="onClick()"
        >
            <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" class="kebab_menu-icon-dot"/>
                <circle cx="2" cy="9" r="2" class="kebab_menu-icon-dot"/>
                <circle cx="2" cy="16" r="2" class="kebab_menu-icon-dot"/>
            </svg>
            <div class="kebab_menu__object"
                 [class.kebab_menu__object-left]="positionOpenedMenu === 'left'"
                 *ngIf="isOpenMenu"
                 [@inOutAnimation]
            >
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['kebab-menu.component.less'],
    animations: ANIMATION_OPACITY
})
export class KebabMenuComponent {
    @Input() disabled = false;
    @Input() positionOpenedMenu: 'right' | 'left' = 'right';

    isOpenMenu = false;

    constructor(private el: ElementRef) {}

    listener = (event) => {
        if (typeof event !== 'undefined' && !this.el.nativeElement.contains(event.target)) {
            this.hide()
        }
    }

    onClick = () => {
        this.isOpenMenu ? this.hide() : this.show();
    }

    show = () => {
        window.addEventListener('click', this.listener);
        this.isOpenMenu = true;
    }

    hide = () => {
        window.removeEventListener('click', this.listener);
        this.isOpenMenu = false;
    }
}
