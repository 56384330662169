import { Component, Input } from '@angular/core';

@Component({
    selector: 'ct-search-result-placeholder',
    templateUrl: 'search-result-placeholder.component.html',
    styleUrls: ['search-result-placeholder.component.less']
})
export class SearchResultPlaceholderComponent {
    @Input() text = '';
}
