import { Component, Input } from '@angular/core';

@Component({
    selector: 'marker-pin',
    templateUrl: 'marker-pin.component.html',
    styleUrls: ['marker-pin.component.less'],
})
export class MarkerPinComponent {
    @Input() color = 'white';
}
