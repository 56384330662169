import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    BehaviorSubject,
    Observable
} from 'rxjs';
import {
    map,
    filter,
    switchMap,
    tap
} from 'rxjs/operators';

import { TEXT } from 'src/locales/text';
import { ROLE } from 'src/namespace';
import { Contact, Organization } from 'src/store/models';
import { RoDetailsService } from 'src/store/services/ro-details.service';
import { ContactsService } from 'src/store/services/contact.service';
import { byId } from 'src/store/queries/organizations';
import { AuthService } from 'src/modules/core/services/auth.service';
import { LoaderService } from 'src/modules/core/services/loader.service';

@Component({
    selector: 'ro-details-page',
    templateUrl: 'ro-details-page.component.html',
    styleUrls: ['ro-details-page.component.less'],
})
export class RoDetailsPageComponent implements OnInit {
    TEXT = TEXT;

    contacts$: Observable<Contact[]>;
    details$: Observable<Organization>;
    canEdit = false;
    capacityPlaceholder: number;
    orgId$: Observable<string> = null;

    constructor(
        public route: ActivatedRoute,
        private organizationsService: RoDetailsService,
        private contactsService: ContactsService,
        authService: AuthService,
        private loaderService: LoaderService,
        private viewContainerRef: ViewContainerRef,
    ) {
        const { role, organization } = authService.getUser();
        if (role === ROLE.ro && organization) { // it's ro, so no id in url, have to remember id
            this.orgId$ = (new BehaviorSubject<string>(organization.toString())).asObservable();
        }
        this.canEdit = role === ROLE.executive;
    }

    ngOnInit() {
        if (this.orgId$ === null ) { // not inited, so it's not RO, have to read from url params
            // it means that organization object was already loaded from header component
            this.orgId$ = this.route.parent?.paramMap.pipe(
                map(params => params.get('id')),
                filter(id => !!id)
            );
        } else {
            // ro, we have to call org api to get current one
            this.orgId$ = this.orgId$.pipe(  // so organization information was not loaded
                tap(id => this.organizationsService.getByKey(id + '/')),
            )
        }

        this.contacts$ = this.orgId$.pipe(
            switchMap(id => this.contactsService.getWithQuery({organization: id}))
        );

        // assume that getting org from API was already initiated
        this.details$ = this.orgId$.pipe(
            switchMap(id => byId(this.organizationsService, id)),
        );

        this.loaderService.waitLoadingComplete(
            this.viewContainerRef,
            [
                this.contactsService
            ]
        )
    }

    abbrName(name: string = '') {
        return name.split(/\s+/).slice(0, 2).join(' ');
    }
}
