<div class="balance-summary-table balance-summary-table--sorted">
    <div class="balance-summary-table-sorting">
        <ct-sort-list-item
            *ngFor="let column of sortingColumns"
            [sortingDirection]="column.direction"
            [text]="column.props.title"
            (click)="sortBy(column, sortingColumns)"
        ></ct-sort-list-item>
    </div>

    <div class="balance-summary-table__header"
        *ngIf="title"
    >
        <div class="balance-summary-table__header-icon balance-summary-table__header-icon--{{type}}"></div>
        <div class="balance-summary-table__header-title"
            [class.balance-summary-table__header-title--active]="details?.length"
            (click)="isOpened = !isOpened"
        >
            <div>{{title}}</div>
            <ct-chevron
                *ngIf="details?.length"
                [direction]="isOpened ? 'up' : 'down'"
            ></ct-chevron>
        </div>
        <div class="balance-summary-table__header-value">
            {{value | number : '1.0-0'}}
        </div>
    </div>

    <div class="balance-summary-table__body"
        [class.balance-summary-table__body--collapsed]="!isOpened"
    >
        <div class="balance-summary-table__item" *ngFor="let item of details | sorting : sortingColumn.predicate : -sortingColumn.direction">
            <organization-type-icon
                class="balance-summary-table__item-icon"
                *ngIf="item.type"
                [slug]="item.type"
                [isGreyscale]="true"
                [showTooltip]="false"
            ></organization-type-icon>
            <div class="balance-summary-table__item-title" [innerHTML]="item.title"></div>
            <div class="balance-summary-table__item-value">{{item.value | number : '1.0-0'}}</div>
        </div>
    </div>
</div>
