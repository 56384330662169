import { Identifiable } from 'src/libs/models';

export class ApiModel {}

export class Fadaster extends ApiModel implements Identifiable {
    id: number;
    year: number;
    no_action_emission: number;
    total_emission: number;
    no_action_sequestration: number;
    total_sequestration: number;
    emission_ro: number;
    sequestration_extra: number;
    obj: string

    static toPoint(obj: Fadaster, fieldName: string) {
        return {
            x: obj.year,
            y: obj[fieldName] / 1000,
        }
    }

    public getBalance(): number {
        return this.total_emission - this.total_sequestration;
    }

    public get emissions_without_ro(): number {
        return this.total_emission - this.emission_ro;
    }

    public get sequestration_without_cm(): number {
        return this.total_sequestration - this.sequestration_extra;
    }
}

export class Organization extends ApiModel implements Identifiable {
    id: number;
    name: string;
    obj: string;
    facilities: number[];
    legal_address: string;
    postal_address: string;
    inn: string;
    ogrn: string;
    phone_number: string;
    email: string;
}

export class Sector implements Identifiable {
    id: number;
    name: string;
    slug: string;
    obj: string;
}

export enum SectorSlugEnum {
    ENERGY = 'energy',
    INDUSTRY = 'industry',
    ARGICULTURE = 'agriculture',
    LAND_AND_FORESTRY = 'lulucf',
    WASTE = 'waste',
    OTHER = 'other',
}

export class Facility extends ApiModel implements Identifiable {
    id: number;
    name: string;
    address: string;
    organization: Organization;
    coords: number[];
    cadastral_number: string;
    sector: number | Sector;
    obj: string;

    static search(obj: Facility, search: string): boolean {
        if ( ! search ) {
            return true;
        }
        const needle: string = search.trim().toLowerCase();

        return ['id', 'name', 'address', 'organization'].some(f => {
                if (f == 'organization') { // @todo: fix it with search nested fields like oragnization__name
                    return `${obj[f]?.name}`.toLowerCase().includes(needle);
                }
                return `${obj[f]}`.toLowerCase().includes(needle);
            }
        );
    }
}

export class User extends ApiModel implements Identifiable {
    id: number;
    email: string;
    role: string;
    username: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    organization: number;
    obj: string;
}

export class FacilityEmission<T extends number | Facility> extends ApiModel implements Identifiable {
    id: number;
    facility: T;
    emission: number;
    sent_at: string;
    approved_at: string;
    status: string;
    sender: User;
    year: number;
    blame: User;
    comment: string;
    obj: string
}

export enum FacilityEmissionStatus {
    APPROVED = 'approved',
}

export class ROSummaryReport extends ApiModel implements Identifiable {
    id: number;
    name: string;
    sectors: number[];
    emissions: number;
    quota: number;
    balance: number;
    transactions: Transaction[];
}

export class CollectionsDb {
    relationsMap: {[relName: string]: any} = {};

    setRelation(name: string, collection: any) {
        this.relationsMap = collection;
    }

    getRelated(m: ApiModel, relation: string) {
        const value: any = m?.[relation];
        return this.relationsMap?.[value];
    }

    getById(id: number) {
        return this.relationsMap?.[id];
    }
}

// @todo: move to the base api model.
export class FacilityYearlyViewModel extends Facility implements Identifiable {
    emission: number;
    sector: Sector;
    sent_at?: string;
    approved_at?: string;
    comment?: string;
    sender?: User;
    email?: string;
    year?: number;
    status?: string;

    public static includes(haystack: string, needle: string) {
        return haystack.toLowerCase().includes(needle);
    }

    public static filter(item, filterValue) {
        const needle = filterValue.text.toLowerCase();
        return this.includes(item?.name, needle) || this.includes(item?.address, needle);
    }
}

export class Quota extends ApiModel implements Identifiable {
    id: number;
    year: number;
    val: number;
    organization: number;
}

export class Transaction extends ApiModel implements Identifiable {
    id: number;
    year: number;
    organization: number;
    change: number;
    date: string;
    transaction_id: string;
}

export class Contact extends ApiModel implements Identifiable {
    id: number;
    full_name: string;
    position: string;
    phone_number: string;
    email: string;
    obj: string;
}

export class DashboardReport extends ApiModel {
    year: number;
    summary: {
        quotas: number;
        boughtCU: number;
        soldCU: number;
        emissions: number;
    } = {quotas: 0, boughtCU: 0, soldCU: 0, emissions: 0};
    reports: {
        awaiting: number;
        approved: number;
        rework: number;
        empty: number;
    } = {awaiting: 0, approved: 0, rework: 0, empty: 0}
}

export class Absorber extends ApiModel implements Identifiable {
    id: number;
    obj: string;
    name: string;
    region: Region;
}

export class AbsorberSequestration extends ApiModel implements Identifiable {
    id: number;
    obj: string;
    year: number;
    absorber: Absorber;
    value: number;
}

export class SectorEmissionFadaster extends ApiModel implements Identifiable {
    id: number;
    obj: string;
    year: number;
    sector: Sector;
    // sector: number;
    emission: number;
}

export class Document extends ApiModel implements Identifiable {
    id: number;
    name: string;
    num: string;
    url: string;
    order_date: string;
    created_at: string;
    modified_at: string;
}

export class Inventorization extends ApiModel {
    year: number;
    sequestration: number;
    emission: number;
    region: number;
}

export class Region extends ApiModel implements Identifiable {
    id: number;
    name: string;
    sequestration: number;
    other_emission: number;
    emission_reports: number;
    obj: string;
}
