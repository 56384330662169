export enum TABLE_COLUMNS_NAME {
    id = 'id',
    roId = 'roId',
    num = 'num',
    year = 'year',
    emission = 'emission',
    emissions = 'emissions',
    status = 'status',
    sent_at = 'sent_at',
    approved_at = 'approved_at',
    facility = 'facility',
    address = 'address',
    sender = 'sender',
    sendReport = 'sendReport',
    blame = 'blame',
    comments = 'comments',
    name = 'name',
    created_at = 'created_at',

    emissionMass = 'emissionMass',
    targetEmissionMass = 'targetEmissionMass',
    organizationName = 'organizationName',
    sector = 'sector',
    quota = 'quota',
    balance = 'balance',
    bill = 'bill',
    source = 'source',
    carbonCapacity = 'carbonCapacity',
}

export class TabModel {
    isDisabled?: boolean;
    type?: any;
    id?: string;
    title: string;
}

export enum POPUPS {
    createReport = 'createReport',
    editReport = 'editReport',
    approveReport = 'approveReport',
}

export enum STATUS_NAMES {
    all= 'all',
    rework = 'rework',
    awaiting = 'awaiting',
    approved = 'approved',
    empty = 'empty'
}

export enum STATUS_COLOR {
    approved = 'green',
    awaiting = 'yellow',
    rework = 'red',
    empty = 'gray',
}

export enum ROLE {
    government = 'government',
    executive = 'executive',
    ro = 'ro',
}

export enum CARD_TYPE {
    geography = 'geography',
    source = 'source',
    absorbers = 'absorbers'
}

export type FilterObject = {
    year: number;
    status: string | number;
    search: string;
}

export class Facility {
    id: number;
    name: string;
    address: string;
    organization?: {
        id: number;
        name: string;
    };
    coords: [number, number];
    sector: number;
}

export class User {
    id: number;
    email: string;
    role: string;
    username: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    organization: number;

    constructor() {
        Object.keys(this).forEach(key => this[key] = null);
    }
}

export class FacilityEmission {
    id: number;
    year: number;
    emission: number;
    status: STATUS_NAMES;
    sent_at: string;
    approved_at: string;
    facility: Facility;
    blame?: User;
    sender: User;
    comment?: string
}

export class Balance {
    year: number;
    emission: number;
    sequestration: number;
    balance: number;
}

export class Region {
    id: number;
    name: string;
    sequestration: number;
    other_emission: number;
    emission_reports: number;
    obj: string;
}

export class Doc {
    id: number;
    name: string;
    num: string;
    url: string;
    order_date: string;
    created_at: string;
    modified_at: string;
}

export enum EMISSIONS {
    emission_reports = 'emission_reports',
    other_emission = 'other_emission',
}

export class SortingColumn {
    props: any;
    title: string;
}

export type CheckboxItem = {
    id: number | string;
    label?: string;
    selected?: boolean;
}
