import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ct-annual-timeline',
    templateUrl: 'annual-timeline.component.html',
    styleUrls: ['annual-timeline.component.less'],
})
export class AnnualTimelineComponent {
    @Input() years: number[];

    @Input() currentYear: number;

    @Input() loadingYear: number;

    @Output() selectedYear = new EventEmitter<number>();

    loadYearData(e: MouseEvent, year: number) {
        if (this.currentYear !== year) {
            this.selectedYear.emit(year);
        }

        (e.target as HTMLElement).scrollIntoView();
    }
}
