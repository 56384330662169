<div class="balance__left"
    [class.balance__left-small]="route.snapshot.data?.smallSize"
>
    <div class="balance__header balance__title">
        {{TEXT.BALANCE_RO.balance[0]}}
    </div>

    <div class="balance__content">
        <div class="balance__tr balance__left_item balance__left_item-quota" [attr.data-letter]="TEXT.BALANCE_RO.quota[1]">
            {{TEXT.BALANCE_RO.quota[0]}}
        </div>

        <div class="balance__tr balance__left_item balance__left_item-facilities"
            [attr.data-letter]="TEXT.BALANCE_RO.facilities[1]"
            (click)="showFacilities = !showFacilities"
        >
            {{TEXT.BALANCE_RO.facilities[0]}}&nbsp;
            <ct-chevron [direction]="showFacilities ? 'up' : 'down'"></ct-chevron>
        </div>

        <ng-container *ngIf="showFacilities">
            <div class="balance__tr balance__left_item" *ngFor="let facility of data.facilities">
                {{facility.name}}
            </div>
        </ng-container>

        <div class="balance__tr balance__left_item balance__left_item-units" [attr.data-letter]="TEXT.BALANCE_RO.carbonUnits[1]">
            {{TEXT.BALANCE_RO.carbonUnits[0]}}
        </div>

        <div class="balance__tr balance__left_item balance__left_item-balance" [attr.data-letter]="TEXT.BALANCE_RO.balance[1]">
            {{TEXT.BALANCE_RO.balance[0]}}
        </div>
    </div>
</div>

<div class="balance__right"
    [class.balance__right-small]="route.snapshot.data?.smallSize"
>
    <div class="balance__header">
        <div *ngFor="let year of years"
            id="{{year === currentYear ? 'current_year_for_scrolling' : ''}}"
            class="balance__year"
            [class.balance__year-current]="year === currentYear"
        >
            <div>{{year}}</div>
            <div class="balance__year_subtitle" [innerHTML]="TEXT.table.emission"></div>
            <div *ngIf="year === currentYear" class="balance__current_year">{{TEXT.BALANCE_RO.awaiting}}</div>
            <div *ngIf="year > currentYear" class="balance__plan_year">{{TEXT.BALANCE_RO.plan}}</div>
        </div>
    </div>

    <div class="balance__content">
        <div class="balance__tr">
            <div class="balance__item" *ngFor="let item of data.quotas">
                <div class="balance__item-no_data" *ngIf="isFalseNumber(item) else value">
                    {{TEXT.common.noData}}
                </div>
                <ng-template #value>{{item | emission}}</ng-template>
            </div>
        </div>

        <div class="balance__tr">
            <div *ngFor="let item of data.total" class="balance__item">{{ (item > 0 ? item : '') | emission }}</div>
        </div>

        <ng-container *ngIf="showFacilities">
            <div class="balance__tr" *ngFor="let facility of data.facilities">
                <div *ngFor="let item of facility.emissions" class="balance__item">{{item | emission}}</div>
            </div>
        </ng-container>

        <div class="balance__tr">
            <div *ngFor="let item of data.transactions" class="balance__item">{{item | emission}}</div>
        </div>

        <div class="balance__tr">
            <div *ngFor="let item of data.balance" class="balance__item balance__item-balance">{{item | emission}}</div>
        </div>
    </div>
</div>
