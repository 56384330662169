import {
    AbsorberSequestration, Fadaster, SectorEmissionFadaster, SectorSlugEnum,
} from 'src/store/models';
import { TEXT } from 'src/locales/text';


type DataItem = {
    title: string;
    value?: number;
    type?: SectorSlugEnum;
};

export enum SummaryTableType {
    ABSORBERS = 'absorbers',
    EMISSIONS = 'emissions',
}

type Block = {
    title: string;
    details: DataItem[];
    value?: number;
    type: SummaryTableType;
};

enum SummaryType {
    ABSORPTION,
    EMISSIONS_RO,
    EMISSIONS_NRO,
    CLIMATE_PROJECTS,
}

export class BalanceViewModel {
    // not regulated absorbers
    absSeqs: Block;

    // not regulated emissions by sectors
    secEmFadaster: Block;

    climateProjects: Block;
    roEmissions: Block;

    constructor(
        absorberSequestrations: AbsorberSequestration[],
        sectorEmissionFadaster: SectorEmissionFadaster[],
        fadasters: Fadaster[], // temporary take part of data from fadaster
        year: number,
    ) {
        // not regulated absorbers
        this.absSeqs = {
            type: SummaryTableType.ABSORBERS,
            title: TEXT.uoivBalance.summaryTableTitles[SummaryType.ABSORPTION],
            details: [],
            value: 0,
        };

        absorberSequestrations.filter(i => i.year === year).forEach(abs_seq_item => {
            this.absSeqs.value += abs_seq_item.value;
            this.absSeqs.details.push({
                title: abs_seq_item.absorber.name,
                value: abs_seq_item.value
            });
        });

        // not regulated emissions by sectors
        this.secEmFadaster = {
            type: SummaryTableType.EMISSIONS,
            title: TEXT.uoivBalance.summaryTableTitles[SummaryType.EMISSIONS_NRO],
            details: [],
            value: 0,
        };

        sectorEmissionFadaster.filter(i => i.year === year).forEach(secEmFadItem => {
            this.secEmFadaster.value += secEmFadItem.emission;
            this.secEmFadaster.details.push({
                title: TEXT.ORGANIZATION_TYPE_NAMES[secEmFadItem.sector.slug],
                value: secEmFadItem.emission,
                type: secEmFadItem.sector.slug as SectorSlugEnum
            });
        })

        // regulated absorbers
        this.climateProjects = {
            type: SummaryTableType.ABSORBERS,
            title: TEXT.uoivBalance.summaryTableTitles[SummaryType.CLIMATE_PROJECTS],
            details: [],
            value: 0,
        };

        // regulated emissions
        this.roEmissions = {
            type: SummaryTableType.EMISSIONS,
            title: TEXT.uoivBalance.summaryTableTitles[SummaryType.EMISSIONS_RO],
            details: [],
            value: 0,
        };

        const currentFadaster = fadasters.find(f => f.year === year);

        if (typeof currentFadaster !== 'undefined') {
            this.climateProjects.value = currentFadaster.sequestration_extra;

            const climateFadasterAbsorption = [
                {
                    title: TEXT.uoivBalance.regulatedSequestrationLabels.ghg_storage,
                    field: 'ghg_storage',
                },
                {
                    title: TEXT.uoivBalance.regulatedSequestrationLabels.reforestation,
                    field: 'reforestation',
                },
                {
                    title: TEXT.uoivBalance.regulatedSequestrationLabels.wildfire_management,
                    field: 'wildfire_management',
                },
                {
                    title: TEXT.uoivBalance.regulatedSequestrationLabels.pest_control,
                    field: 'pest_control',
                },
            ];

            for (const item of climateFadasterAbsorption) {
                this.climateProjects.details.push({
                    title: item.title,
                    value: currentFadaster[item.field]
                });
            }

            this.roEmissions.value = currentFadaster.emission_ro;

            const climateFadasterEmission = [
                {
                    title: TEXT.uoivBalance.regulatedEmissionLabels.emission_ro,
                    field: 'emission_ro',
                },
                {
                    title: TEXT.uoivBalance.regulatedEmissionLabels.regional_program_emissions,
                    field: 'regional_program_emissions',
                },
                {
                    title: TEXT.uoivBalance.regulatedEmissionLabels.climate_projects_emissions,
                    field: 'climate_projects_emissions',
                },
            ];

            for (const dataItem of climateFadasterEmission) {
                this.roEmissions.details.push({
                    title: dataItem.title,
                    value: currentFadaster[dataItem.field]
                });
            }
        }
    }
}
