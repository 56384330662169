<div class="login_block__page">
    <div class="login_block__wrapper scroll">
        <lang-selector [setLangCb]="changeLang" [isRu]="isRu"></lang-selector>

        <form class="login_block" (ngSubmit)="submit()">
            <div class="login_block__island">
                <div class="login_block__logo"></div>
            </div>
            <div class="login_block__title"
                 [innerHTML]="TEXT.header.balance"></div>
            <div class="login_block__sub_title">{{TEXT.loginPage.carbonBalance}}</div>

            <div class="login_block__input login_block__input-sign"
                 [class.login_block__input-wrong]="isWarning"
            >
                <input class="login_input"
                       type="text"
                       autocomplete="on"
                       name="user_login"
                       required
                       [attr.placeholder]="TEXT.loginPage.login"
                       [(ngModel)]="login"
                       (keydown.enter)="focusOrSubmit()"
                />
            </div>

            <div class="login_block__input"
                 [class.login_block__input-wrong]="isWarning"
            >
                <input class="login_input"
                       type="{{isDisplayPas ? 'text' : 'password'}}"
                       autocomplete="on"
                       name="user_password"
                       required
                       [attr.placeholder]="TEXT.loginPage.pass"
                       [(ngModel)]="password"
                       (keydown.enter)="focusOrSubmit()"
                />
                <div (click)="isDisplayPas = !isDisplayPas">
                    <div class="login_input__eye login_input__eye-{{isDisplayPas ? 'open' : 'closed'}}"></div>
                </div>
            </div>

            <div class="login_block__check_password_wrapper login_block__check_password_wrapper-sign">
                <div class="login_block__check_password_wrapper-wrong"
                     *ngIf="isWarning">
                    {{extraError || TEXT.loginPage.wrong}}
                </div>
            </div>

            <div class="login_block__btn_confirm"
                 tabindex="0"
                 (click)="submit()"
                 (keyup.enter)="focusOrSubmit()"
            >
                <ca-spinner *ngIf="loading; else showText"></ca-spinner>
                <ng-template #showText>
                    <div>{{TEXT.loginPage.sign}}</div>
                </ng-template>
            </div>

<!--             <div class="login_block__problems">{{TEXT.loginPage.loginProblems}}</div>-->
        </form>

        <div class="login_block__footer">
            <div class="login_block__footer_text">
                <a href="{{TEXT.others.copyright1[1]}}" target="_blank">{{TEXT.others.copyright1[0]}}</a>
                <a href="{{TEXT.others.copyright2[1]}}" target="_blank">{{TEXT.others.copyright2[0]}}</a>
            </div>
            <div class="login_block__footer_text">
                <a href="{{TEXT.others.copyright3[1]}}" target="_blank">{{TEXT.others.copyright3[0]}}</a>
            </div>
        </div>
    </div>
    <div class="login_block__background"></div>
</div>