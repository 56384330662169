import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'carbon-capacity-bar',
  templateUrl: 'carbon-capacity-bar.component.html',
  styleUrls: ['carbon-capacity-bar.component.less']
})
export class CarbonCapacityBarComponent implements OnChanges {
    @Input() available: number;
    @Input() used: number;

    availableRatio: number;
    usedRatio: number;
    unknownWidth = 100;

    ngOnChanges() {
        if (!isNaN(this.available) && !isNaN(this.used)) {
            const overall = this.available + this.used;

            this.availableRatio = 100 * this.available / overall;
            this.usedRatio = 100 - this.availableRatio;
            this.unknownWidth = 0;
        } else {
            this.unknownWidth = 100;
        }
    }
}
