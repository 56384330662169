import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Fadaster } from '../models';

@Injectable({ providedIn: 'root' })
export class FadasterService extends EntityCollectionServiceBase<Fadaster> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Fadaster', serviceElementsFactory);
    }
}
