import {
    Directive,
    Input,
    HostListener,
    ElementRef,
    TemplateRef,
    OnInit,
    OnDestroy
} from '@angular/core';

import { fromEvent, Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TooltipsService, TooltipPlacement, ITooltipComponent } from './tooltips.service';

@Directive({
    selector: '[caTooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {
    @Input() caTooltipTemplate: TemplateRef<ITooltipComponent>;
    @Input() caTooltipPlacement: TooltipPlacement = 'right';
    @Input() caTooltipFollowPointer?: boolean;

    private onDestroy$ = new Subject();
    private onLeave$ = new Subject();
    private onClear$ = merge(this.onLeave$, this.onDestroy$);

    constructor(
        private el: ElementRef,
        private tooltipsService: TooltipsService
    ) {}

    ngOnInit() {
        this.onClear$.subscribe(() => {
            this.tooltipsService.clearBy(this.el);
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.onLeave$.complete();
    }

    @HostListener('mouseenter')
    private onMouseEnter() {
        this.tooltipsService.setFloating(this.caTooltipFollowPointer);

        if (this.caTooltipFollowPointer) {
            this.watchPointer();
        }

        this.tooltipsService.show(
            this.el,
            this.caTooltipTemplate,
            this.caTooltipPlacement
        );
    }

    @HostListener('mouseleave')
    private onMouseLeave() {
        this.onLeave$.next();
    }

    private watchPointer() {
        fromEvent(window, 'wheel')
            .pipe(
                takeUntil(this.onClear$)
            )
            .subscribe((e: WheelEvent) => {
                const { left, right, top, bottom } = this.el.nativeElement.getBoundingClientRect();
                const { clientX, clientY } = e;

                if (clientX > right || clientX < left || clientY < top || clientY > bottom) {
                    this.onMouseLeave();
                }
            });

        fromEvent(document, 'mousemove')
            .pipe(
                takeUntil(this.onClear$)
            )
            .subscribe((e: MouseEvent) => {
                this.updatePosition(e);
            });
    }

    private updatePosition({ clientX, clientY }: MouseEvent) {
        const { position } = this.tooltipsService;

        if (position) {
            position.x = clientX;
            position.y = clientY;
        }
    }
}
