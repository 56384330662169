<ca-tooltip-placement>
    <div class="tooltip"
        *ngIf="tooltipsService.currentTarget"
        [@inOutAnimation]
    >
        <ca-tooltip-pointer fill="#6C7484"></ca-tooltip-pointer>
        <div class="tooltip__text" *ngIf="text; else content" [innerHTML]="text"></div>
        <ng-template #content>
            <ng-content></ng-content>
        </ng-template>
    </div>
</ca-tooltip-placement>
