import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';

import { TEXT } from 'src/locales/text';

export type PeriodType = 'closed' | 'opened' | 'planned' | 'unknown';

@Component({
    selector: 'ct-period-status-badge',
    templateUrl: 'period-status-badge.component.html',
    styleUrls: ['period-status-badge.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodStatusBadgeComponent implements OnChanges {
    @Input() period: number;

    @Input() workingPeriod: number;

    TEXT = TEXT;

    type: PeriodType = 'unknown';

    ngOnChanges() {
        this.type = {
            [NaN]: 'unknown',
            [-1]: 'closed',
            [0]: 'opened',
            [1]: 'planned'
        }[Math.sign(this.period - this.workingPeriod)] as PeriodType;
    }
}
