import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AuthService } from '../modules/core/services/auth.service';
import { routesByRole } from '../navigation/routes-by-role';
import { onUserLoggedIn } from './actions';

@Injectable()
export class CoreEffects {
    constructor(private actions$: Actions, private router: Router, private authSrv: AuthService) {}

    onUserLoggedIn$ = createEffect(
        () => this.actions$.pipe(
            ofType(onUserLoggedIn),
            tap(i => {
                this.router.resetConfig(routesByRole(this.authSrv.getUser()?.role));
                return i;
            }),
        ),
        { dispatch: false }
    );
}
