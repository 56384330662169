import { NgModule } from '@angular/core';
import { ArrowComponent } from './arrow.component';

@NgModule({
    declarations: [
        ArrowComponent,
    ],
    exports: [
        ArrowComponent,
    ],
})
export class ArrowModule {}
