<div class="balance-excerpt-page">
    <div class="balance-excerpt-page__body" *ngIf="fadaster$ | async as fadaster">
        <div class="balance-excerpt-page__body-info">
            <div class="balance-excerpt-page__body-title-wrapper">
                <div class="balance-excerpt-page__body-title">
                    {{TEXT.others.balance}}&nbsp;{{mapFacade.getCurrentYear()}}
                </div>

                <ct-period-status-badge class="balance-excerpt-page__widget-badge"
                    [period]="mapFacade.getCurrentYear()"
                    [workingPeriod]="REPORT_YEAR"
                ></ct-period-status-badge>
            </div>

            <balance-value size="large" [value]="fadaster.total_emission - fadaster.total_sequestration"></balance-value>
        </div>

        <div class="balance-excerpt-page__card-wrapper">
            <div class="balance-excerpt-page__card-title">
                {{TEXT.cardCategory.inventory}}
            </div>

            <balance-contribution-card
                class="balance-excerpt-page__card"
                [type]="CARD_TYPE.source"
                [label]="TEXT.cardType.sources"
            >
                <ca-tags-list class="balance-excerpt-page__tags-list" [tags]="[TEXT.cardName.sources]"></ca-tags-list>
                <balance-value [value]="fadaster.total_emission" [isBalance]="false"></balance-value>
            </balance-contribution-card>

            <balance-contribution-card
                class="balance-excerpt-page__card"
                [type]="CARD_TYPE.absorbers"
                [label]="TEXT.cardType.absorbers"
            >
                <ca-tags-list class="balance-excerpt-page__tags-list" [tags]="[TEXT.cardName.absorbers]"></ca-tags-list>
                <balance-value [value]="fadaster.total_sequestration" [isBalance]="false"></balance-value>
            </balance-contribution-card>
        </div>

        <div class="balance-excerpt-page__card-wrapper">
            <div class="balance-excerpt-page__card-title">
                {{TEXT.cardCategory.regulation}}
            </div>

            <balance-contribution-card
                class="balance-excerpt-page__card"
                [type]="CARD_TYPE.source"
                [label]="TEXT.cardType.regulatedSources"
                [regulated]="true"
                [showDetails]="true"
                (clickDetails)="mapFacade.toggleOrganizations()"
            >
                <ca-tags-list
                    class="balance-excerpt-page__tags-list"
                    [tags]="roWidgetConfig.selectedList"
                    [truncateTo]="4"
                    [textNodesFns]="tagsListLabels"
                ></ca-tags-list>

                <balance-value [value]="roWidgetConfig.totalEmission" [isBalance]="false"></balance-value>
                <div class="balance-excerpt-page__calculated-value">{{roWidgetConfig.selectedEmission | number : '1.0-0'}}</div>
            </balance-contribution-card>

            <balance-contribution-card
                class="balance-excerpt-page__card"
                [type]="CARD_TYPE.absorbers"
                [label]="TEXT.cardType.climateProjects"
                [regulated]="true"
            >
                <ca-tags-list class="balance-excerpt-page__tags-list" [tags]="[TEXT.cardName.climateProjects]"></ca-tags-list>
                <balance-value [value]="fadaster.total_sequestration - fadaster.no_action_sequestration" [isBalance]="false"></balance-value>
            </balance-contribution-card>
        </div>
    </div>
</div>
