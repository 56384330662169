import { Component, Input } from '@angular/core';

@Component({
    selector: 'balance-chart-point',
    templateUrl: 'balance-chart-point.component.html',
    styleUrls: ['balance-chart-point.component.less'],
})
export class BalanceChartPointComponent {
    @Input() color: string;
}
