import { Injectable } from '@angular/core';
import {
    DefaultHttpUrlGenerator,
    normalizeRoot,
    Pluralizer
} from '@ngrx/data';

@Injectable()
export class PluralHttpUrlGenerator extends DefaultHttpUrlGenerator {
    constructor(private myPluralizer: Pluralizer) {
        super(myPluralizer);
    }

    protected getResourceUrls(entityName: string, root: string) {
        let resourceUrls = this.knownHttpResourceUrls[entityName];

        if (!resourceUrls) {
            const nRoot = normalizeRoot(root);

            const url = `${nRoot}/${this.myPluralizer.pluralize(
                entityName
            )}/`.toLowerCase();

            resourceUrls = {
                entityResourceUrl: url,
                collectionResourceUrl: url
            };

            this.registerHttpResourceUrls({
                [entityName]: resourceUrls
            });
        }

        return resourceUrls;
    }
}
