<div class="sort_list_item {{sortingDirection === 0 ? 'sort_list_item-inactive' : ''}} sort_list_item-{{align}}">
    <div class="sort_list_item__text sort_list_item__text-{{align}}" [innerHTML]="text"></div>
    <div [ngClass]="{
            'sort_list_item__img': true,
            'sort_list_item__img-default': sortingDirection === 0,
            'sort_list_item__img-sorting_up': sortingDirection > 0,
            'sort_list_item__img-sorting_down': sortingDirection < 0
        }"
    ></div>
</div>
