import { Component, OnInit } from '@angular/core';
import { TEXT } from 'src/locales/text';

@Component({
    selector: 'strategy-page',
    templateUrl: './strategy-page.component.html',
    styleUrls: ['./strategy-page.component.less']
})
export class StrategyPageComponent implements OnInit {
    TEXT = TEXT;

    constructor() {
    }

    ngOnInit(): void {
    }

}
