import { Observable } from 'rxjs';
import {
    filter,
    map
} from 'rxjs/operators';
import { Organization } from '../models';
import { OrganizationService } from '../services/organization.service';

export function byId(orgSrv: OrganizationService, id): Observable<Organization> {
    return orgSrv.entityMap$.pipe(
        // map Dict<org_id, org> to the organization object with same id as in the router
        map(orgsMap => orgsMap?.[id]), // org with id from route or null
        // filter null values
        filter(v => !!v),
    )
}
