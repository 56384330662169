import { Map as MapboxMap } from 'mapbox-gl';

import {
    MAPBOX_BOUNDARY_LAYERS,
    MAPBOX_LABEL_LAYERS,
    MAPBOX_LABELS_COLOR,
    MAPBOX_STYLES,
} from 'src/pages/map-page/map.config';
import { FacilityEmission, Facility } from 'src/store/models';

export function applyCustomStyles(map: MapboxMap, language: string) {
    const textField = [
        'coalesce',
        ['get', 'name_' + language],
        ['get', 'name']
    ];

    ['hillshade', ...MAPBOX_BOUNDARY_LAYERS]
        .filter(layerName => map.getLayer(layerName))
        .forEach(layerName => {
            map.setLayoutProperty(layerName, 'visibility', 'none');
        });

    MAPBOX_LABEL_LAYERS
        .filter(layerName => map.getLayer(layerName))
        .forEach(layerName => {
            map.setLayoutProperty(layerName, 'text-field', textField);
            map.setPaintProperty(layerName, 'text-color', MAPBOX_LABELS_COLOR);
        });

    Object.entries(MAPBOX_STYLES)
        .filter(([layerName]) => map.getLayer(layerName))
        .forEach(([layerName, styles]) => {
            Object.entries(styles).forEach(([style, value]) => {
                map.setPaintProperty(layerName, style, value);
            });
        });
}

export function applyHoverOverLayer(
    map: MapboxMap,
    layerName: string,
    sourceName?: string,
    sourceLayer?: string
) {
    if (!sourceName) {
        sourceName = layerName;
    }

    if (!sourceLayer) {
        sourceLayer = sourceName;
    }

    let hoveredStateId = null;

    const canvasStyle = map.getCanvas().style;

    map.on('mousemove', layerName, (e) => {
        if (e.features.length) {
            if (hoveredStateId !== null) {
                map.setFeatureState(
                    { source: sourceName, sourceLayer, id: hoveredStateId },
                    { hover: false }
                );
            }

            hoveredStateId = e.features[0].id;

            canvasStyle.cursor = 'pointer';

            map.setFeatureState(
                { source: sourceName, sourceLayer, id: hoveredStateId },
                { hover: true }
            );
        }
    });

    map.on('mouseleave', layerName, () => {
        if (hoveredStateId !== null) {
            canvasStyle.cursor = 'auto';

            map.setFeatureState(
                { source: sourceName, sourceLayer, id: hoveredStateId },
                { hover: false }
            );
        }

        hoveredStateId = null;
    });
}

const MAX_RADIUS = 100;
const MIN_RADIUS = 12;

function emissionToRadius(x: number, min: number, max: number) {
    return MIN_RADIUS + Math.sqrt(((MAX_RADIUS - MIN_RADIUS) ** 2) * ((x - min) / max));
}

export function createFacilitiesAreaGeoJson(
    fems: FacilityEmission<Facility>[]
): GeoJSON.FeatureCollection<
    GeoJSON.Point,
    {
        id: number;
        emission: number;
        category: number;
    }
> {
    const emissions = fems.map(r => r.emission);
    const minEmission = Math.min(...emissions);
    const maxEmission = Math.max(...emissions);

    return {
        type: 'FeatureCollection',
        features: fems.filter(f => f.facility.coords).map(({ facility, emission }) => ({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: facility.coords
            },
            properties: {
                id: facility.id,
                emission: emissionToRadius(emission, minEmission, maxEmission),
                category: 0
            }
        }))
    };
}

export function createIntrpolation(min: number, max: number) {
    let k = 1 / (max - min) || 1;

    k = Number.isFinite(k) ? k : 0;

    return (val: number) => k * (val - min);
}
