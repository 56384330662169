<div class="region-list__item no_highlight_touch"
     (click)="selectedRegionIds.length !== 0 ? removeAll.emit() : ''">
    <ca-checkbox
            [checked]="selectedRegionIds.length === 0"
    ></ca-checkbox>
    <div class="region-list__item_text">{{TEXT.others.regionAll}}</div>
</div>
<div *ngFor="let r of regions"
     class="region-list__item no_highlight_touch"
     (click)="selectedRegionIds.includes(r.id) ? remove.emit(r.id) : add.emit(r.id)">
    <ca-checkbox
            [checked]="selectedRegionIds.includes(r.id)"
    ></ca-checkbox>
    <div class="region-list__item_text">{{r.name}}</div>
</div>