import { Component } from '@angular/core';

import { POPUPS } from 'src/namespace';
import { PAGES } from 'src/navigation/navigation-config';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsServerError } from 'src/store/selectors';
import { Router } from '@angular/router';

@Component({
    selector: 'main-page',
    templateUrl: 'main-page.component.html',
    styleUrls: ['./main-page.component.less']
})
export class MainPageComponent {
    showError$: Observable<boolean>;

    isClosed = false;

    constructor(store: Store, private router: Router) {
        this.showError$ = store.select(selectIsServerError);
    }

    close() {
        this.isClosed = true;

        // reload after go to another page
        this.router.events.subscribe(async () => {
            location.reload();
        });
    }

    page = PAGES;
    popup = POPUPS;
}
