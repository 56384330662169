import { Component, Input, OnChanges } from '@angular/core';

import { SidebarStateService } from '../../../sidebar/sidebar-state.service';

@Component({
    selector: 'ca-sidebar',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.less']
})
export class SidebarComponent implements OnChanges {
    @Input() isActive: boolean;

    constructor(private stateService: SidebarStateService) {}

    ngOnChanges() {
        this.stateService.setStatus(this.isActive);
    }
}
