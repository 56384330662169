import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TEXT } from '../../../../locales/text';
import { STATUS_COLOR } from 'src/namespace';

@Component({
    selector: 'status-tags',
    template: `<div class='status_tag ellipsis status_tag-{{color}}'>{{text}}</div>`,
    styleUrls: ['status-tags.component.less']
})
export class StatusTagsComponent implements OnChanges {
    @Input() status: string;

    text = '';
    color: 'red' | 'yellow' | 'green' | 'gray';

    ngOnChanges(changes: SimpleChanges) {
        this.text = TEXT.statusManager[this.status];
        this.color = STATUS_COLOR[this.status];
    }
}
