import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor
} from '@angular/common/http';
import { throwError } from 'rxjs';
import {
    catchError,
} from 'rxjs/operators';

import { AuthService } from 'src/modules/core/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const modifiedRequest = this.updateAuthHeaders(req);

        return next.handle(modifiedRequest).pipe(
            catchError(err => {
                console.log('error', err);
                if ( err?.status == 401 ) {
                    this.authService.gotoLogin();
                }

                // @todo: show something wrong on server error in popup?
                // @ts-ignore
                if (err.status > 401 && window.sentryCaptureException)
                { // @ts-ignore
                    window.sentryCaptureException(err);
                }

                return throwError(err);
            })
        );
    }

    updateAuthHeaders(req: HttpRequest<any>): HttpRequest<any> {
        const idToken = this.authService.getAccessToken();
        if ( !idToken ) {
            return req;
        }
        return req.clone({
            headers: req.headers.set('Authorization', `Bearer ${idToken}`)
        });
    }
}
