import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { ROLE, User } from 'src/namespace';
import { AuthService } from '../auth.service';
import { PAGES } from '../../../../navigation/navigation-config';

// TODO: delete
export const START_PAGE: {
    [k in ROLE]: PAGES;
} = {
    [ROLE.ro]: PAGES.ro,
    [ROLE.government]: PAGES.map,
    [ROLE.executive]: PAGES.map,
};

@Injectable({
    providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const user = this.authService.getUser();

        const isValidRole = this.checkValidRole(user);

        if (!isValidRole) {
            this.authService.logout();
            this.authService.gotoLogin();
        }

        const isAccessGranted = this.checkUserAccess(route, user);

        if (!isAccessGranted) {
            return this.router.parseUrl('');
        }

        return true;
    }

    private checkValidRole(user: User) {
        return Object.values(ROLE).includes(user.role as ROLE);
    }

    private checkUserAccess(route: ActivatedRouteSnapshot, user: User) {
        const role = user.role;

        const allowedRoles = route.data?.allowed_roles;

        return allowedRoles ? allowedRoles.includes(role) : true;
    }
}
