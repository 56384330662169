<div class="balance-page">
    <balance-widget class="balance-page__chart">
        <balance-chart
            [data]="chartData"
            [year]="selectedYear"
            (changeYear)="changeYear($event)"
        ></balance-chart>
    </balance-widget>

    <div class="balance-page__header">
        <balance-widget>
            <ct-period-status-badge class="balance-page__widget-badge"
                [period]="selectedYear"
                [workingPeriod]="REPORT_YEAR"
            ></ct-period-status-badge>
            <div class="balance-page__header-main">
                <div class="balance-page__header-main-title">{{TEXT.uoivBalance.balance}} {{selectedYear}}</div>
                <div class="balance-page__header-main-value">
                    <ct-balance-change-indicator
                        [previous]="previousFadaster.getBalance()"
                        [current]="selectedFadaster.getBalance()"
                    ></ct-balance-change-indicator>
                    <balance-value [value]="selectedFadaster.getBalance()" size="large"></balance-value>
                </div>
            </div>
            <div class="balance-page__header-separator"></div>
            <div class="balance-page__header-summary">
                <div class="balance-page__header-summary-item"
                    *ngFor="let item of headerUiConfig"
                >
                    <div class="balance-page__header-summary-item-title">
                        {{TEXT.uoivBalance.summaryTitles[item.type]}}
                    </div>
                    <balance-value class="balance-page__header-summary-item-value"
                        [value]="selectedFadaster[item.key]"
                        [isBalance]="false"
                    ></balance-value>
                </div>
            </div>
        </balance-widget>

        <balance-widget>
            <ct-gradient-text class="balance-page__header-justification"
                caTooltip
                caTooltipPlacement="top"
                [caTooltipTemplate]="dummyButton"
            >
                {{TEXT.uoivBalance.planJustification}}
            </ct-gradient-text>
        </balance-widget>

        <div class="balance-page__edit" *ngIf="canEdit">
            <ct-edit-button
                caTooltip
                caTooltipPlacement="top"
                [caTooltipTemplate]="dummyButton"
            >
                {{TEXT.uoivBalance.edit}}
            </ct-edit-button>
        </div>
    </div>

    <div class="balance-page__tabs">
        <div class="balance-page__tabs-item"
            *ngFor="let tab of tabs" (click)="selectTab(tab)"
            [class.balance-page__tabs-item--active]="tab === activeTab"
        >
            {{tab.title}}
        </div>
    </div>

    <div class="balance-page__body" [ngSwitch]="activeTab.id">
        <ng-container *ngSwitchCase="'inventory'">
            <div class="balance-page__column balance-page__column-absorption">
                <balance-widget>
                    <balance-summary-table
                        [type]="data.absSeqs.type"
                        [value]="data.absSeqs.value"
                        [details]="data.absSeqs.details"
                        [title]="data.absSeqs.title"
                    ></balance-summary-table>
                </balance-widget>
            </div>
            <div class="balance-page__column balance-page__column-emissions">
                <balance-widget>
                    <balance-summary-table
                        [type]="data.secEmFadaster.type"
                        [value]="data.secEmFadaster.value"
                        [details]="data.secEmFadaster.details"
                        [title]="data.secEmFadaster.title"
                    ></balance-summary-table>
                </balance-widget>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'regulation'">
            <div class="balance-page__column balance-page__column-absorption">
                <balance-widget>
                    <balance-summary-table
                        [type]="data.climateProjects.type"
                        [value]="data.climateProjects.value"
                        [details]="data.climateProjects.details"
                        [title]="data.climateProjects.title"
                    ></balance-summary-table>
                </balance-widget>
            </div>
            <div class="balance-page__column balance-page__column-emissions">
                <balance-widget>
                    <balance-summary-table
                        [type]="data.roEmissions.type"
                        [value]="data.roEmissions.value"
                        [details]="data.roEmissions.details"
                        [title]="data.roEmissions.title"
                    ></balance-summary-table>
                </balance-widget>
            </div>
        </ng-container>
    </div>

    <ct-annual-timeline
        class="balance-page__timeline"
        [years]="yearsList"
        [currentYear]="selectedYear"
        (selectedYear)="changeYear($event)"
    ></ct-annual-timeline>

    <ng-template #dummyButton>
        <ca-tooltip [text]="TEXT.uoivBalance.dummyButton"></ca-tooltip>
    </ng-template>
</div>
