import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EMISSIONS } from 'src/namespace';
import { TEXT } from '../../../../locales/text';

@Component({
    selector: 'emissions-list',
    templateUrl: './emissions-list.component.html',
    styleUrls: ['./emissions-list.component.less']
})
export class EmissionsListComponent {
    @Input() selectedEmissions: EMISSIONS[];

    @Output() add: EventEmitter<EMISSIONS> = new EventEmitter();
    @Output() remove: EventEmitter<EMISSIONS> = new EventEmitter();
    @Output() removeAll: EventEmitter<void> = new EventEmitter();

    TEXT = TEXT;

    isScrolled = false;

    constructor() {
    }
}
