<div class="dashboard-widget">
    <ct-period-status-badge class="dashboard-widget__badge"
        [period]="REPORT_YEAR"
        [workingPeriod]="REPORT_YEAR"
    ></ct-period-status-badge>
    <div class="dashboard-widget__year">{{year}}</div>

    <div class="dashboard-widget__item">
        <div class="dashboard-widget__subtitle">{{TEXT.DASHBOARD_WIDGET.quote}}</div>
        <div>
            <span class="dashboard-widget__value">{{quote  | emission }}</span>
            <span class="dashboard-widget__measure" [innerHTML]="TEXT.others.widgetUnit"></span>
        </div>
    </div>
    <div class="dashboard-widget__item">
        <div class="dashboard-widget__subtitle">{{TEXT.DASHBOARD_WIDGET.emission}}</div>
        <div>
            <span class="dashboard-widget__value">{{emission  | emission }}</span>
            <span class="dashboard-widget__measure" [innerHTML]="TEXT.others.widgetUnit"></span>
        </div>
    </div>
    <div class="dashboard-widget__item">
        <div class="dashboard-widget__subtitle">{{TEXT.DASHBOARD_WIDGET.boughtUnits }}</div>
        <div>
            <span class="dashboard-widget__value">{{boughtUnits | number: '1.0-0'}}</span>
            <span class="dashboard-widget__measure" [innerHTML]="TEXT.DASHBOARD_WIDGET.measureUnit"></span>
        </div>
    </div>
    <div class="dashboard-widget__item">
        <div class="dashboard-widget__subtitle">{{TEXT.DASHBOARD_WIDGET.soldUnits}}</div>
        <div>
            <span class="dashboard-widget__value">{{soldUnits}}</span>
            <span class="dashboard-widget__measure" [innerHTML]="TEXT.DASHBOARD_WIDGET.measureUnit"></span>
        </div>
    </div>
</div>
