import { Component, EventEmitter, Input, Output } from '@angular/core';

import { isRU, TEXT } from 'src/locales/text';
import { TabModel, User } from 'src/namespace';
import { changeLang } from 'src/utils';

@Component({
    selector: 'carbon-trail-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
export class HeaderComponent {
    @Input() user: User;

    @Input() tabs: TabModel[];

    @Output() logout = new EventEmitter<void>();

    TEXT = TEXT;
    isRu = isRU;

    changeLang = changeLang;
}
