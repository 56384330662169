import { Component, Input, OnChanges } from '@angular/core';
import { TEXT } from 'src/locales/text';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { Color } from 'ng2-charts/lib/color';

@Component({
    selector: 'dashboard-chart',
    templateUrl: './dashboard-chart.component.html',
    styleUrls: ['./dashboard-chart.component.less']
})
export class DashboardChartComponent implements OnChanges {
    @Input() awaitingSum: number;
    @Input() emptySum: number;
    @Input() approvedSum: number;
    @Input() reworkSum: number;
    @Input() title: string;
    @Input() isDisable?: boolean;

    TEXT = TEXT;
    doughnutChartType: ChartType = 'doughnut';
    doughnutChartLabels: Label[];
    doughnutChartData: MultiDataSet;
    color: Color[];


    options: ChartOptions = {
        tooltips: {
            enabled: false
        },
        legend: {
            display: false
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        },
    };


    ngOnChanges(): void {
        this.doughnutChartLabels = [];
        this.doughnutChartData = [[this.awaitingSum, this.emptySum, this.approvedSum, this.reworkSum]];
        this.color = [{backgroundColor: ['#FFCC45', 'rgba(105, 69, 208, 0.775844)', '#2CCE54', '#EA484C']}]
    }
}
