<div class="error-popup" (clickOutside)="close()">
    <div class="error-popup__close" (click)="close()"></div>
    <div class="error-popup__img"></div>
    <div>
        <div class="error-popup__text-main">{{TEXT.ERROR_POPUP.alasWeCouldNot}}</div>
        <div class="error-popup__text-reload">{{TEXT.ERROR_POPUP.tryRefreshingThePage}}</div>
        <div class="error-popup__text-question">{{TEXT.ERROR_POPUP.didNotHelp}}
            <a href="mailto:info@cityair.ru">{{TEXT.ERROR_POPUP.writeToUs}}</a>
        </div>
    </div>
</div>
