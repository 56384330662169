<div class="tags-list">
    <ng-container *ngIf="isExpanded || !collapseAll">
    <div class="tags-list__item"
        [class.tags-list__item--editable]="editable"
        *ngFor="let tag of tags | slice : 0 : maxVisibleTags; index as i"
    >
        <div class="tags-list__text">{{tagsMapper(tag)}}</div>
        <ca-cross-button class="tags-list__delete"
            size="small"
            *ngIf="editable"
            (click)="remove(i)"
        ></ca-cross-button>
    </div>
    </ng-container>
    <ng-container *ngIf="textNodesFns">
    <div class="tags-list__item tags-list__item--expand"
        *ngIf="tags.length > maxVisibleTags"
        (click)="expandList()"
    >
        {{collapseAll ? textNodesFns.all() : textNodesFns.expand(tags.length - maxVisibleTags)}}
    </div>
    <div class="tags-list__item tags-list__item--expand"
        *ngIf="isExpanded"
        (click)="collapseList()"
    >
        {{textNodesFns.collapse()}}
    </div>
    </ng-container>
</div>
