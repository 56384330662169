import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from '../auth.service';
import { PAGES } from '../../../../navigation/navigation-config';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const isLoggedIn = this.authService.isLoggedIn();
        const isLoginPage = route.routeConfig.path === PAGES.login;

        if (isLoggedIn && isLoginPage) {
            return this.router.parseUrl('');
        }

        if (!isLoggedIn && !isLoginPage) {
            return this.router.parseUrl(PAGES.login);
        }

        return true;
    }
}
