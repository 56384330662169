<dropdown-menu
    [label]="TEXT.table.sector"
    [value]="orgTypeItem?.label"
    [(showDropdown)]="orgTypeDropdownOpened"
    (clickOutside)="orgTypeDropdownOpened = false"
>
    <ca-selectbox-radio
        [(listItems)]="listOrgTypes"
        (listItemsChange)="filterBy($event); orgTypeDropdownOpened = false"
    ></ca-selectbox-radio>
</dropdown-menu>
