export const MAPBOX_STYLES = {
    landuse: {
        'fill-color': '#dfe5dc'
    },
    landcover: {
        'fill-color': '#dfe5dc'
    },
    water: {
        'fill-color': '#A8CDFF'
    },
    land: {
        'background-color': '#f0f0f0'
    }
};

export const MAPBOX_LABELS_COLOR = '#999999';

export const MAPBOX_BOUNDARY_LAYERS = [
    'admin-0-boundary-disputed',
    'admin-0-boundary',
    'admin-0-boundary-bg',
    'admin-1-boundary',
    'admin-1-boundary-bg'
];

export const MAPBOX_LABEL_LAYERS = [
    'road-label',
    'waterway-label',
    'natural-line-label',
    'natural-point-label',
    'water-line-label',
    'water-point-label',
    'poi-label',
    'airport-label',
    'settlement-subdivision-label',
    'settlement-label',
    'state-label',
    'country-label'
];
