<div class="wrapper">

    <div class="close" (click)="facade.setCurrentPopup(null)"> </div>

    <div class="header-report">
        <div class="header-report__title">{{TEXT.others.emissionReport}}</div>
        <div class="header-report__number">№ {{report.id}}</div>
        <status-tags [status]="report.status"></status-tags>
    </div>

    <div class="header-report__subtitle">{{report.facility.name}}</div>

    <div class="description-report">
        <div class="description-report__block">
            <div class="description-report__period">
                <div class="description-report__title">{{TEXT.others.reportingPeriod}}</div>
                <div class="description-report__value">{{report.year}}</div>
            </div>
            <div class="description-report__emission">
                <div class="description-report__title">{{TEXT.form.emission}}</div>
                <div>
                    <span class="description-report__value">{{report.emission}}</span>
                    <span class="description-report__value-util_left description-report__value-util" [innerHTML]="TEXT.table.emission.split(',')[1]"></span>
                </div>
            </div>
        </div>
        <div class="description-report__comments">
            <div class="description-report__title">{{TEXT.others.commentaryNature}}</div>
            <div class="description-report__comments_text">{{report.comment}}</div>
            <div>
                <span *ngIf="report.approved_at" class="description-report__value-util" style="margin-right: 16px;">
                    {{report.approved_at | date: 'mm:hh DD MMM YYYY'}}
                </span>
                <span class="description-report__email">{{report.blame?.email}}</span>
            </div>
        </div>
    </div>

    <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
        <ct-input
                [form]="form"
                [controlName]="'emission'"
                [label]="TEXT.form.emission"
                [type]="'number'"
                [valueForm]="form.controls.emission.value"
                [textError]="(form.controls.emission.invalid && form.controls.emission.touched ? TEXT.form.errorEmission : '') || emissionError"
                [prompt]="TEXT.others.tg"
        ></ct-input>

        <ca-button
                class="submit"
                [type]="'primary'"
                (clickAction)="onSubmit()"
                [disabled]="form.invalid"
        >
            <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
            <ng-template #showContent>{{ TEXT.form.sendCorrect }}</ng-template>
        </ca-button>
    </form>
</div>