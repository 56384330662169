<div class="selectbox-radio">
    <div class="selectbox-radio__list scroll" [formGroup]="form">
        <div class="selectbox-radio__list-item"
            *ngFor="let item of listItems"
        >
            <label class="selectbox-radio__list-item__label">
                <input type="radio" formControlName="selectedItem" [value]="item.id" />
                <item-radio class="selectbox-radio__list-item__checkmark"
                    [check]="item.selected"
                ></item-radio>
                <div class="selectbox-radio__list-item__text">{{item.label}}</div>
            </label>
        </div>
    </div>
</div>
