import { TabModel } from 'src/namespace';
import { TEXT } from '../locales/text';

export enum PAGES {
    ro = 'ro',
    reportCoordinator = 'report-coordinator',
    login = 'login',
    map = 'map',
    mobile = 'mobile',
    facilities = 'facilities',
    balance = 'balance',
    reports = 'reports',
    dashboard = 'dashboard',
    strategy = 'strategy',
    normative = 'normative-acts',
}

export enum RO_PAGES {
    reports = 'reports',
    normative = 'normative-acts',
    facilities = 'facilities',
    details = 'details',
    balance = 'balance',
}

export enum EXECUTIVE_PAGES {
    map = 'map',
    balance = 'balance',
    ro = 'ro',
    facilities = 'facilities',
    dashboard = 'dashboard',
    reports = 'reports',
    strategy = 'strategy',
    normative = 'normative-acts',
}

export const MAP_MENU_ITEMS: TabModel[] = [
    {
        id: `${PAGES.map}`,
        title: TEXT.menuBalance.map
    },
    {
        id: `${PAGES.balance}`,
        title: TEXT.menuBalance.table
    },
];

export const RO_MENU_ITEMS: TabModel[] = [
    {id: `${PAGES.ro}/${RO_PAGES.details}`, title: TEXT.RO_DETAILS_PAGE.title},
    {id: `${PAGES.ro}/${RO_PAGES.balance}`, title: TEXT.pageNames.balance},
    {id: `${PAGES.ro}/${RO_PAGES.facilities}`, title: TEXT.pageNames.facilities},
    {id: `${PAGES.ro}/${RO_PAGES.reports}`, title: TEXT.pageNames.reports},
    {id: `${PAGES.ro}/${RO_PAGES.normative}`, title: TEXT.pageNames.normative},
];

export const RO_SUB_MENU_ITEMS: TabModel[] = [
    {id: RO_PAGES.details, title: TEXT.RO_DETAILS_PAGE.title},
    {id: RO_PAGES.balance, title: TEXT.pageNames.balance},
    {id: RO_PAGES.facilities, title: TEXT.pageNames.facilities},
    {id: RO_PAGES.reports, title: TEXT.pageNames.reports, isDisabled: true},
];

export const EXECUTIVE_MENU_ITEMS: TabModel[] = [
    {id: EXECUTIVE_PAGES.map, title: TEXT.pageNames.map},
    {id: EXECUTIVE_PAGES.balance, title: TEXT.pageNames.balance},
    {id: EXECUTIVE_PAGES.ro, title: TEXT.pageNames.ro},
    {id: EXECUTIVE_PAGES.facilities, title: TEXT.pageNames.facilities},
    {id: EXECUTIVE_PAGES.dashboard, title: TEXT.pageNames.dashboard},
    {id: EXECUTIVE_PAGES.reports, title: TEXT.pageNames.reports},
    {id: EXECUTIVE_PAGES.strategy, title: TEXT.pageNames.strategy},
    {id: EXECUTIVE_PAGES.normative, title: TEXT.pageNames.normative},
];
