<div class="filter-block__wrapper">
    <div class="filter-block">
        <div class="filter-block__search_list_wrapper" conte>
            <searchbar
                    class="facilities-page__filters-searchbar"
                    [placeholder]=""
                    (textChange)="filter$.next({'text': $event})"
            ></searchbar>
        </div>


            <div *ngIf="yearFilterValues.length > 1"
                 class="filter-block__filter_dropdown_wrapper">
                <selectbox-year
                    class="facilities-page__filters-dropdown"
                    [list]="yearFilterValues"
                    (filterByItem)="filter$.next({'year': $event.id.toString()})"
                ></selectbox-year>
            </div>

    <!--        <div *ngIf="arrStatusForDropdownList.length > 1"-->
    <!--             class="filter-block__filter_dropdown_wrapper">-->
    <!--        </div>-->
    <!--        <div *ngIf="arrStatusForDropdownList.length > 1"-->
    <!--             class="filter-block__filter_dropdown_wrapper">-->
    <!--            <selectbox-status-->
    <!--                class="facilities-page__filters-dropdown"-->
    <!--                [list]="arrStatusForDropdownList"-->
    <!--                (filterByItem)="changesStatus($event)"-->
    <!--            ></selectbox-status>-->
    <!--        </div>-->

    <!--    <div (click)="cleanFilterObject()" [ngClass]="{-->
    <!--            'filter-block__btn_clear': true,-->
    <!--            'filter-block__btn_clear&#45;&#45;active': this.objectFilter.status || this.objectFilter.year-->
    <!--            }">{{TEXT.others.clears}}</div>-->

 </div>
    <ca-button
            class="btn_add_report"
            [type]="'primary'"
            (clickAction)="facade.goToCreateReport()"
            [disabled]="true"
    >
        <div class="btn_add_report__img"></div>
        <div class="btn_add_report__text">{{TEXT.header.createReport}}</div>
    </ca-button>
</div>

<div class="body-container  scroll scroll-transparent">
    <ng-template #reports_table let-row="row" let-columns="cols">
        <td class="table__font_weight_semi_bold table__font_color_gray">{{row.id}}</td>
        <td class="table__font_weight_semi_bold center">
            <organization-type-icon [slug]="row.sector?.slug"></organization-type-icon>
        </td>
        <td class="table__font_weight_semi_bold">{{row.name}}</td>
        <td class="table__font_weight_semi_bold">{{row.address}}</td>
        <td class="table__font_weight_bold table__number right">{{row.emission | emission}}</td>
        <td>{{row.sent_at | date: 'mediumDate'}}</td>
        <td>{{row.approved_at| date: 'mediumDate'}}</td>
        <td>{{row?.sender?.email}}</td>
        <td>{{row?.comment}}</td>
        <td class="center">{{row?.status}}</td>
    </ng-template>

    <ct-data-grid
        tableClass="table"
        trHeaderClass="table__header"
        trClass="table__content_tr"
        [rows]="reports"
        [sorter]="sorter"
        [template]="reports_table"
    ></ct-data-grid>
</div>
