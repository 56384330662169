import { Component } from '@angular/core';

import { SidebarStateService } from '../../sidebar-state.service';

@Component({
    selector: 'ca-page-container',
    templateUrl: 'page-container.component.html',
    styleUrls: ['page-container.component.less']
})
export class PageContainerComponent {
    constructor(readonly stateService: SidebarStateService) {}
}
