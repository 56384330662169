import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    ClickOutsideDirective,
    AfterInitDirective
} from './directives';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        ClickOutsideDirective,
        AfterInitDirective,
    ],
    declarations: [
        ClickOutsideDirective,
        AfterInitDirective,
    ]
})
export class DirectivesModule {}
