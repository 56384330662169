import { HttpErrorResponse } from '@angular/common/http';
import { ObservableInput, throwError } from 'rxjs';

export class FormError {
    [key: string]: string[];

    constructor(obj: any) {
        Object.keys(obj).forEach(key => {
            if (obj[key].length)
                {this[key] = obj[key][0];}
        })
    }
}

export class ApiModels {
    private _errorCb: (data: HttpErrorResponse) => void;
    private _errorTokenCb: () => void;

    setErrorTokenCb = (cb: () => void) => this._errorTokenCb = cb;
    setErrorCb = (cb: (data: HttpErrorResponse) => void) => this._errorCb = cb;

    errorHandler = (error: HttpErrorResponse): ObservableInput<any> => {
        if (error.status === 401 && this._errorTokenCb) {
            this._errorTokenCb();
            return null;
        }

        // @ts-ignore
        if (error.status > 401 && window.sentryCaptureException)// @ts-ignore
            {window.sentryCaptureException(error);} // (public sentry: ErrorHandler) почему то не работает

        this._errorCb?.(error);

        return throwError(error);
    }
}
