import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';

import { DirectivesModule } from 'src/directives/directives.module';
import { TagsListModule } from 'src/components/tags-list/tags-list.module';
import { CheckboxModule } from 'src/components/checkbox/checkbox.module';
import { CrossButtonModule } from 'src/components/cross-button/cross-button.module';
import { PipesModule } from 'src/pipes/pipes.module';
import { TooltipsModule } from 'src/components/tooltips/tooltips.module';
import { KebabMenuModule } from 'src/components/kebab-menu/kebab-menu.module';
import { CoreModule } from '../core/core.module';

import { TableNormativeActsModule } from 'src/pages/table-normative-acts-page/table-normative-acts.module';

import { BalanceContributionCardComponent } from './components/balance-contribution-card/balance-contribution-card.component';
import { RegionsListComponent } from './components/regions-list/regions-list.component';
import { MarkerCircleComponent } from './components/marker-circle/marker-circle.component';
import { MarkerPinComponent } from './components/marker-pin/marker-pin.component';
import { PopupFacilityComponent } from './components/popup-facility/popup-facility.component';
import { PopupSubdivisionComponent } from './components/popup-subdivision/popup-subdivision.component';
import { EmissionsListComponent } from './components/emissions-list/emissions-list.component';
import { ListPanelComponent } from './components/list-panel/list-panel.component';
import { BalanceTooltipComponent } from './components/balance-tooltip/balance-tooltip.component';
import { MeatballsMenuComponent } from './components/meatballs-menu/meatballs-menu.component';
import { PopupDocumentsListComponent } from './components/popup-documents-list/popup-documents-list.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';
import { ContributionValueComponent } from './components/contribution-value/contribution-value.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ChartsModule,
        PipesModule,
        TooltipsModule,
        CoreModule,
        DirectivesModule,
        CrossButtonModule,
        KebabMenuModule,
        TableNormativeActsModule,
        TagsListModule,
        CheckboxModule,
    ],
    exports: [
        BalanceContributionCardComponent,
        MarkerCircleComponent,
        MarkerPinComponent,
        PopupFacilityComponent,
        PopupSubdivisionComponent,
        RegionsListComponent,
        EmissionsListComponent,
        OrganizationsListComponent,
        PopupDocumentsListComponent,
        ListPanelComponent,
        SidebarMenuComponent,
    ],
    declarations: [
        BalanceContributionCardComponent,
        MarkerCircleComponent,
        MarkerPinComponent,
        PopupFacilityComponent,
        PopupSubdivisionComponent,
        RegionsListComponent,
        EmissionsListComponent,
        OrganizationsListComponent,
        BalanceTooltipComponent,
        MeatballsMenuComponent,
        PopupDocumentsListComponent,
        ListPanelComponent,
        SidebarMenuComponent,
        ContributionValueComponent,
    ],
})
export class StateMapModule {}
