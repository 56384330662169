import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private language: string;

    constructor() {
        const lang = localStorage.getItem('lang');

        const isRU = !!~(lang || navigator.language).indexOf('ru');

        this.language = lang || (isRU ? 'ru' : 'en');

        document.documentElement.lang = lang;
    }

    getLanguage() {
        return this.language;
    }
}
