export class Gradient {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D;

    constructor(resolution: number, colorStops: [number, string][] = []) {
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');

        const width = Math.round(resolution);
        const height = 1;

        this.canvas.width = width;
        this.canvas.height = height;

        const gradient = this.ctx.createLinearGradient(0, 0, width, height);

        for (const cs of colorStops) {
            gradient.addColorStop(...cs);
        }

        this.ctx.fillStyle = gradient;
        this.ctx.fillRect(0, 0, width, height);
    }

    getColorAtPercentage(percentage: number) {
        if (!percentage && percentage !== 0)  {
            // data is N/A
            return 'rgba(120, 120, 120, .2)';
        }

        const x = Math.round(percentage * (this.canvas.width - 1));
        const { data } = this.ctx.getImageData(x, 0, 1, 1);

        return `rgba(${data[0]}, ${data[1]}, ${data[2]}, ${data[3] / 255})`;
    }

    destroy() {
        if (this.canvas) {
            this.canvas.remove();
        }
    }
}
