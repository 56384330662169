<div class="contribution-value contribution-value--{{type}}">
    <div class="contribution-value__dt" [ngSwitch]="type">
        <ng-container *ngSwitchCase="'emission'">{{TEXT.common.emission}}</ng-container>
        <ng-container *ngSwitchCase="'sequestration'">{{TEXT.common.absorption}}</ng-container>
    </div>
    <div class="contribution-value__dd">
        <ng-container *ngIf="value | number : '1.0-0' as val; else noData">
            <span class="contribution-value__value">{{val}}</span>
            <span class="contribution-value__units">{{+val | i18nPlural : TEXT.UNITS.ton}}</span>
        </ng-container>
        <ng-template #noData>
            <span class="contribution-value__units">{{TEXT.common.noData}}</span>
        </ng-template>        
    </div>
</div>
