<div class="dashboard_chart"
     caTooltip
     caTooltipPlacement="top"
     [caTooltipTemplate]="isDisable ? dummyButton : null"
>
    <div class="dashboard_chart__top">
        <div class="dashboard_chart__canvas">
            <canvas baseChart
                    [height]="'100px'"
                    [width]="'100px'"
                    [options]="options"
                    [data]="doughnutChartData"
                    [labels]="doughnutChartLabels"
                    [chartType]="doughnutChartType"
                    [colors]="color"
            ></canvas>
        </div>
        <div class="dashboard_chart__title" [innerHTML]="title"></div>
    </div>

    <reports-status class="dashboard_chart__statuses"
                    [arrOfStatuses]="TEXT.statusCoord"
                    [awaitingSum]="awaitingSum"
                    [emptySum]="emptySum"
                    [approvedSum]="approvedSum"
                    [reworkSum]="reworkSum"
    ></reports-status>
</div>
<ng-template #dummyButton>
    <ca-tooltip [text]="TEXT.uoivBalance.dummyButton"></ca-tooltip>
</ng-template>