import { Component, NgZone, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Expression, LngLat, Map as MapboxMap } from 'mapbox-gl';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Dictionary } from '@ngrx/entity';

import { ANIMATION_HIDE, ANIMATION_TOP, REPORT_YEAR } from 'src/config';
import { Region } from 'src/namespace';
import { isRU, LANGUAGE, TEXT } from 'src/locales/text';
import { changeLang } from 'src/utils';
import { Gradient } from 'src/utils/gradient';
import { MapFacadeService } from 'src/modules/state-map/services/facade.service';
import { AuthService } from 'src/modules/core/services/auth.service';
import { MAP_SETTINGS } from 'src/pages/map-page/map-settings';
import {
    applyCustomStyles,
    applyHoverOverLayer,
    createFacilitiesAreaGeoJson,
    createIntrpolation,
} from 'src/pages/map-page/map.utils';
import { Facility, FacilityEmission, Inventorization, ROSummaryReport } from 'src/store/models';
import { FacilityEmissionService } from 'src/store/services/facility-emission.service';
import { RoSummaryReportService } from 'src/store/services/ro-summary-report.service';
import { InventorizationService } from 'src/store/services/inventorization.service';
import { RegionsService } from 'src/store/services/regions.service';
import { FadasterService } from 'src/store/services/fadaster.service';
import { LoaderService } from 'src/modules/core/services/loader.service';

const GREEN_GRADIENT: [number, string][] = [
    [0, 'rgba(3, 127, 3, 0.5)'],
    [0.5, 'rgba(40, 125, 40, 0.3)'],
    [1, 'rgba(133, 182, 2, 0.3)']
];

const EMISSION_CATEGORY_MAP = {
    fill: [
        [0, '#fff500'],
        [1, '#ff0000']
    ],
    border: [
        [0, '#ffd600'],
        [1, '#ff5000']
    ],
    point: [
        '#ffe600',
        '#ff4145'
    ]
};

const POPUP_OFFSET = 26;

const MARKER_BACKGROUND = 'linear-gradient(90deg, #74EEFF -159.68%, #E338B3 222.58%)';

function getRegionBalance(inv: Inventorization) {
    return inv && inv.emission - inv.sequestration;
}

@Component({
    selector: 'map-page',
    templateUrl: 'map-page.component.html',
    styleUrls: ['map-page.component.less'],
    animations: [ANIMATION_TOP, ANIMATION_HIDE]
})
export class MapPageComponent implements OnInit, OnDestroy {
    mapSettings = MAP_SETTINGS;
    emissionCategories = EMISSION_CATEGORY_MAP;

    facilitiesGeoJson = null;
    selectedFacilityId: number = null;
    selectedSubdivision: {
        coordinates: [number, number];
        color: string;
        emission: number;
        region: Region;
    } = null;

    gradientResolution = 100;
    gradient: Gradient;
    popupOffset = POPUP_OFFSET;

    facilityEmissions$: Observable<FacilityEmission<Facility>[]>;

    summaries$: Observable<ROSummaryReport[]>;

    visibleROs: ROSummaryReport[];

    selectedRoIds: number[] = [];

    onDestroy$ = new Subject<void>();

    TEXT = TEXT;
    isRu = isRU;

    loadingYear = 0;

    regionsProperties: {
        inventorizationMap: Dictionary<Inventorization>;
        regions: Region[];
    } = {
        inventorizationMap: {},
        regions: []
    };

    yearsList$: Observable<number[]>;

    constructor(
        private ngZone: NgZone,
        readonly authService: AuthService,
        readonly mapFacade: MapFacadeService,
        private facilityEmissionService: FacilityEmissionService<Facility>,
        private roSummaryService: RoSummaryReportService,
        private inventorizationService: InventorizationService,
        private regionsService: RegionsService,
        private fadasterService: FadasterService,
        private viewContainerRef: ViewContainerRef,
        private loaderService: LoaderService,
    ) {
        this.gradient = new Gradient(this.gradientResolution, GREEN_GRADIENT);

        this.facilityEmissionService.clearCache();
        this.roSummaryService.clearCache();

        this.facilityEmissions$ = this.facilityEmissionService.filteredEntities$;

        this.summaries$ = this.roSummaryService.filteredEntities$;
    }

    ngOnInit() {
        document.body.classList.add('bodyGovernor');

        this.loaderService.waitLoadingComplete(
            this.viewContainerRef,
            [this.inventorizationService, this.regionsService, this.facilityEmissionService, this.roSummaryService]
        );

        const year = this.mapFacade.getCurrentYear() || REPORT_YEAR;

        this.updateYearlyData(year);

        combineLatest([
            this.inventorizationService.entityMap$,
            this.regionsService.entities$
        ]).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(([inventorizationMap, regions]) => {
            this.regionsProperties = {
                inventorizationMap,
                regions
            };

            this.regionsPaintProperties = this.createPaintProperties(inventorizationMap, regions);
        });

        this.yearsList$ = this.fadasterService.entities$.pipe(
            map(fs => fs.map(f => f.year).filter(y => y <= REPORT_YEAR).reverse())
        );

        this.facilityEmissions$.pipe(
            takeUntil(this.onDestroy$)
        ).subscribe((facilities) => {
            this.facilitiesGeoJson = createFacilitiesAreaGeoJson(facilities);

            if (this.selectedSubdivision) {
                this.selectSubdivision(
                    this.selectedSubdivision.region.name,
                    new LngLat(...this.selectedSubdivision.coordinates)
                );
            }

            const id = this.selectedFacilityId;

            if (id && !this.facilitiesGeoJson.features.some((f: GeoJSON.Feature) => f.properties.id === id)) {
                this.deselectFacility(id);
            }
        });

        combineLatest([
            this.facilityEmissionService.entities$,
            this.roSummaryService.entities$
        ]).pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(([fems, summaries]) => {
            const ids = new Set(fems.map(f => f.facility.organization.id));

            this.selectedRoIds = [...ids];
            this.visibleROs = summaries.filter(s => ids.has(s.id));
            this.updateFilter();
        });
    }

    ngOnDestroy() {
        document.body.classList.remove('bodyGovernor');

        this.gradient.destroy();

        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    getUser = () => this.authService.getUser();

    logOut = () => {
        this.authService.removeRefreshToken();
        this.authService.removeAccessToken();
        this.authService.logout();
        location.reload();
    }

    mapboxLoad(map: MapboxMap) {
        this.ngZone.runOutsideAngular(() => {
            applyCustomStyles(map, LANGUAGE);
            applyHoverOverLayer(map, 'subdivisions');

            map.touchZoomRotate.disableRotation();

            map.on('click', ({point, lngLat}) => {
                const subdivisionsLayerName = 'subdivisions';
                const facilitiesLayerName = 'facilities';

                const features = map.queryRenderedFeatures(point, {
                    layers: [subdivisionsLayerName, facilitiesLayerName]
                });

                const subdivision = features.find(f => f.layer.id === subdivisionsLayerName);

                const facility = features.find(f => f.layer.id === facilitiesLayerName);

                if (subdivision && !facility) {
                    this.selectSubdivision(subdivision.properties.local_name, lngLat);
                    this.deselectFacility(this.selectedFacilityId);
                } else if (facility) {
                    this.deselectSubdivision();
                    this.selectFacility(facility.properties.id);
                } else {
                    this.deselectSubdivision();
                }
            });
        });
    }

    selectSubdivision(subdivisionName: string, lngLat: LngLat) {
        const {inventorizationMap, regions} = this.regionsProperties;

        const region = regions.find(r => r.name === subdivisionName);

        if (region) {
            const inv = inventorizationMap[region.id];

            this.selectedSubdivision = {
                coordinates: [lngLat.lng, lngLat.lat],
                color: MARKER_BACKGROUND,
                emission: inv.emission,
                region
            };
        } else {
            this.deselectSubdivision();
        }
    }

    updateYearlyData(year: number) {
        this.loadingYear = year;

        this.mapFacade.setCurrentYear(year);

        this.loadData(year);

        combineLatest([
            this.facilityEmissionService.loading$,
            this.roSummaryService.loading$,
            this.inventorizationService.loading$,
            this.regionsService.loading$
        ]).pipe(
            filter(loading => !loading.some(v => v)),
            take(1)
        ).subscribe(() => {
            this.loadingYear = 0;
        });
    }

    loadData(year: number) {
        const searchByYear = {
            year: `${year}`
        };

        this.roSummaryService.getWithQuery(searchByYear);

        // clear old entities since there is no separation by year
        this.facilityEmissionService.clearCache();

        this.facilityEmissionService.getWithQuery({
            ...searchByYear,
            expand: 'facility'
        });

        this.inventorizationService.getWithQuery(searchByYear);

        this.regionsService.getWithQuery(searchByYear);
    }

    isSidebarOpen = true;

    showPage() {
        this.isSidebarOpen = true;
    }

    hidePage() {
        this.isSidebarOpen = false;
    }

    regionsPaintProperties: Expression | 'transparent';

    balanceInterpolation: (val: number) => number;

    createPaintProperties(inventorizationMap: Dictionary<Inventorization>, regions: Region[]) {
        const defaultColor = 'transparent';

        /**
         * map balance values to gradient colors using interpolation
         * of the current balance values range for all regions
         */
        const balances = regions.map(r => getRegionBalance(inventorizationMap[r.id])).filter(b => !isNaN(b));
        this.balanceInterpolation = createIntrpolation(Math.min(...balances), Math.max(...balances));

        return regions.length ? [
            'match',
            ['get', 'local_name'],
            ...regions.reduce((acc, r) => [
                ...acc,
                r.name,
                this.gradient.getColorAtPercentage(
                    this.balanceInterpolation(getRegionBalance(inventorizationMap[r.id]))
                )
            ], []),
            defaultColor
        ] as Expression : defaultColor;
    }

    selectMapReport(facilityId: number) {
        return this.facilityEmissions$.pipe(
            map(fs => fs.find(f => f.facility.id === facilityId))
        );
    }

    selectFacility(facilityId: number) {
        this.selectedFacilityId = facilityId;
    }

    deselectFacility(facilityId: number) {
        if (this.selectedFacilityId === facilityId) {
            this.selectedFacilityId = null;
        }
    }

    deselectSubdivision() {
        this.selectedSubdivision = null;
    }

    changeLang = changeLang;

    addSelectedRO(id: number) {
        this.selectedRoIds = this.selectedRoIds ? [...this.selectedRoIds, id] : [id];
        this.updateFilter();
    }

    removeSelectedRO(id: number) {
        this.selectedRoIds = (this.selectedRoIds || this.getAllRoIds()).filter(v => v !== id);
        this.updateFilter();
    }

    selectAllRO(all: boolean) {
        this.selectedRoIds = all ? this.getAllRoIds() : [];
        this.updateFilter();
    }

    getAllRoIds() {
        return this.visibleROs.map(v => v.id);
    }

    updateFilter() {
        this.facilityEmissionService.setFilter({orgIds: this.selectedRoIds});
        this.roSummaryService.setFilter({orgIds: this.selectedRoIds});
    }
}
