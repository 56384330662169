import { Routes } from '@angular/router';
import { RoBalancePageComponent } from 'src/pages/ro-balance-page/ro-balance-page.component';

import { ROLE } from 'src/namespace';
import { PAGES, RO_PAGES } from './navigation-config';
import { CommonPageComponent } from 'src/pages/common-page/common-page.component';
import { RoListPageComponent } from 'src/pages/ro-list-page/ro-list-page.component';
import { FacilitiesPageComponent } from 'src/pages/facilities-page/facilities-page.component';
import { BalancePageComponent } from 'src/pages/balance-page/balance-page.component';
import { MapPageComponent } from 'src/pages/map-page/map-page.component';
import { SidebarPageComponent } from 'src/pages/sidebar-page/sidebar-page.component';
import { BalanceExcerptPageComponent } from 'src/pages/balance-excerpt-page/balance-excerpt-page.component';
import { RoDetailsPageComponent } from 'src/pages/ro-details-page/ro-details-page.component';
import { LayoutRoComponent } from 'src/pages/layout-ro/layout-ro.component';
import { OuivReportsPageComponent } from 'src/pages/ouiv-reports-page/ouiv-reports-page.component';
import { DashboardPageComponent } from 'src/pages/dashboard-page/dashboard-page.component';
import { StrategyPageComponent } from 'src/pages/strategy-page/strategy-page.component';
import { OneRoReportsPageComponent } from 'src/pages/one-ro-reports-page/one-ro-reports-page.component';
import { TableNormativeActsComponent } from 'src/pages/table-normative-acts-page/table-normative-acts.component';
import { RoReportsPageComponent } from 'src/pages/ro-reports-page/ro-reports-page.component';

export const roleGovermentRoutes: Routes = [
    {
        path: '',
        redirectTo: PAGES.map,
        pathMatch: 'full'
    },
    {
        path: PAGES.ro,
        component: CommonPageComponent,
        children: [
            {
                path: '',
                component: RoListPageComponent,
            },
            {
                path: ':id',
                component: LayoutRoComponent,
                children: [
                    {
                        path: RO_PAGES.details,
                        component: RoDetailsPageComponent,
                        data: {
                            smallSize: true,
                        }
                    },
                    {
                        path: RO_PAGES.balance,
                        component: RoBalancePageComponent,
                        data: {
                            smallSize: true
                        }
                    },
                    {
                        path: RO_PAGES.facilities,
                        component: FacilitiesPageComponent,
                        data: {
                            smallSize: true,
                            hideOrganization: true
                        }
                    },
                    // {
                    //     path: RO_PAGES.reports,
                    //     component: OuivReportsPageComponent,
                    //     data: {
                    //         smallSize: true
                    //     }
                    // },
                    {
                        path: '',
                        redirectTo: RO_PAGES.details,
                        pathMatch: 'full'
                    },
                    {
                        path: '**',
                        redirectTo: RO_PAGES.details
                    }
                ]
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
        data: {
            allowed_roles: [ROLE.government, ROLE.executive],
        }
    },
    {
        path: PAGES.balance,
        component: CommonPageComponent,
        children: [
            {
                path: '',
                component: BalancePageComponent
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
        data: {
            allowed_roles: [ROLE.government, ROLE.executive],
        }
    },
    {
        path: PAGES.facilities,
        component: CommonPageComponent,
        children: [
            {
                path: '',
                component: FacilitiesPageComponent
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
        data: {
            allowed_roles: [ROLE.government, ROLE.executive],
        }
    },
    {
        path: PAGES.map,
        component: MapPageComponent,
        children: [
            {
                path: '',
                component: SidebarPageComponent,
                children: [
                    {
                        path: '',
                        component: BalanceExcerptPageComponent
                    },
                ]
            }
        ],
        data: {
            allowed_roles: [ROLE.government, ROLE.executive],
        }
    },
    {
        path: PAGES.reports,
        component: CommonPageComponent,
        children: [
            {
                path: '',
                component: OuivReportsPageComponent
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
    },
    {
        path: PAGES.dashboard,
        component: CommonPageComponent,
        children: [
            {
                path: '',
                component: DashboardPageComponent
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
    },
    {
        path: PAGES.strategy,
        component: CommonPageComponent,
        children: [
            {
                path: '',
                component: StrategyPageComponent
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
    },
    {
        path: PAGES.normative,
        component: CommonPageComponent,
        children: [
            {
                path: '',
                component: TableNormativeActsComponent
            },
            {
                path: '**',
                redirectTo: ''
            }
        ],
    }
];
