<ng-template #defaultTemplate let-row="row" let-columns="cols">
    <td *ngFor="let column of columns">
        {{ row[column.key] | dynPipe: column?.format }}
    </td>
</ng-template>

<table class="{{tableClass || ''}}">
    <thead>
    <tr class="{{trHeaderClass}}">
        <th *ngFor="let column of sorter.columns">
            <ct-sort-list-item
                *ngIf="column.sort; else notSortable"
                [text]="column.title"
                [sortingDirection]="column.direction"
                [align]="column?.align || ''"
                (click)="sorter.sortBy(column)"
            ></ct-sort-list-item>
            <ng-template #notSortable>
                {{column.title}}
            </ng-template>
        </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let row of rows | sorting : sorter.getPredicate() : -sorter.selectedColumn.direction; trackBy: sorter.trackById"
        class="{{trClass}}"
    >
        <ng-container
            *ngTemplateOutlet="template ? template : defaultTemplate; context: { row: row, cols: columns }"
        ></ng-container>
    </tr>
    </tbody>
</table>
