<div class="balance-value">
    <div class="balance-value-text balance-value-text--{{size}}"
        [class.balance-value-text--balance]="isBalance"
        [class.balance-value-text--danger]="isBalance && value > 0"
    >
        {{value | number : '1.0-0'}}
    </div>
    <div class="balance-value-units balance-value-units--{{size}}">
        {{value | i18nPlural : TEXT.UNITS.ton}}
    </div>
</div>
