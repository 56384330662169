import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { FacilityEmission, Facility } from '../models';

@Injectable({ providedIn: 'root' })
export class FacilityEmissionService<T extends number | Facility> extends EntityCollectionServiceBase<FacilityEmission<T>> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('FacilityEmission', serviceElementsFactory);
    }
}
