<div class="facilities-table">
    <table class="table facilities-table__table">
        <thead>
        <tr class="table__header">
            <th *ngFor="let column of columns"
                class="{{column.props.alignment || 'left'}}"
            >
                <ct-sort-list-item *ngIf="column.predicate; else titleOnly"
                    [text]="column.props.title"
                    [sortingDirection]="column.direction"
                    [align]="column.props.alignment"
                    (click)="sortBy(column, columns)"
                ></ct-sort-list-item>

                <ng-template #titleOnly>{{column.props.title}}</ng-template>
            </th>
        </tr>
        </thead>

        <tbody>
        <tr class="facilities-table__table-cap facilities-table__table-cap--top"
            *ngIf="facilities.length !== 0"
        >
            <td colspan="100%"></td>
        </tr>
        <tr class="table__content_tr"
            *ngFor="let facility of facilities | sorting : sortingColumn.predicate : -sortingColumn.direction; trackBy: trackById"
        >
            <td class="table__font_weight_semi_bold table__font_color_gray">
                {{facility.id}}
            </td>
            <td class="table__font_weight_semi_bold">{{facility.name}}</td>
            <td class="table__font_weight_semi_bold">{{facility.address}}</td>
            <td *ngIf="!hideOrganization"
                class="table__font_weight_semi_bold table_link"
                [routerLink]="'/' + EXECUTIVE_PAGES.ro + '/' + facility.organization?.id"
            >
                {{facility.organization?.name || ''}}
            </td>
            <td class="center">
                <div class="facilities-table__cell-icons">
                    <organization-type-icon [slug]="facility.sector?.slug"></organization-type-icon>
                </div>
            </td>
            <td class="table__font_weight_semi_bold table__number">
                {{facility?.emission | emission}}
            </td>
            <td class="center nowrap"
                caTooltip
                caTooltipPlacement="top"
                [caTooltipTemplate]="dummyButton"
            >
                <div class="source_colors source_colors-3">X</div>
            </td>
        </tr>
        </tbody>
    </table>

    <ct-search-result-placeholder *ngIf="facilities.length === 0"></ct-search-result-placeholder>

    <div class="facilities-table__summary">
        <span class="facilities-table__summary-value">{{facilities.length}}</span>
        <span class="facilities-table__summary-units">{{facilities.length | i18nPlural: TEXT.FACILITIES}}</span>
    </div>

    <div class="regulated-organizations__filler"></div>

    <ng-template #dummyButton>
        <ca-tooltip [text]="TEXT.uoivBalance.dummyButton"></ca-tooltip>
    </ng-template>
</div>
