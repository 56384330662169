<div [ngClass]="{
        'ro-details-page': true,
        'ro-details-page-small': route.snapshot.data?.smallSize
    }">
    <div class="ro-details-page__content">
        <div class="ro-details-page__header">
            <div class="ro-details-page__header-title">
                {{TEXT.RO_DETAILS_PAGE.title}}
            </div>
            <div class="ro-details-page__header-edit" *ngIf="canEdit">
                <ct-edit-button
                    caTooltip
                    caTooltipPlacement="top"
                    [caTooltipTemplate]="dummyButton"
                >
                    {{TEXT.uoivBalance.edit}}
                </ct-edit-button>
            </div>
        </div>

        <div class="ro-details-page__column">
            <balance-widget>
                <div class="ro-details-page__widget ro-details-page__org">
                    <div class="ro-details-page__widget-header-icon"></div>
                    <div class="ro-details-page__widget-content" *ngIf="details$ | async as details">
                        <div class="ro-details-page__widget-header ro-details-page__org-header">
                            <div class="ro-details-page__widget-column">
                                <div class="ro-details-page__org-item">
                                    <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.organizationName}}</div>
                                    <div class="ro-details-page__text">{{details.name}}</div>
                                </div>
                            </div>
                            <div class="ro-details-page__widget-column">
                                <div class="ro-details-page__org-sources-summary">
                                    <div class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.sources}}</div>
                                        <div class="ro-details-page__text">{{details.facilities.length}}</div>
                                    </div>
                                    <div
                                            caTooltip
                                            caTooltipPlacement="top"
                                            [caTooltipTemplate]="dummyButton"
                                            class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.carbonCapacity}}</div>
                                        <div class="ro-details-page__org-sources-capacity">
                                            <div class="ro-details-page__text ro-details-page__org-capacity"
                                                *ngIf="capacityPlaceholder"
                                            >
                                                <div class="ro-details-page__org-capacity-available">
                                                    {{capacityPlaceholder}}
                                                </div>
                                                {{(details.facilities.length - capacityPlaceholder)}}
                                            </div>
                                            <carbon-capacity-bar
                                                [available]="capacityPlaceholder"
                                                [used]="details.facilities.length - capacityPlaceholder"
                                            ></carbon-capacity-bar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ro-details-page__org-content">
                            <div class="ro-details-page__widget-row">
                                <div class="ro-details-page__widget-column">
                                    <div class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.legalAddress}}</div>
                                        <div class="ro-details-page__text">{{details.legal_address}}</div>
                                    </div>
                                </div>
                                <div class="ro-details-page__widget-column">
                                    <div class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.postalAddress}}</div>
                                        <div class="ro-details-page__text">{{details.postal_address}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ro-details-page__widget-row">
                                <div class="ro-details-page__widget-column">
                                    <div class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.inn}}</div>
                                        <div class="ro-details-page__text">{{details.inn}}</div>
                                    </div>
                                </div>
                                <div class="ro-details-page__widget-column">
                                    <div class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.ogrn}}</div>
                                        <div class="ro-details-page__text">{{details.ogrn}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ro-details-page__widget-row">
                                <div class="ro-details-page__widget-column">
                                    <div class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.phoneNumber}}</div>
                                        <div class="ro-details-page__text">{{details.phone_number}}</div>
                                    </div>
                                </div>
                                <div class="ro-details-page__widget-column">
                                    <div class="ro-details-page__org-item">
                                        <div class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.details.email}}</div>
                                        <div class="ro-details-page__text">
                                            <a class="common-link" href="mailto:{{details.email}}">{{details.email}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </balance-widget>

            <balance-widget>
                <div class="ro-details-page__widget ro-details-page__contacts">
                    <div class="ro-details-page__widget-header-icon"></div>
                    <div class="ro-details-page__widget-content">
                        <div class="ro-details-page__widget-header ro-details-page__contacts-header">
                            <div class="ro-details-page__contacts-title">{{TEXT.RO_DETAILS_PAGE.contactsTitle}}</div>
                        </div>
                        <div class="ro-details-page__contacts-content">
                            <table class="ro-details-page__contacts-table" [cellSpacing]="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.contacts.fullName}}</th>
                                        <th class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.contacts.position}}</th>
                                        <th class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.contacts.phoneNumber}}</th>
                                        <th class="ro-details-page__subtitle">{{TEXT.RO_DETAILS_PAGE.contacts.email}}</th>
                                    </tr>
                                </thead>
                                <tbody class="ro-details-page__text">
                                    <tr *ngFor="let c of contacts$ | async">
                                        <td>
                                            <img class="ro-details-page__contacts-table-avatar"
                                                [ngxLetterImageAvatar]="{ name: abbrName(c.full_name) }"
                                                alt="avatar"
                                            />
                                        </td>
                                        <td>{{c.full_name}}</td>
                                        <td>{{c.position}}</td>
                                        <td>{{c.phone_number}}</td>
                                        <td>
                                            <a class="common-link" href="mailto:{{c.email}}">{{c.email}}</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </balance-widget>
        </div>
    </div>
    <ng-template #dummyButton>
        <ca-tooltip [text]="TEXT.uoivBalance.dummyButton"></ca-tooltip>
    </ng-template>
</div>
