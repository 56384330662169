import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CheckboxItem } from 'src/namespace';

@Component({
    selector: 'ca-selectbox-radio',
    templateUrl: 'selectbox-radio.component.html',
    styleUrls: ['selectbox-radio.component.less']
})
export class SelectboxRadioComponent implements OnInit, OnDestroy {
    @Input() listItems: CheckboxItem[];

    @Output() listItemsChange = new EventEmitter<CheckboxItem[]>();

    form: FormGroup;

    onDestroy$ = new Subject<void>();

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.form = this.fb.group({
            selectedItem: [
                this.listItems.find(item => item.selected)?.id
            ]
        });

        this.form.controls.selectedItem.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.listItems.forEach(item => {
                    item.selected = item.id === value;
                });

                this.listItemsChange.emit(this.listItems);
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
