<div class="wrapper">

    <div class="close" (click)="facade.setCurrentPopup(null)"> </div>


    <div class="title">{{TEXT.form.title}}</div>

    <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
        <ct-input-dropdown
            [inputText]="getSelectText()"
            [label]="TEXT.form.object"
            [textError]="facilityError"
            [(showDropdown)]="showSelector"
            (clickOutside)="showSelector = false"
        >
            <ca-selectbox-radio
                [(listItems)]="list"
                (listItemsChange)="select()"
            ></ca-selectbox-radio>
        </ct-input-dropdown>

        <ct-input
                [form]="form"
                [controlName]="'emission'"
                [label]="TEXT.form.emission"
                [type]="'number'"
                [valueForm]="form.controls.emission.value"
                [textError]="(form.controls.emission.invalid && form.controls.emission.touched ? TEXT.form.errorEmission : '') || emissionError"
                [prompt]="TEXT.others.tg"
        ></ct-input>

        <ca-button
                class="submit"
                [type]="'primary'"
                (clickAction)="onSubmit()"
                [disabled]="form.invalid"
        >
            <ca-spinner *ngIf="isLoading; else showContent"></ca-spinner>
            <ng-template #showContent>{{ TEXT.form.send }}</ng-template>
        </ca-button>
    </form>
</div>