import { ICoreState } from './reducers';
import { createSelector } from '@ngrx/store';

export interface AppState {
    core: ICoreState;
}

export const coreSelector = (state: AppState) => state.core;

export const selectIsServerError = createSelector(
    coreSelector,
    (state: ICoreState) => state.isServerError,
)


// export const selectFadaster = createSelector(
//     coreSelector,
//     (state: ICoreState) => state.collections.fadaster,
// )
//
// export const selectChartData = createSelector(
//     coreSelector,
//     (state: ICoreState, props) => state.collections.fadaster.map(
//         (f: Fadaster) => {return {x: f.year, y: f[props.field] / 1000}}
//     )
// )

