import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';

import { DirectivesModule } from 'src/directives/directives.module';
import { PipesModule } from 'src/pipes/pipes.module';

import { SpinnerModule } from 'src/components/spinner/spinner.module';
import { TextButtonModule } from 'src/components/text-button/text-button.module';
import { SelectboxRadioModule } from 'src/components/selectbox-radio/selectbox-radio.module';
import { ButtonModule } from 'src/components/button/button.module';
import { CrossButtonModule } from 'src/components/cross-button/cross-button.module';
import { TooltipsModule } from 'src/components/tooltips/tooltips.module';
import { SortListItemModule } from 'src/components/sort-list-item/sort-list-item.module';
import { KebabMenuModule } from 'src/components/kebab-menu/kebab-menu.module';
import { InputModule } from 'src/components/input/input.module';
import { InputDropdownModule } from 'src/components/input-dropdown/input-dropdown.module';
import { LangSelectorModule } from 'src/components/lang-selector/lang-selector.module';
import { PeriodStatusBadgeModule } from 'src/components/period-status-badge/period-status-badge.module';
import { ChevronModule } from 'src/components/chevron/chevron.module';
import { SearchResultPlaceholderModule } from 'src/components/search-result-placeholder/search-result-placeholder.module';

import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { LogoutMenuComponent } from './components/logout-menu/logout-menu.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MobileContainerComponent } from './containers/mobile-container/mobile-container.component';
import { HeaderComponent } from './components/header/header.component';
import { BalanceValueComponent } from './components/balance-value/balance-value.component';
import { AnnualTimelineComponent } from './components/annual-timeline/annual-timeline.component';
import { OrganizationTypeIconComponent } from './components/organization-type-icon/organization-type-icon.component';
import { GradientTextComponent } from './components/gradient-text/gradient-text.component';
import { EditButtonComponent } from './components/edit-button/edit-button.component';
import { RoHeaderComponent } from './components/ro-header/ro-header.component';
import { TableCoordComponent } from './components/table/components/table-coord/table-coord.component';
import { CommentTooltipComponent } from './components/comment-tooltip/comment-tooltip.component';
import { StatusTagsComponent } from './components/status-tags/status-tags.component';
import { TableManagerComponent } from './components/table/components/table-manager/table-manager.component';
import { RoFacilityReportsContainer } from './components/table/ro-facility-reports/ro-facility-reports.component';
import { FilterBlockComponent } from './components/table/components/filter-block/filter-block.component';
import { TableWidgetComponent } from './components/table/components/table-header/table-widget.component';
import { CreateReportComponent } from './components/reports/create-report/create-report.component';
import { EditReportComponent } from './components/reports/edit-report/edit-report.component';
import { BalanceWidgetComponent } from './components/balance-widget/balance-widget.component';
import { BalanceChartComponent } from './components/balance-chart/balance-chart.component';
import { BalanceSummaryTableComponent } from './components/balance-summary-table/balance-summary-table.component';
import { BalanceChartRunnerComponent } from './components/balance-chart-runner/balance-chart-runner.component';
import { BalanceChartPointComponent } from './components/balance-chart-point/balance-chart-point.component';
import { BalanceChartTooltipComponent } from './components/balance-chart-tooltip/balance-chart-tooltip.component';
import { CoordCreateReportComponent } from './components/reports/coord-create-report/coord-create-report.component';
import { CoordContainerComponent } from './components/table/coord-container/coord-container.component';
import { MessageReportSentWrapperComponent } from './components/table/components/message-report-sent-wrapper/message-report-sent-wrapper.component';
import { MessageReportSentSuccessfullyComponent } from './components/table/components/message-report-sent-successfully/message-report-sent-successfully.component';
import { MessageReportSentErrorComponent } from './components/table/components/message-report-sent-error/message-report-sent-error.component';
import { TableRegulatedOrganizationsComponent } from './components/table-regulated-organizations/table-regulated-organizations.component';
import { FacilitiesTableComponent } from './components/facilities-table/facilities-table.component';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { SelectboxOrganizationTypeComponent } from './components/selectbox-organization-type/selectbox-organization-type.component';
import { SelectboxYearComponent } from './components/selectbox-year/selectbox-year.component';
import { ButtonAddItemComponent } from './components/button-add-item/button-add-item.component';
import { CarbonCapacityBarComponent } from './components/carbon-capacity-bar/carbon-capacity-bar.component';
import { Balance65LogoComponent } from './components/balance-65-logo/balance-65-logo.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { DashboardChartBigComponent } from './components/dashboard-chart-big/dashboard-chart-big.component';
import { DashboardWidgetComponent } from './components/dashboard-widget/dashboard-widget.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SelectboxStatusComponent } from './components/selectbox-status/selectbox-status.component';
import { ReportsStatusComponent } from './components/reports-status/reports-status.component';
import { ErrorPopupComponent } from './components/error-popup/error-popup.component';

@NgModule({
    imports: [
        CommonModule,
        CrossButtonModule,
        DirectivesModule,
        FormsModule,
        PipesModule,
        RouterModule,
        TooltipsModule,
        KebabMenuModule,
        ReactiveFormsModule,
        ChartsModule,
        CrossButtonModule,
        SpinnerModule,
        TextButtonModule,
        SelectboxRadioModule,
        ButtonModule,
        SortListItemModule,
        ChevronModule,
        InputModule,
        InputDropdownModule,
        LangSelectorModule,
        PeriodStatusBadgeModule,
        SearchResultPlaceholderModule,
    ],
    declarations: [
        HeaderComponent,
        UserAvatarComponent,
        LogoutButtonComponent,
        LogoutMenuComponent,
        LogoutComponent,
        MobileContainerComponent,
        BalanceValueComponent,
        AnnualTimelineComponent,
        OrganizationTypeIconComponent,
        GradientTextComponent,
        EditButtonComponent,
        RoHeaderComponent,
        TableCoordComponent,
        TableManagerComponent,
        RoFacilityReportsContainer,
        CommentTooltipComponent,
        StatusTagsComponent,
        FilterBlockComponent,
        TableWidgetComponent,
        CreateReportComponent,
        EditReportComponent,
        BalanceWidgetComponent,
        BalanceChartComponent,
        BalanceChartRunnerComponent,
        BalanceChartPointComponent,
        BalanceChartTooltipComponent,
        BalanceSummaryTableComponent,
        CoordCreateReportComponent,
        CoordContainerComponent,
        MessageReportSentWrapperComponent,
        MessageReportSentSuccessfullyComponent,
        MessageReportSentErrorComponent,
        TableRegulatedOrganizationsComponent,
        FacilitiesTableComponent,
        DropdownMenuComponent,
        SearchbarComponent,
        SelectboxOrganizationTypeComponent,
        SelectboxYearComponent,
        ButtonAddItemComponent,
        CarbonCapacityBarComponent,
        Balance65LogoComponent,
        DashboardChartComponent,
        DashboardChartBigComponent,
        DashboardWidgetComponent,
        LoaderComponent,
        SelectboxStatusComponent,
        ReportsStatusComponent,
        ErrorPopupComponent,
    ],
    exports: [
        LogoutComponent,
        HeaderComponent,
        BalanceValueComponent,
        AnnualTimelineComponent,
        OrganizationTypeIconComponent,
        GradientTextComponent,
        EditButtonComponent,
        RoHeaderComponent,
        TableCoordComponent,
        TableManagerComponent,
        CommentTooltipComponent,
        StatusTagsComponent,
        RoFacilityReportsContainer,
        FilterBlockComponent,
        TableWidgetComponent,
        CreateReportComponent,
        EditReportComponent,
        BalanceWidgetComponent,
        BalanceChartComponent,
        BalanceSummaryTableComponent,
        CoordCreateReportComponent,
        CoordContainerComponent,
        MessageReportSentWrapperComponent,
        MessageReportSentSuccessfullyComponent,
        MessageReportSentErrorComponent,
        TableRegulatedOrganizationsComponent,
        FacilitiesTableComponent,
        DropdownMenuComponent,
        SearchbarComponent,
        SelectboxOrganizationTypeComponent,
        SelectboxYearComponent,
        ButtonAddItemComponent,
        CarbonCapacityBarComponent,
        Balance65LogoComponent,
        DashboardChartComponent,
        DashboardChartBigComponent,
        DashboardWidgetComponent,
        LoaderComponent,
        ErrorPopupComponent,
    ]
})
export class CoreModule {}
