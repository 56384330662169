<table *ngIf="this.table?.length !== 0 && tableData" class="table" cellSpacing="0" cellPadding="0">
    <thead>
    <tr class="table__header">
        <th *ngFor="let h of header">
            <ct-sort-list-item *ngIf="h.sort !== undefined; else notSortable"
                [text]="h.name"
                [sortingDirection]="h.sort"
                [align]="h.props?.alignment"
                (click)="sortClick(h)"
            ></ct-sort-list-item>

            <ng-template #notSortable>
                {{h.name}}
            </ng-template>
        </th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of table" class="table__content_tr">
        <td class="table__font_weight_semi_bold table__font_color_gray">{{t.id || '-'}}</td>
        <td class="table__font_weight_semi_bold center">
            <organization-type-icon [slug]="sectorsMap[t.facility.sector]"></organization-type-icon>
        </td>
        <td class="table__font_weight_semi_bold">{{t.facility?.name}}</td>
        <td class="table__font_weight_semi_bold">{{t.facility?.address}}</td>
        <td class="table__font_weight_bold table__number right">{{t.emission | emission}}</td>
        <td>{{formatDayMonth2(t.sent_at)}}</td>
        <td>{{formatDayMonth2(t.approved_at)}}</td>
        <td>{{t.sender?.email}}</td>
        <td>
            <div *ngIf="t.comment" class="table__comment"
                caTooltip
                [caTooltipTemplate]="commentTooltip"
                [caTooltipPlacement]="'top'"
                [caTooltipFollowPointer]="false"
            ></div>

            <ng-template #commentTooltip>
                <ct-comment-tooltip [text]="t.comment"></ct-comment-tooltip>
            </ng-template>
        </td>
        <td class="center">
            <status-tags *ngIf="t.status !== statusName.empty"
                [status]="t.status"
            ></status-tags>

            <ca-text-button
                *ngIf="t.status === statusName.empty && t.year === currentYear"
                size="medium"
                [text]="TEXT.table.sendReport"
                (clickAction)="create.emit(t.facility.id)"
            ></ca-text-button>
        </td>

        <td class="functional_column">
            <kebab-menu *ngIf="t.status === statusName.awaiting" [positionOpenedMenu]="'left'">
                <div class="table__btn_revoke" (click)="revoke.emit(t.id)">{{TEXT.others.revoke}}</div>
            </kebab-menu>

            <kebab-menu *ngIf="t.status === statusName.rework" [positionOpenedMenu]="'left'">
                <div class="table__btn_revoke" (click)="edit.emit(t.id)">{{TEXT.others.edit}}</div>
            </kebab-menu>
        </td>
    </tr>
    </tbody>
</table>

<ct-search-result-placeholder *ngIf="table?.length === 0"></ct-search-result-placeholder>
