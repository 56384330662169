import { ChartDataSets } from 'chart.js';
import { BALANCE_CHART_TYPE } from './balance-chart.settings';

export const LineStyles: {
    [key: string]: ChartDataSets;
} = {
    [BALANCE_CHART_TYPE.TOTAL_SEQUESTRATION]: {
        borderColor: '#1AB674',
        borderWidth: 3,
        pointBackgroundColor: '#69E298',
        pointHoverBackgroundColor: '#69E298',
        pointBorderWidth: 0,
        pointRadius: 7,
        pointHoverRadius: 7,
        pointBorderColor: 'transparent',
        pointHoverBorderColor: 'transparent',
    },
    [BALANCE_CHART_TYPE.TOTAL_EMISSIONS]: {
        borderColor: '#E7417C',
        borderWidth: 3,
        pointBackgroundColor: '#E63E8E',
        pointHoverBackgroundColor: '#E63E8E',
        pointBorderWidth: 0,
        pointRadius: 7,
        pointHoverRadius: 7,
        pointBorderColor: 'transparent',
        pointHoverBorderColor: 'transparent',
    },
    [BALANCE_CHART_TYPE.NO_ACTION_SEQUESTRATION]: {
        borderColor: '#1AB674',
        borderDash: [9, 7],
        pointRadius: 0,
        pointHoverRadius: 0,
    },
    [BALANCE_CHART_TYPE.NO_ACTION_EMISSION]: {
        borderColor: '#E63E8E',
        borderDash: [2, 6],
        pointRadius: 0,
        pointHoverRadius: 0,
    },
};
