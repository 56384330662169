import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PAGES } from './navigation/navigation-config';
import { AuthGuard } from './modules/core/services/route-guards/auth.guard';
import { PermissionsGuard } from './modules/core/services/route-guards/permissions.guard';
import { MobileGuard } from './modules/core/services/route-guards/mobile.guard';
import { LoginComponent } from './modules/core/containers/login/login.component';
import { MobileContainerComponent } from './modules/core/containers/mobile-container/mobile-container.component';

export const carbonTrailRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [MobileGuard, AuthGuard, PermissionsGuard],
    },
    {
        path: PAGES.login,
        component: LoginComponent,
        canActivate: [MobileGuard, AuthGuard],
    },
    {
        path: PAGES.mobile,
        component: MobileContainerComponent,
        canActivate: [MobileGuard],
    },
    {
        path: '**',
        redirectTo: ''
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(carbonTrailRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class CarbonTrailRoutingModule {}
