import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TEXT } from 'src/locales/text';
import { FacilityEmission, Facility } from 'src/store/models';
import { PAGES } from 'src/navigation/navigation-config';

@Component({
    selector: 'popup-facility',
    templateUrl: 'popup-facility.component.html',
    styleUrls: ['popup-facility.component.less'],
})
export class PopupFacilityComponent {
    @Input() report: FacilityEmission<Facility>;

    @Output() closeAction = new EventEmitter<void>();

    TEXT = TEXT;

    PAGES = PAGES;
}
