<div class="facilities-page">
    <div class="facilities-page__header">
        <h1 class="facilities-page__title">{{TEXT.PAGE_TITLES.facilities}}</h1>
        <div class="facilities-page__filters">
            <searchbar
                class="facilities-page__filters-searchbar"
                [placeholder]="TEXT.others.search"
                (textChange)="search($event)"
            ></searchbar>

            <selectbox-year
                class="facilities-page__filters-dropdown"
                [list]="listYears"
                (filterByItem)="filterByYear($event)"
            ></selectbox-year>

            <selectbox-organization-type
                class="facilities-page__filters-dropdown"
                (filterByItem)="filterByOrgType($event)"
            ></selectbox-organization-type>
        </div>
    </div>

    <div class="facilities-page__table scroll scroll-transparent"
        [class.facilities-page__table-small]="route.snapshot.data?.smallSize"
    >
        <facilities-table
            [facilities]="joinedFacilities$ | async"
            [hideOrganization]="route.snapshot.data?.hideOrganization"
        ></facilities-table>
    </div>
</div>
