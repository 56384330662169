<div class="searchbar" [formGroup]="form">
    <input class="searchbar__input ellipsis"
        formControlName="textInput"
        [attr.placeholder]="placeholder"
        (focus)="onFocus()"
        (blur)="onBlur()"
    />
    <div class="searchbar__loupe" (click)="clear()"></div>
    <div [ngClass]="{
        'searchbar__clear': true,
        'searchbar__clear--active': text
    }" (click)="clear()"></div>
</div>
