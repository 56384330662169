import { Component, Input } from '@angular/core';

@Component({
    selector: 'balance-chart-tooltip',
    templateUrl: 'balance-chart-tooltip.component.html',
    styleUrls: ['balance-chart-tooltip.component.less'],
})
export class BalanceChartTooltipComponent {
    @Input() color: string;
}
