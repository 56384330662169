import {
    FacilityEmission,
    Facility,
    Sector
} from 'src/store/models';
import { FacilityYearlyViewModel } from 'src/store/models';

export type ReportPageFilter = {year: string; text: string}

export class ReportsViewModel {
    data: FacilityYearlyViewModel[];

    public constructor(
        filters: ReportPageFilter,
        fs: Facility[],
        ems: FacilityEmission<number>[],
        sectors: Sector[],
    ) {
        this.data = fs.map(f => {
            // using filter year values to find facility emission object by facility_id and year from filter
            const facilityEmission = ems.find(e => e.facility == f.id && filters.year == e.year.toString());
            return {
                ...f,
                emission: facilityEmission?.emission,
                status: facilityEmission?.status,
                sector: sectors.find(s => f.sector == s.id),
                sent_at: facilityEmission?.sent_at,
                approved_at: facilityEmission?.approved_at,
                comment: facilityEmission?.comment,
                sender: facilityEmission?.sender,
                email: facilityEmission?.sender.email,
                year: facilityEmission?.year,
            } as FacilityYearlyViewModel;
        }).filter(
                m =>  FacilityYearlyViewModel.filter(m, filters)
        );
    }
}
