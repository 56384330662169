import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Sector } from '../models';

@Injectable({ providedIn: 'root' })
export class SectorService extends EntityCollectionServiceBase<Sector> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Sector', serviceElementsFactory);
    }
}
