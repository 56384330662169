<div class="dashboard_chart">
    <div class="dashboard_chart__title" [innerHTML]="title"></div>

    <div class="dashboard_chart__bottom">

        <div class="dashboard_chart__canvas">
            <canvas baseChart
                    [height]="'210px'"
                    [width]="'210px'"
                    [options]="options"
                    [data]="doughnutChartData"
                    [labels]="doughnutChartLabels"
                    [chartType]="doughnutChartType"
                    [colors]="color"
            ></canvas>
        </div>

        <reports-status class="dashboard_chart__statuses"
                [arrOfStatuses]="TEXT.statusCoord"
                [awaitingSum]="awaitingSum"
                [emptySum]="emptySum"
                [approvedSum]="approvedSum"
                [reworkSum]="reworkSum"
                [isVertical]="true"
        ></reports-status>
    </div>
</div>