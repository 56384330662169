<div class="regulated-organizations">
    <table class="table regulated-organizations__table">
        <thead>
        <tr class="table__header">
            <th *ngFor="let column of sortingColumns; index as i"
                class="{{column.props.alignment || 'left'}}"
            >
                <ct-sort-list-item *ngIf="column.predicate; else titleOnly"
                    [text]="column.props.title"
                    [sortingDirection]="column.direction"
                    [align]="column.props.alignment"
                    (click)="sortBy(column, sortingColumns)"
                ></ct-sort-list-item>

                <ng-template #titleOnly>{{column.props.title}}</ng-template>
            </th>
        </tr>
        </thead>

        <tbody>
        <tr *ngIf="data.length !== 0" class="regulated-organizations__table-cap regulated-organizations__table-cap--top">
            <td colspan="100%"></td>
        </tr>
        <tr *ngFor="let ro of data | sorting : sortingColumn.predicate : -sortingColumn.direction; trackBy: trackById"
            class="table__content_tr"
            [routerLink]="ro.id"
        >
            <td class="table__font_weight_semi_bold table__font_color_gray">
                <ca-text-button size="medium" [text]="ro.id"></ca-text-button>
            </td>
            <td class="table__font_weight_semi_bold">{{ro.name}}</td>
            <td>
                <div class="regulated-organizations__cell-icons">
                    <organization-type-icon [slug]="manager.getById(s_id)?.slug" *ngFor="let s_id of ro.sectors"></organization-type-icon>
                </div>
            </td>
            <td class="table__font_weight_semi_bold table__number">
                {{ro.emissions | emission}}
            </td>
            <td class="table__font_weight_semi_bold table__number">
                {{ro.quota | emission}}
            </td>
            <td class="table__font_weight_semi_bold table__number">
                {{ro.balance | emission}}
            </td>
            <td class="table__font_weight_semi_bold table__number"
                [class.regulated-organizations__carbon-balance--good]="calcBalance(ro) < 0"
            >
                {{calcBalance(ro) | emission}}
            </td>
            <td class="center nowrap"
                caTooltip
                caTooltipPlacement="top"
                [caTooltipTemplate]="dummyButton"
            >
                <div class="source_colors source_colors-1">X</div>
                <div class="source_colors source_colors-2">X</div>
                <div class="source_colors source_colors-3">X</div>
            </td>
        </tr>
        </tbody>
    </table>

    <ct-search-result-placeholder *ngIf="data.length === 0"></ct-search-result-placeholder>

    <div class="regulated-organizations__summary">
        <span class="regulated-organizations__summary-value">{{data.length}}</span>
        <span class="regulated-organizations__summary-units">{{data.length | i18nPlural: TEXT.ORGANIZATIONS}}</span>
    </div>

    <div class="regulated-organizations__filler"></div>

    <ng-template #dummyButton>
        <ca-tooltip [text]="TEXT.uoivBalance.dummyButton"></ca-tooltip>
    </ng-template>
</div>
