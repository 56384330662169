import { Component, Input, OnInit } from '@angular/core';
import { TEXT } from 'src/locales/text';
import { STATUS_NAMES } from 'src/namespace';

@Component({
    selector: 'carbon-trail-table-widget',
    templateUrl: './table-widget.component.html',
    styleUrls: ['./table-widget.component.less']
})
export class TableWidgetComponent implements OnInit {
    @Input() arrOfStatuses: { [key in STATUS_NAMES]: string };
    @Input() awaitingSum: number;
    @Input() emptySum: number;
    @Input() approvedSum: number;
    @Input() reworkSum: number;
    @Input() emissionSum: string;
    @Input() tableLength: number;
    @Input() lastYear: number;

    TEXT = TEXT;

    constructor() {
    }

    ngOnInit(): void {
    }

}
