import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor
} from '@angular/common/http';
import { throwError } from 'rxjs';
import {
    catchError,
} from 'rxjs/operators';

import { AuthService } from 'src/modules/core/services/auth.service';
import { onErrorCaught } from 'src/store/actions';
import { Store } from '@ngrx/store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError(err => {
                if (err.status !== 401 && err.status !== 400) {
                    this.store.dispatch(onErrorCaught());
                }

                return throwError(err);
            })
        );
    }
}
