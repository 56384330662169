import { Injectable } from '@angular/core';

import { POPUPS, STATUS_NAMES } from 'src/namespace';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth.service';
import { CollectionService } from '../collection/collection.service';
import { REPORT_YEAR } from 'src/config';

class State {
    currentYear = REPORT_YEAR;
    currentPopup: POPUPS;

    selectedReport: number;
    selectedFacility: number;

    successNotify = {
        isShow: false,
        status: <STATUS_NAMES>null,
        name: '',
        timeOut: <any>null,
    };
    errorNotify = {
        isShow: false,
        name: '',
        timeOut: <any>null,
    };

    showLoader = true;
}

@Injectable()
export class ReportsFacadeService {
    private state = new State();

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private collection: CollectionService
    ) {
        collection.onUpdateReports$.subscribe((data) => {
            this.state.showLoader = !data.length;
        });
    }

    getCurrentYear() {
        return this.state.currentYear;
    }

    getFacilities() {
        this.apiService.getFacilities();
    }

    showLoader = () => this.state.showLoader = true;

    getShowLoader = () => this.state.showLoader;

    mainStart() {
        if (!this.authService.isLoggedIn()) {
            return;
        }
        this.loadReports();
    }

    clearLocalData() {
        this.authService.removeRefreshToken();
        this.authService.removeAccessToken();
    }

    logOut = () => {
        this.clearLocalData();
        this.authService.logout();
        location.reload();
    };

    loadReports() {
        return this.apiService.getReports();
    }

    getCurrentPopup = () => this.state.currentPopup;

    setCurrentPopup(p: POPUPS) {
        this.state.currentPopup = p;
    }

    setSelectedReport = (id: number) => (this.state.selectedReport = id);

    setSelectedFacility = (id: number) => (this.state.selectedFacility = id);

    getSelectedReport = () => this.state.selectedReport;

    getSelectedFacility = () => this.state.selectedFacility;

    goToCreateReport = (id?: number) => {
        this.setSelectedFacility(id || null);

        this.setCurrentPopup(POPUPS.createReport);
    };

    goToEditReport = (reportId: number) => {
        this.setSelectedReport(reportId);

        this.setCurrentPopup(POPUPS.editReport);
    };

    goToApproveReport = (reportId: number) => {
        this.setSelectedReport(reportId);

        this.setCurrentPopup(POPUPS.approveReport);
    };

    createReport = async (props: { facility: number; emission: number }) => {
        let data: any;

        try {
            data = await this.apiService.createReport(props);
        } catch (e) {
            this.showErrorNotify();
            return {};
        }

        if (data.isOk) {
            this.setCurrentPopup(null);
            await this.loadReports();
            const report = this.collection.getReportForFacilityId(props.facility);
            this.showSuccessNotify(report.status, report.facility.name);
        }

        return data;
    };

    updateReport = async (props: { status?: STATUS_NAMES; comment?: string; emission?: number }) => {
        let data: any;

        try {
            data = await this.apiService.updateReport(this.getSelectedReport(), props);
        } catch (e) {
            const report = this.collection.getReport(this.getSelectedReport());
            this.showErrorNotify(report.facility.name);
            return {};
        }

        if (data.isOk) {
            this.setCurrentPopup(null);
            const report = this.collection.getReport(this.getSelectedReport());
            this.showSuccessNotify(report.status, report.facility.name);
        }

        return data;
    };

    revokeReport = async (id: number) => {
        try {
            await this.apiService.deleteReport(id);
        } catch (e) {
            this.showErrorNotify();
        }
    }

    showSuccessNotify = (status: STATUS_NAMES, name: string) => {
        this.state.successNotify.isShow = true;
        this.state.successNotify.status = status;
        this.state.successNotify.name = name;

        if (this.state.successNotify.timeOut)
            {clearTimeout(this.state.successNotify.timeOut)}

        this.state.successNotify.timeOut = setTimeout(() => {
            this.hideSuccessNotify();
        }, 5000)
    }
    hideSuccessNotify = () => this.state.successNotify.isShow = null;
    getSuccessNotify = () => this.state.successNotify;

    showErrorNotify = (name?: string) => {
        this.state.errorNotify.isShow = true;
        this.state.errorNotify.name = name || '';

        if (this.state.errorNotify.timeOut)
            {clearTimeout(this.state.errorNotify.timeOut)}

        this.state.errorNotify.timeOut = setTimeout(() => {
            this.hideErrorNotify();
        }, 10000);
    }
    hideErrorNotify = () => this.state.errorNotify.isShow = false;
    getErrorNotify = () => this.state.errorNotify;
}
