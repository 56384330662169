import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ROSummaryReport } from '../models';

@Injectable({ providedIn: 'root' })
export class RoSummaryReportService extends EntityCollectionServiceBase<ROSummaryReport> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('ROSummaryReport', serviceElementsFactory);
    }
}
