import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Facility, FacilityEmission, Region, STATUS_NAMES } from 'src/namespace';

@Injectable({
    providedIn: 'root'
})
export class CollectionService {
    private _onUpdateReports$ = new BehaviorSubject<FacilityEmission[]>([]);
    onUpdateReports$ = this._onUpdateReports$.asObservable();

    private _onUpdateFacilities$ = new BehaviorSubject<Facility[]>([]);
    onUpdateFacilities$ = this._onUpdateFacilities$.asObservable();

    private _onUpdateRegions$ = new BehaviorSubject<Region[]>([]);
    onUpdateRegions$ = this._onUpdateRegions$.asObservable();

    private reports: FacilityEmission[] = [];
    private mapReports: FacilityEmission[] = [];

    updateReports(data: FacilityEmission[]) {
        this.reports = data;
        this._onUpdateReports$.next(data);
    }

    getReports() {
        return this.reports;
    }

    updateMapData(data: FacilityEmission[]) {
        this.mapReports = data;
    }

    getMapReports() {
        return this.mapReports;
    }

    getReport(id: number) {
        return this.reports.find(r => r.id === id);
    }

    getReportForFacilityId(id: number) {
        return this.reports.find(r => r.facility.id === id);
    }

    deleteReport(id: number) {
        const index = this.reports.findIndex(r => r.id === id);
        this.reports.splice(index, 1);
        this._onUpdateReports$.next(this.reports);
    }

    getReportsFiltered(status: STATUS_NAMES) {
        return this.reports.filter(d => d.status === status);
    }

    patchTable(data: FacilityEmission) {
        const index = this.reports.findIndex(t => t.id === data.id);

        this.reports[index] = data;

        this._onUpdateReports$.next(this.reports);
    }

    private facilities: Facility[] = [];

    updateFacilities(data: Facility[]) {
        this.facilities = data;
        this._onUpdateFacilities$.next(data);
    }

    getFacilitiesWithoutReport(): Facility[] {
        return this.facilities.filter(f => !this.reports.find(r => r.facility.id === f.id && r.status !== STATUS_NAMES.empty));
    }

    private regions: Region[] = [];

    updateRegions(data: Region[]) {
        this.regions = data;
        this._onUpdateRegions$.next(data);
    }

    getRegions() {
        return this.regions;
    }

    getRegionsAbsorption(ids: number[]): number {
        return this.regions.reduce((prevVal: number, r) => {
            if (!ids.length || ids.includes(r.id))
                {prevVal += r.sequestration;}

            return prevVal;
        }, 0);
    }

    getRegionsEmissionOther(ids: number[]): number {
        return this.regions.reduce((prevVal: number, r) => {
            if (!ids.length || ids.includes(r.id))
                {prevVal += r.other_emission;}

            return prevVal;
        }, 0);
    }

    getRegionsEmissionReports(ids: number[]): number {
        return this.regions.reduce((prevVal: number, r) => {
            if (!ids.length || ids.includes(r.id))
                {prevVal += r.emission_reports;}

            return prevVal;
        }, 0);
    }
}
