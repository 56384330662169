import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportsFacadeService } from '../../../services/facade/reports-facade.service';
import { CollectionService } from '../../../services/collection/collection.service';
import { TEXT } from '../../../../../locales/text';
import { CheckboxItem, STATUS_NAMES } from 'src/namespace';

@Component({
    selector: 'create-report',
    templateUrl: './create-report.component.html',
    styleUrls: ['./create-report.component.less']
})
export class CreateReportComponent implements OnInit {
    form: FormGroup;
    TEXT = TEXT;

    showSelector = false;
    list: CheckboxItem[] = [];
    isLoading = false;

    facilityError = '';
    emissionError = '';


    constructor(public facade: ReportsFacadeService, private collection: CollectionService) {
        this.form = new FormGroup({
            facility: new FormControl('', [
                Validators.required
            ]),
            emission: new FormControl('', [
                Validators.required,
                Validators.min(0)
            ])
        });
    }

    getSelectText = () => {
        const find = this.list.find(l => l.selected);
        return find ? find.label : '';
    }

    ngOnInit(): void {
        const facilities = this.collection.getFacilitiesWithoutReport();

        this.list = facilities.map(f => {
            const selected = this.facade.getSelectedFacility() === f.id
            if (selected)
                {this.form.controls.facility.setValue(f.id);}

            return {
                id: f.id,
                label: f.name,
                selected
            }
        });
    }

    select = () => {
        this.showSelector = false;
        const find = this.list.find(l => l.selected);
        this.form.controls.facility.setValue(find.id);
    }

    onSubmit = async () => {
        this.isLoading = true;

        const find = this.list.find(l => l.selected)

        const data: any = await this.facade.createReport({
            emission: this.form.value.emission,
            facility: find.id as number
        });
        this.isLoading = false;

        if (data.facility)
            {this.facilityError = data.facility;}

        if (data.emission)
            {this.emissionError = data.emission;}
    }

}
