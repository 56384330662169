<div class="header_menu">
    <div class="header_menu__title">
        <a class="header_menu__title-link" routerLink="/">
            <ct-balance-65-logo></ct-balance-65-logo>
        </a>
    </div>

    <div class="header_menu__tabs">
        <a *ngFor="let tab of tabs"
            class="header_menu__tab"
            [class.header_menu__tab-disabled]="tab.isDisabled"
            [routerLink]="'/' + tab.id"
            routerLinkActive="header_menu__tab-active"
        >
            {{tab.title}}
        </a>
    </div>

    <div class="header_menu__ask">
        <a href="mailto:info@cityair.ru" [innerHTML]="TEXT.header.askAQuestion"></a>
    </div>

    <lang-selector [setLangCb]="changeLang" [isRu]="isRu"></lang-selector>

    <ct-logout class="header_menu__logout_block"
        [user]="user"
        (logout)="logout.emit()"
    ></ct-logout>
</div>
