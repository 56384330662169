<div class="total_status {{isVertical ? 'total_status-vertical' : ''}}">
    <div class="total_status__block">
        <div class="total_status__label">{{arrOfStatuses.rework}}</div>
        <div class="total_status__number">{{reworkSum.toLocaleString()}}</div>
        <div class="total_status__dot total_status__dot-rework"></div>
    </div>
    <div class="total_status__block">
        <div class="total_status__label">{{arrOfStatuses.empty}}</div>
        <div class="total_status__number">{{emptySum.toLocaleString()}}</div>
        <div class="total_status__dot total_status__dot-empty"></div>
    </div>
    <div class="total_status__block">
        <div class="total_status__label">{{arrOfStatuses.approved}}</div>
        <div class="total_status__number">{{approvedSum.toLocaleString()}}</div>
        <div class="total_status__dot total_status__dot-approved"></div>
    </div>
    <div class="total_status__block">
        <div class="total_status__label">{{arrOfStatuses.awaiting}}</div>
        <div class="total_status__number">{{awaitingSum.toLocaleString()}}</div>
        <div class="total_status__dot total_status__dot-awaiting"></div>
    </div>
</div>