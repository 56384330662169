import { EMISSIONS, STATUS_NAMES, TABLE_COLUMNS_NAME } from 'src/namespace';
import { SectorSlugEnum } from 'src/store/models';

export const UNITS = {
    ton: {
        zero: 'tons',
        one: 'ton',
        two: 'tons',
        few: 'tons',
        many: 'tons',
        other: 'tons'
    }
};

export const table: {
    [key in TABLE_COLUMNS_NAME]: string
} = {
    id: 'ID',
    roId: 'roId',
    num: '#',
    year: 'Period',
    emission: `Tons of&nbsp;CO<sub>2</sub>&nbsp;equiv`,
    emissions: `Tons of&nbsp;CO<sub>2</sub>&nbsp;equiv`,
    status: 'Status',
    created_at: `Order&nbsp;dated`,
    sent_at: `Date&nbsp;of submission`,
    approved_at: `Date&nbsp;of approval`,
    facility: 'Object',
    address: 'Address',
    sender: 'Uploaded',
    sendReport: `Submit report`,
    blame: 'Processed',
    comments: 'Comment',
    name: 'Name',

    sector: 'Sector',
    emissionMass: 'Emission, tons',
    organizationName: 'Organization',
    targetEmissionMass: 'Target emission, tons',
    quota: 'Quota, tons',
    balance: 'Balance, tons',
    bill: 'CUs accounting',
    source: 'Carbon intensity, sources',
    carbonCapacity: 'Carbon capacity',
}

export const common = {
    emission: 'Emission',
    absorption: 'Absorption',
    noData: 'No data',
};

export const form = {
    title: 'Report',
    object: 'Facility',
    emission: ' Emission',
    send: 'Send report',
    sendCorrect: 'Send revised report',
    errorEmission: 'Incorrect value'
}

export const status = {
    [STATUS_NAMES.all]: 'All',
    [STATUS_NAMES.rework]: 'To revise',
    [STATUS_NAMES.awaiting]: 'On approval',
    [STATUS_NAMES.approved]: 'Approved'
}

export const statusCoord: {[key in STATUS_NAMES]: string} = {
    ...status,
    [STATUS_NAMES.empty]: 'Did not submit report'
}

export const statusManager: {[key in STATUS_NAMES]: string} = {
    ...status,
    [STATUS_NAMES.empty]: 'No report'
}

export const pageNames = {
    reports: 'Reporting',
    normative: 'Statutory acts',
    map: 'Map',
    ro: 'Organizations',
    balance: 'Balance',
    facilities: 'Facilities',
    dashboard: 'Dashboard',
    strategy: 'Strategy',
}

export const menuBalance = {
    map: 'Map',
    table: 'Balance'
};

export const balancePage = {
    calculations: 'Calculations'
};

export const cardType = {
    sources: 'GHG Sources',
    regulatedSources: 'RO',
    absorbers: 'GHG Absorbers',
    climateProjects: 'Climate Projects'
};

export const cardName = {
    sources: 'All Sources',
    regulatedSources: 'All regulated organizations',
    absorbers: 'All GHG Absorbers',
    climateProjects: 'All Climate Projects'
};

export const cardCategory = {
    inventory: 'Inventory',
    regulation: 'Regulation'
};

export const header = {
    balance: `Balance&nbsp;65`,
    askAQuestion: `Ask&nbsp;question`,
    logOff: 'Log off',
    periodInWork: 'Period in work',
    createReport: 'Create report',
    submitReport: 'Submit report',
}

export const messageReportSend = {
    [STATUS_NAMES.approved]: 'approved',
    [STATUS_NAMES.rework]: 'sent for revision',
    [STATUS_NAMES.awaiting]: 'sent for approval',
    [STATUS_NAMES.empty]: 'revoked'
}

export const others = {
    widgetUnit: 'tons of CO<sub>2</sub>&nbsp;equiv',
    balance: 'Balance',
    revoke: 'Revoke',
    change: 'Change',
    tg: 'tons',
    year: 'year',
    edit: 'Edit',
    emissionReport: 'Emission report',
    reportingPeriod: 'Reporting period',
    commentaryNature: 'Commentary from the Min. of Nature',
    clears: 'Clear',
    searchManager: 'Search for object',
    searchCoord: 'Search for object, legal entity',
    searchName: 'Search by name',
    searchDoc: 'Search for document',
    sendForRevision: 'Send for revision',
    toApprove: 'To approve',
    reportWillRequireRevision: 'If the report requires revision, please specify the reason for this. It will be easier to understand the cause. Thank you!',
    regionAll: 'All Sakhalin',
    emissionsAll: 'All',
    tonsYear: 'tons/year',
    messageReportSendError: 'Error occurred',
    messageReportSendStart: 'Source Emission Report ',
    messageReportSendErrorEnd: 'when sending Source Emission Report',
    messageReportSendErrorDefault: ' when sending data',
    nothing: 'Unfortunately, there is nothing',
    weCantSeemToFind: `No&nbsp;search&nbsp;results&nbsp;found`,
    mobileVersion: `Mobile version is unavailable`,
    openTheSiteOn: `Open the web site on&nbsp;laptop or&nbsp;desktop computer`,
    copyright: `Sakhalin region, SSU Sakhalin Climate Center, developed by CityAir`,
    copyright1: [`Sakhalin region, `, 'https://sakhalin.gov.ru/'],
    copyright2: ['SSU Sakhalin Climate Center', 'http://sakhgu.ru/'],
    copyright3: [`Developed by CityAir`, 'https://cityair.io/'],
    all: 'all',
    collapse: 'minimize',
    expand: 'more',
    documentation: 'Documents',
    search: 'Search',
    notSelected: 'All',
    addOrganization: 'Add Organization',
    notSelectedReport: 'Not Selected',
    weDidNotFind: 'С данными параметрами ничего не нашли',
    weAreAlsoAwaitingReceipt: 'Мы также в ожидании поступления данных, но по какой-то причине они еще не пришли...',
}

export const listPageTitle = {
    emissions: 'GHG sources',
    regions: 'Geography',
    organizations: 'GHG sources',
}

export const loginPage = {
    carbonBalance: 'Carbon balance of Sakhalin region',
    login: 'Login',
    pass: 'Password',
    sign: 'Sign in',
    loginProblems: 'Login problems?',
    devCityAir: 'Developed by CityAir',
    wrong: 'Wrong login or password',
    developedByCityAir: 'Developed by CityAir',
    ministryOfEcology: 'Ministry of Ecology of Sakhalin Region'
}

export const emissionsList = [
    {name: 'Energetics', id: EMISSIONS.emission_reports, children: []},
    {
        name: 'Industrial processes and product use', id: null,
        children: [
            {name: 'Production of products from mineral raw materials', id: null},
            {name: 'Chemical industry', id: null},
            {name: 'Metallurgical industry', id: null},
            {name: 'Non-energy use of fuels and use of solvents ', id: null},
            {name: 'Electronics industry', id: null},
            {name: 'Use of products as substitutes for ozone-depleting substances', id: null},
            {name: 'Production and use of other products', id: null},
        ]
    },

    {name: 'Agriculture', id: null, children: []},
    {name: 'LULUCF', id: null, children: []},
    {name: 'Waste', id: null, children: []},
    {name: 'Other', id: EMISSIONS.other_emission, children: []},
];

export const uoivBalance = {
    kiloCO2Equiv: 'kt&nbsp;CO<sub>2</sub>-e',
    isPlanned: 'Plan',
    isFactual: 'Fact',
    dummyButton: 'In development',
    planJustification: 'Justification for the plan',
    edit: 'Edit',
    balance: 'Balance',
    summaryTitles: ['Absorption', 'Regulated Emissions', 'Factual Emissions'],
    summaryTableTitles: [
        'Absorbers',
        'Emissions. Regulated Organizations',
        'Emissions',
        'Absorbers. Climate Projects'
    ],
    tableColumns: {
        name: 'Name',
        emission: 'Emissions, tons',
        absorption: 'Absorption, tons'
    },
    regulatedSequestrationLabels: {
        ghg_storage: 'CO<sub>2</sub> capture and storage',
        reforestation: 'Reforestation and afforestation',
        wildfire_management: 'Wildfires fighting and preventing',
        pest_control: 'Forest diseases fighting and preventing'
    },
    regulatedEmissionLabels: {
        emission_ro: 'Regulated organizations',
        regional_program_emissions: 'Regional program',
        climate_projects_emissions: 'Climate projects',
    },
};

export const periodStatus = {
    opened: 'Period of work',
    closed: 'Finished',
    planned: 'Planning',
};

export const ORGANIZATIONS = {
    zero: 'organizations',
    one: 'organization',
    two: 'organizations',
    few: 'organizations',
    many: 'organizations',
    other: 'organizations'
};

export const FACILITIES = {
    zero: 'facilities',
    one: 'facility',
    two: 'facilities',
    few: 'facilities',
    many: 'facilities',
    other: 'facilities'
};

export const PAGE_TITLES = {
    regulatedOrganizations: 'Regulated Organizations',
    facilities: 'Facilities containing sources of GHG emissions'
};

export const ORGANIZATION_TYPE_NAMES: {
    [name in SectorSlugEnum]: string
} = {
    [SectorSlugEnum.ENERGY]: 'Power industry',
    [SectorSlugEnum.INDUSTRY]: 'Industrial processes and product use',
    [SectorSlugEnum.ARGICULTURE]: 'Agriculture',
    [SectorSlugEnum.LAND_AND_FORESTRY]: 'Land use, land use changes, and forestry',
    [SectorSlugEnum.WASTE]: 'Waste',
    [SectorSlugEnum.OTHER]: 'Other'
};

export const ORGANIZATION_TYPE_NAMES_SHORT = ORGANIZATION_TYPE_NAMES;

export const BALANCE_RO = {
    quota: ['Quota', 'Q'],
    facilities: ['Total Emissions', 'TE'],
    carbonUnits: ['Carbon units', 'CU'],
    balance: ['Balance', 'B'],
    plan: 'Plan',
    awaiting: 'On approval',
};

export const RO_DETAILS_PAGE = {
    title: 'General Information',
    details: {
        organizationName: 'Organization Name',
        sources: 'Emission Sources',
        carbonCapacity: 'Carbon Capacity of the Sources',
        legalAddress: 'Legal Address',
        postalAddress: 'Postal Address',
        inn: 'INN',
        ogrn: 'OGRN',
        phoneNumber: 'Phone Number',
        email: 'Email',
    },
    contactsTitle: 'Contacts',
    contacts: {
        fullName: 'Full Name',
        position: 'Position',
        phoneNumber: 'Phone Number',
        email: 'Email'
    },
};

export const LIST_OM = {
    uncheckAll: '__uncheck_all_not_translated__',
    checkAll: '__check_all_not_translated__',
}

export const COMMON = {
    save: 'save',
    cancel: 'cancel',
}

export const OTHER = {
    noSelect: '__no_select_not_translated',
}


export const DASHBOARD_WIDGET = {
    quote: 'Quota',
    emission: 'Emissions',
    boughtUnits: 'CUs Bought',
    soldUnits: 'CUs Sold',
    measureUnit: 'CUs',
    titleDynamic: 'Dynamics of work <br> with reports for ',
    title2tp: 'Reports on the form <br> 2-TP (air)',
    titlePg: 'Reports <br> on GHG Emissions',
    titleKp: 'Reports <br> on the implementation of CPs',
    titleUnits: 'Carbon units <br> purchase reports',
}

export const STRATEGY = {
    title: 'Strategy of Sakhalin Region for the period up to 2025, with a view to 2035',
    nameDoc: 'Стратегия Сахалинской области  на период до 2025 года с перспективой до 2035 года.pdf',
    updated: 'Updated',
    subTitle: 'Слово Губернатора Сахалинской области В.И.Лимаренко',
    valeryLimarenko: 'Валерий Лимаренко',
    governorOfTheSakhalinRegion: 'Губернатор Сахалинской области',
}

export const ERROR_POPUP = {
    alasWeCouldNot: 'Oops! An error occurred on the server',
    tryRefreshingThePage: 'Please try refreshing the page.',
    didNotHelp: 'Didn\'t help? ',
    writeToUs: 'Please write to us',
}
