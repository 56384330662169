<div class="annual-timeline scroll-grey">
    <div class="annual-timeline-item"
        *ngFor="let year of years"
        [class.annual-timeline-item--active]="currentYear === year"
        (click)="loadYearData($event, year)"
    >
        <ca-spinner *ngIf="loadingYear === year; else showText"></ca-spinner>
        <ng-template #showText>
            {{year}}
        </ng-template>
    </div>
</div>
