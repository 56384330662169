import { Component, Input } from '@angular/core';

@Component({
    selector: 'ca-spinner',
    template: `
        <div class="spinner">
            <svg viewBox="0 0 100 100">
                <circle class="spinner-circle" cx="50" cy="50" r="30" fill="none" [attr.stroke]="color"></circle>
            </svg>
        </div>
    `,
    styleUrls: ['spinner.component.less']
})
export class SpinnerComponent {
    @Input() color = '#FFF';
}
