<table class="table" cellSpacing="0" cellPadding="0">
    <thead>
    <tr class="table__header">
        <th *ngFor="let h of header">
            <ct-sort-list-item *ngIf="h.sort !== undefined; else notSortable"
                [text]="h.name"
                [sortingDirection]="h.sort"
                [align]="h.props?.alignment"
                (click)="sortClick(h)"
            ></ct-sort-list-item>

            <ng-template #notSortable>
                {{h.name}}
            </ng-template>
        </th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let t of table" class="table__content_tr">
        <td class="table__font_weight_semi_bold table__font_color_gray" title="{{sectorsMap[t.facility.sector]}}">{{t.id || '-'}}</td>
        <td class="table__font_weight_semi_bold center">
            <organization-type-icon [slug]="sectorsMap[t.facility.sector]"></organization-type-icon>
        </td>
        <td class="table__font_weight_semi_bold">{{t.facility?.name}}</td>
        <td class="table__max_width table_link"
            [routerLink]="'/' + EXECUTIVE_PAGES.ro + '/' + t.facility.organization?.id"
        >{{t.facility?.organization?.name}}</td>
        <td class="table__font_weight_bold table__number right">{{t.emission | emission}}</td>
        <td>{{formatDayMonth2(t.sent_at)}}</td>
        <td>{{formatDayMonth2(t.approved_at)}}</td>
        <td>{{t.blame?.email}}</td>
        <td>
            <div class="table__comment"
                *ngIf="t.comment"
                caTooltip
                [caTooltipTemplate]="commentTooltip"
                [caTooltipPlacement]="'top'"
                [caTooltipFollowPointer]="false"
            ></div>

            <ng-template #commentTooltip>
                <ct-comment-tooltip [text]="t.comment"></ct-comment-tooltip>
            </ng-template>
        </td>
        <td>
            <status-tags [status]="t.status"></status-tags>
        </td>

        <td *ngIf="canEdit" class="functional_column">
            <kebab-menu *ngIf="t.year >= reportYear && t.status !== STATUS_NAMES.empty" [positionOpenedMenu]="'left'">
                <div class="table__btn_revoke" (click)="edit.emit(t.id)">{{TEXT.others.change}}</div>
            </kebab-menu>
        </td>

    </tr>
    </tbody>
</table>

<ct-search-result-placeholder *ngIf="table?.length === 0"></ct-search-result-placeholder>
