import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Region } from '../models';

@Injectable({ providedIn: 'root' })
export class RegionsService extends EntityCollectionServiceBase<Region> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Region', serviceElementsFactory);
    }
}
