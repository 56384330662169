import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Organization } from '../models';

@Injectable({ providedIn: 'root' })
export class RoDetailsService extends EntityCollectionServiceBase<Organization> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Organization', serviceElementsFactory);
    }
}
