import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { User } from 'src/namespace';
import { ANIMATION_OPACITY } from 'src/config';

@Component({
    selector: 'ct-logout',
    templateUrl: 'logout.component.html',
    styleUrls: ['logout.component.less'],
    animations: ANIMATION_OPACITY
})
export class LogoutComponent implements OnChanges {
    @Input() user: User;
    @Output() logout = new EventEmitter<void>();

    isMenuOpened = false;
    name: string;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.user && this.user) {
            let name = this.user.last_name || '';
            name += this.user.first_name ? ` ${this.user.first_name[0]}.` : '';
            name += this.user.middle_name ? ` ${this.user.middle_name[0]}.` : '';

            this.name = name || this.user.username;
        }
    }
}
