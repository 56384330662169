import { Component, Input } from '@angular/core';

@Component({
    selector: 'ct-logout-button',
    templateUrl: 'logout-button.component.html',
    styleUrls: ['logout-button.component.less'],
})
export class LogoutButtonComponent {
    @Input() userName: string;
}
