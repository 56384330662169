import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortListItemModule } from 'src/components/sort-list-item/sort-list-item.module';
import { EmissionPipe } from 'src/pipes/pipes';
import { PipesModule } from 'src/pipes/pipes.module';
import { DataGridComponent } from 'src/components/data-grid/data-grid-component/data-grid.component';



@NgModule({
    declarations: [
        DataGridComponent
    ],
    exports: [
        DataGridComponent
    ],
    imports: [
        CommonModule,
        SortListItemModule,
        PipesModule,
    ],
    providers: [EmissionPipe],
})
export class DataGridModule { }
