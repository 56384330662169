import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TEXT } from 'src/locales/text';
import { CheckboxItem } from 'src/namespace';

@Component({
    selector: 'selectbox-year',
    templateUrl: 'selectbox-year.component.html',
})
export class SelectboxYearComponent implements OnInit {
    @Input() list: number[] = [];

    @Output() filterByItem = new EventEmitter<CheckboxItem>();

    TEXT = TEXT;

    yearsItem: CheckboxItem;

    yearsDropdownOpened = false;

    listYears: CheckboxItem[];

    ngOnInit() {
        this.listYears = this.list.map((y, i) => ({
                id: y,
                label: `${y}`,
                selected: i === 0
            }));

        this.yearsItem = this.listYears[0];
    }

    filterBy(items: CheckboxItem[]) {
        this.yearsItem = items.find(it => it.selected);
        this.filterByItem.emit(this.yearsItem);
    }
}
