import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';

import { TooltipDirective } from './tooltip.directive';
import { TooltipOutletComponent } from './components/tooltip-outlet/tooltip-outlet.component';
import { TooltipPointerComponent } from './components/tooltip-pointer/tooltip-pointer.component';
import { TooltipPlacementComponent } from './components/tooltip-placement/tooltip-placement.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { DetailedTooltipComponent } from './components/detailed-tooltip/detailed-tooltip.component';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        PipesModule,
    ],
    exports: [
        TooltipDirective,
        TooltipOutletComponent,
        TooltipPointerComponent,
        TooltipPlacementComponent,
        TooltipComponent,
        InfoTooltipComponent,
        DetailedTooltipComponent,
    ],
    declarations: [
        TooltipDirective,
        TooltipOutletComponent,
        TooltipPointerComponent,
        TooltipPlacementComponent,
        TooltipComponent,
        InfoTooltipComponent,
        DetailedTooltipComponent,
    ]
})
export class TooltipsModule {}
