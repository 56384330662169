import {
    Component,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
} from 'rxjs';
import {
    distinct,
    map,
    pairwise,
    scan,
    startWith,
} from 'rxjs/operators';
import {
    GridSortingColumns,
    Sorter
} from 'src/libs/ui/sorting';
import { AuthService } from 'src/modules/core/services/auth.service';
import { ReportsFacadeService } from 'src/modules/core/services/facade/reports-facade.service';
import { LoaderService } from 'src/modules/core/services/loader.service';
import {
    ROLE,
} from 'src/namespace';
import {
    ReportPageFilter,
    ReportsViewModel
} from 'src/pages/reports-page/reports-view-model';
import {
    FacilityYearlyViewModel
} from 'src/store/models';
import { FacilityEmissionService } from 'src/store/services/facility-emission.service';
import { FacilityService } from 'src/store/services/facility.service';
import { SectorService } from 'src/store/services/sector.service';
import {
    ANIMATION_HIDE,
    ANIMATION_TOP
} from 'src/config';

import {
    TEXT
} from 'src/locales/text';

type FilterEvent = { [name: string]: string };

@Component({
    selector: 'ct-reports-page',
    templateUrl: './reports-page.component.html',
    styleUrls: [
        './reports-page.component.less',
        '../../styles/table-common.less',
    ],
    animations: [ANIMATION_TOP, ANIMATION_HIDE],
})
export class ReportsPageComponent implements OnInit {
    // grid settings
    columns: GridSortingColumns<FacilityYearlyViewModel>[] = [
        {key: 'id', title: TEXT.table.id, align: 'center'},
        {key: 'sector', title: TEXT.table.sector, align: 'center'},
        {key: 'name', title: TEXT.table.name},
        {key: 'address', title: TEXT.table.address},
        {
            key: 'emission',
            title: TEXT.table.emission,
            format: 'emissionPipe',
            align: 'right',
        },
        {
            key: 'sent_at',
            title: TEXT.table.sent_at,
            format: 'medium_date',
            predicate: (i) => i.sent_at ? Date.parse(i.sent_at) : 0,
        },
        {
            key: 'approved_at',
            title: TEXT.table.approved_at,
            format: 'medium_date',
            predicate: (i) => i.approved_at ? Date.parse(i.approved_at) : 0,
        },
        {key: 'sender', title: TEXT.table.sender},
        {key: 'comment', title: TEXT.table.comments},
        {key: 'status', title: TEXT.table.status},
    ];
    sorter = new Sorter(this.columns);

    // filtering settings
    defaultFilter: ReportPageFilter = {
        text: '',
        year: '2020',
    };

    // any filter use .next({'event_name': 'event_value'});
    filter$ = new BehaviorSubject<FilterEvent>(null);

    // primary model to display
    reports: FacilityYearlyViewModel[];

    // other displaying shit
    TEXT = TEXT;

    canEdit = false;



    yearFilterValues = [2020, 2019, 2018];

    constructor(
        private facilitySrv: FacilityService,
        private facilityEmSrv: FacilityEmissionService<number>,
        private sectorSrv: SectorService,
        private viewContainerRef: ViewContainerRef,
        private loaderService: LoaderService,
        public route: ActivatedRoute,
        private authSrv: AuthService,
        public facade: ReportsFacadeService,
    ) {
        loaderService.waitLoadingComplete( // loader
            viewContainerRef,
            [
                this.facilitySrv,
                this.facilityEmSrv,
                this.sectorSrv,
            ]
        );
        this.canEdit = authSrv.getUser().role === ROLE.executive;  // rendering shit
    }

    ngOnInit() {
        // {'text': 'aaa'}, {year: 2020} => {text: '', year: 2020} ( {year: 2019} ) => {text: '', year: 2019})
        const allFilters$: Observable<ReportPageFilter> = this.filter$.pipe(
            distinct(),
            scan(
                (acc, cur) => Object.assign({}, acc, cur), this.defaultFilter),
        ); // cast single filters events to full filtering object { year: val, 'text': val}


        allFilters$.pipe(
            startWith({text: '', year: null}),
            pairwise(),
        ).subscribe( ([prev, curr]) => {
            this.loadFilteredData(prev, curr);
        }); // it loads data that uses filters as extra args for api, like ?year=xx


        this.loadData(); // load generic dadta
            // reload data if need on filter update
        combineLatest([
            allFilters$, // we combine our data with filter changes
            this.facilitySrv.entities$,
            this.facilityEmSrv.entities$,
            this.sectorSrv.entities$,
        ]).pipe(
            map(([pageFilters, fs, ems, sectors]) => new ReportsViewModel(pageFilters, fs, ems, sectors)), // we can use .filter to here, but I decided to use extra pipe only for filtering
        ).subscribe(viewModel => this.reports = viewModel.data); // finally subscribe to the data
    }

    loadData() {
        this.facilitySrv.getAll();
        this.sectorSrv.getAll();
    }

    loadFilteredData(prev: ReportPageFilter, curr: ReportPageFilter) {
        if (curr.year != prev.year) {
            this.facilityEmSrv.getWithQuery({year: curr.year.toString()});
        }
    }
}
