import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'ct-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit, OnDestroy {
    hide: boolean;

    constructor() {
    }

    ngOnInit(): void {
        document.body.style.overflowY = 'hidden';
    }

    ngOnDestroy() {
        document.body.style.overflowY = '';
    }

    public setHide() {
        this.hide = true;
    }
}
