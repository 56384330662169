import { Component, Input, OnInit } from '@angular/core';

import { TEXT } from 'src/locales/text';
import { Sortable, SortingColumn } from '../../directives/sortable/sortable.directive';
import { SectorSlugEnum } from 'src/store/models';

export enum SummaryTableType {
    ABSORBERS = 'absorbers',
    EMISSIONS = 'emissions',
}

type DataItem = {
    title: string;
    value?: number;
    type?: SectorSlugEnum;
};

type ColumnType = {
    title: string;
    value: number;
};

@Component({
    selector: 'balance-summary-table',
    templateUrl: 'balance-summary-table.component.html',
    styleUrls: ['balance-summary-table.component.less'],
})
export class BalanceSummaryTableComponent extends Sortable<ColumnType> implements OnInit {
    @Input() type: SummaryTableType;
    @Input() value: number;
    @Input() details: DataItem[];
    @Input() title: string;

    isOpened = true;

    sortingColumns: SortingColumn<ColumnType>[] = [];

    sectorSlugEnum = SectorSlugEnum;

    ngOnInit() {
        this.sortingColumns = [
            {
                direction: 0,
                predicate: (d: ColumnType) => d.title,
                props: {
                    title: TEXT.uoivBalance.tableColumns.name
                }
            },
            {
                direction: 0,
                predicate: (d: ColumnType) => d.value,
                props: {
                    title: this.type === SummaryTableType.ABSORBERS
                        ? TEXT.uoivBalance.tableColumns.absorption
                        : TEXT.uoivBalance.tableColumns.emission
                }
            }
        ];
    }
}
