import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Document } from '../models';

@Injectable({ providedIn: 'root' })
export class DocumentService extends EntityCollectionServiceBase<Document> {
    [name: string]: any;
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Document', serviceElementsFactory);
    }
}
