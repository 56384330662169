import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ANIMATION_OPACITY } from 'src/config';

@Component({
    selector: 'message-report-sent-wrapper',
    templateUrl: './message-report-sent-wrapper.component.html',
    styleUrls: ['./message-report-sent-wrapper.component.less'],
    animations: [ANIMATION_OPACITY]
})
export class MessageReportSentWrapperComponent {
    @Input() isShow = false;
    @Output() closeNotify = new EventEmitter<void>();
}
