<div class="popup-facility">
    <div class="popup-facility-icon"></div>
    <div class="popup-facility__content">
        <div class="popup-facility__name">{{report?.facility.name}}</div>
        <div class="popup-facility__organization">
            <a class="common-link common-link--underlined"
                [routerLink]="['/', PAGES.ro, report?.facility.organization.id]"
            >
                {{report?.facility.organization.name}}
            </a>
        </div>
        <div class="popup-facility__address">{{report?.facility.address}}</div>
        <div class="popup-facility__info">
            <ct-contribution-value type="emission" [value]="report?.emission"></ct-contribution-value>
        </div>
    </div>
    <div class="popup-facility__actions">
        <ca-cross-button (click)="closeAction.emit()"></ca-cross-button>
    </div>
</div>
