import { Component, Input } from '@angular/core';

export type Direction = 'up' | 'down' | 'left' | 'right';

@Component({
    selector: 'ct-chevron',
    templateUrl: 'chevron.component.html',
    styleUrls: ['chevron.component.less'],
})
export class ChevronComponent {
    @Input() direction: Direction = 'down';
}
