import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChevronModule } from 'src/components/chevron/chevron.module';
import { InputForDropdownComponent } from './input-for-dropdown.component';

@NgModule({
    imports: [
        CommonModule,
        ChevronModule,
    ],
    declarations: [InputForDropdownComponent],
    exports: [InputForDropdownComponent]
})
export class InputForDropdownModule {}
