<div class="dashboard">
    <dashboard-widget
            [year]="data.year"
            [quote]="data.summary.quotas"
            [emission]="data.summary.emissions"
            [boughtUnits]="data.summary.boughtCU"
            [soldUnits]="data.summary.soldCU"
    ></dashboard-widget>

    <div class="dashboard__body">
        <dashboard-chart-big class="dashboard__wrapper"
                             [reworkSum]="6"
                             [awaitingSum]="23"
                             [approvedSum]="data.reports.approved + 21"
                             [emptySum]="29"
                             [title]="TEXT.DASHBOARD_WIDGET.titleDynamic + (data.year || '')"
                             style="cursor: pointer"
        ></dashboard-chart-big>

        <div class="dashboard__wrapper">
            <dashboard-chart class="dashboard__item"
                             [reworkSum]="data.reports.rework"
                             [awaitingSum]="data.reports.awaiting"
                             [approvedSum]="data.reports.approved"
                             [emptySum]="data.reports.empty"
                             [title]="TEXT.DASHBOARD_WIDGET.title2tp"

                             [routerLink]="'/' + EXECUTIVE_PAGES.reports"
                             style="cursor: pointer"
            ></dashboard-chart>
            <dashboard-chart class="dashboard__item"
                             [reworkSum]="3"
                             [awaitingSum]="12"
                             [approvedSum]="15"
                             [emptySum]="0"
                             [title]="TEXT.DASHBOARD_WIDGET.titlePg"
                             [isDisable]="true"
            ></dashboard-chart>
        </div>
        <div class="dashboard__wrapper">
            <dashboard-chart class="dashboard__item"
                             [reworkSum]="3"
                             [awaitingSum]="10"
                             [approvedSum]="5"
                             [emptySum]="1"
                             [title]="TEXT.DASHBOARD_WIDGET.titleKp"
                             [isDisable]="true"
            ></dashboard-chart>
            <dashboard-chart class="dashboard__item"
                             [reworkSum]="0"
                             [awaitingSum]="1"
                             [approvedSum]="1"
                             [emptySum]="28"
                             [title]="TEXT.DASHBOARD_WIDGET.titleUnits"
                             [isDisable]="true"
            ></dashboard-chart>
        </div>
    </div>
</div>
