import { Component, Input, OnInit } from '@angular/core';
import { TEXT } from 'src/locales/text';
import { REPORT_YEAR } from 'src/config';

@Component({
    selector: 'dashboard-widget',
    templateUrl: './dashboard-widget.component.html',
    styleUrls: ['./dashboard-widget.component.less']
})
export class DashboardWidgetComponent implements OnInit {
    @Input() year: number;
    @Input() quote: number;
    @Input() emission: number;
    @Input() boughtUnits: number;
    @Input() soldUnits: number;

    TEXT = TEXT;

    REPORT_YEAR = REPORT_YEAR;

    constructor() {
    }

    ngOnInit(): void {
    }

}
