import {
    Component,
    Input
} from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Observable } from 'rxjs';

import { TEXT } from 'src/locales/text';
import {
    Sortable,
    SortingColumn
} from 'src/modules/core/directives/sortable/sortable.directive';
import { TABLE_COLUMNS_NAME } from 'src/namespace';
import {
    CollectionsDb,
    ROSummaryReport,
    Sector,
} from 'src/store/models';
import { SectorService } from 'src/store/services/sector.service';

@Component({
    selector: 'table-regulated-organizations',
    templateUrl: 'table-regulated-organizations.component.html',
    styleUrls: [
        '../../../../styles/table-common.less',
        './table-regulated-organizations.component.less',
    ],
})
export class TableRegulatedOrganizationsComponent extends Sortable<ROSummaryReport> {
    @Input() data: ROSummaryReport[] = [];
    sectorsById$: Observable<Dictionary<Sector>>;
    manager: CollectionsDb;

    constructor(sectorSrv: SectorService) {
        super();
        this.sectorsById$ = sectorSrv.entityMap$ as Observable<Dictionary<Sector>>;
        this.manager = new CollectionsDb();
        sectorSrv.entityMap$.subscribe(data => this.manager.setRelation('sector', data));
    }

    TEXT = TEXT;

    sortingColumns: SortingColumn<ROSummaryReport>[] = [
        {
            direction: 0,
            predicate: (d: ROSummaryReport) => d.id,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.id]
            }
        },
        {
            direction: 0,
            predicate: (d: ROSummaryReport) => d.name,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.name]
            }
        },
        {
            direction: 0,
            predicate: null,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.sector],
                alignment: 'center'
            }
        },
        {
            direction: 0,
            predicate: (d: ROSummaryReport) => d.emissions,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.emissionMass],
                alignment: 'right'
            }
        },
        {
            direction: 0,
            predicate: (d: ROSummaryReport) => d.quota,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.quota],
                alignment: 'right'
            }
        },
        {
            direction: 0,
            predicate: (d: ROSummaryReport) => d.balance,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.balance],
                alignment: 'right'
            }
        },
        {
            direction: 0,
            predicate: (d: ROSummaryReport) => this.calcBalance(d),
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.bill],
                alignment: 'right'
            }
        },
        {
            direction: 0,
            predicate: null,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.carbonCapacity],
                alignment: 'center'
            }
        }
    ];

    calcBalance(r: ROSummaryReport): number {
        const balance = r.transactions;
        if (!balance || !balance.length) {
            return null;
        }
        return balance.reduce((acc, tr) => acc + tr.change, 0);
    }

    trackById(_: number, item: ROSummaryReport) {
        return item.id;
    }
}
