<div class="filter-block">
    <div class="filter-block__search_list_wrapper">
        <searchbar
                class="facilities-page__filters-searchbar"
                [placeholder]="placeholder"
                (textChange)="changesSearch($event)"
        ></searchbar>
    </div>

    <div *ngIf="listYears.length > 1"
         class="filter-block__filter_dropdown_wrapper">
        <selectbox-year
                class="facilities-page__filters-dropdown"
                [list]="listYears"
                (filterByItem)="changesYear($event)"
        ></selectbox-year>
    </div>
    <div *ngIf="arrStatusForDropdownList.length > 1"
         class="filter-block__filter_dropdown_wrapper">
        <selectbox-status
                class="facilities-page__filters-dropdown"
                [list]="arrStatusForDropdownList"
                (filterByItem)="changesStatus($event)"
        ></selectbox-status>
    </div>

    <!--    <div (click)="cleanFilterObject()" [ngClass]="{-->
    <!--            'filter-block__btn_clear': true,-->
    <!--            'filter-block__btn_clear&#45;&#45;active': this.objectFilter.status || this.objectFilter.year-->
    <!--            }">{{TEXT.others.clears}}</div>-->
</div>