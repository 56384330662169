import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ItemRadioModule } from '../item-radio/item-radio.module';
import { SelectboxRadioComponent } from './selectbox-radio.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ItemRadioModule,
    ],
    declarations: [SelectboxRadioComponent],
    exports: [SelectboxRadioComponent]
})
export class SelectboxRadioModule {}
