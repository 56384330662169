import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

import { ApiModels } from './error-handler';
import { ProviderApi } from './provider';
import { CollectionService } from '../collection/collection.service';
import { environment } from 'src/environments/environment';
import { Region, FacilityEmission, STATUS_NAMES, Balance, Facility } from 'src/namespace';
import { AuthService } from '../auth.service';

class FormError {
    [key: string]: string[];

    constructor(obj: any) {
        Object.keys(obj).forEach(key => {
            if (obj[key].length) {
                this[key] = obj[key][0];
            }
        });
    }
}

const formErrorHandler = (error: HttpErrorResponse, resolve) => {
    if (error.status === 400 && Object.keys(error.error).length) {
        return resolve(new FormError(error.error));
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApiService extends ApiModels {
    constructor(
        private provider: ProviderApi,
        private collection: CollectionService,
        private authService: AuthService
    ) {
        super();
        this.setErrorTokenCb(() => this.authService.gotoLogin());
    }

    checkToken = async () => {
        if (this.authService.isTokenExpired()) {
            await this.authService.updateToken();
        }
    }

    async getReportsForMap(year: number) {
        const data: FacilityEmission[] = await this.provider.get(
            environment.url_carbon + 'facility_emissions/',
            {
                fields: 'emission,facility',
                expand: 'facility',
                year: `${year}`
            },
            this.checkToken,
            this.errorHandler
        );

        this.collection.updateMapData(data);
    }

    async getReports() {
        const data: FacilityEmission[] = await this._apiGetRequest(
            'reports_facility_emission/', { expand: 'facility,sender' },
        );
        this.collection.updateReports(data);
    }

    async getBalance() {
        const data: Balance[] = await this._apiGetRequest('balance/', {});
        return data;
    }

    async _apiGetRequest(url: string, params: {[param: string]: string | string[]}) {
        return await this.provider.get(
            environment.url_carbon + url,
            params,
            this.checkToken,
            this.errorHandler
        );
    }

    async getFacilities() {
        const data: Facility[] = await this._apiGetRequest('facilities/', {});
        this.collection.updateFacilities(data);
    }

    async getRegions(year: number) {
        const data: Region[] = await this._apiGetRequest('regions/', {year: `${year}`});
        this.collection.updateRegions(data);
    }

    async updateReport(
        id: number,
        props: {
            status?: STATUS_NAMES;
            emission?: number;
            comment?: string;
        }
    ): Promise<FormError | HttpErrorResponse | { isOk: true }> {
        return new Promise((resolve, reject) => {
            this.provider.patch(
                environment.url_carbon + `facility_emissions/${id}/`,
                props,
                this.checkToken,
                (error: HttpErrorResponse) => {
                    this.errorHandler(error);
                    formErrorHandler(error, resolve);
                    reject(error);
                    return throwError(error);
                }
            ).then((data) => {
                this.collection.patchTable(data);
                resolve({isOk: true});
            });
        });
    }

    async createReport(
        props: {
            emission: number;
            facility: number;
        }
    ): Promise<FormError | HttpErrorResponse | { isOk: true }> {
        return new Promise((resolve, reject) => {
            this.provider.post(
                environment.url_carbon + `facility_emissions/`,
                props,
                this.checkToken,
                (error: HttpErrorResponse) => {
                    this.errorHandler(error);
                    formErrorHandler(error, resolve);
                    reject(error);
                    return throwError(error);
                }
            ).then((data) => {
                this.collection.patchTable(data);
                resolve({isOk: true});
            });
        });
    }

    async deleteReport(id: number) {
        return new Promise((resolve, reject) => {
            this.provider.delete(
                environment.url_carbon + `facility_emissions/${id}/`,
                this.checkToken,
                (error: HttpErrorResponse) => {
                    this.errorHandler(error);
                    reject(error);
                    return throwError(error);
                }
            ).then((data) => {
                this.collection.deleteReport(id);
                resolve({isOk: true});
            });
        });
    }
}
