import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportsFacadeService } from '../../../services/facade/reports-facade.service';
import { CollectionService } from '../../../services/collection/collection.service';
import { TEXT } from '../../../../../locales/text';
import { CheckboxItem, FacilityEmission, STATUS_COLOR } from 'src/namespace';

@Component({
    selector: 'edit-report',
    templateUrl: './edit-report.component.html',
    styleUrls: ['./edit-report.component.less']
})
export class EditReportComponent implements OnInit {
    form: FormGroup;
    TEXT = TEXT;

    statusType = STATUS_COLOR;
    showSelector = false;
    list: CheckboxItem[] = [];
    isLoading = false;

    facilityError = '';
    emissionError = '';

    reportId: number = null;
    report: FacilityEmission = null;

    constructor(public facade: ReportsFacadeService, private collection: CollectionService) {
        this.form = new FormGroup({
            emission: new FormControl('', [
                Validators.required,
                Validators.min(0)
            ])
        });
    }

    ngOnInit(): void {
        this.reportId = this.facade.getSelectedReport();
        this.report = this.collection.getReport(this.reportId);

        this.form.controls.emission.setValue(this.report.emission);
    }

    onSubmit = async () => {
        this.isLoading = true;
        const data: any = await this.facade.updateReport({emission: this.form.value.emission});
        this.isLoading = false;

        if (data.emission)
            {this.emissionError = data.emission;}
    }

}
