import {
    AfterViewInit,
    Component, ViewContainerRef,
} from '@angular/core';
import {
    ActivatedRoute,
    ParamMap,
} from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import {
    map,
    switchMapTo,
    tap,
    filter
} from 'rxjs/operators';

import { ROLE } from 'src/namespace';
import { REPORT_YEAR, YEARS_FUTURE } from 'src/config';
import { isFalseNumber } from 'src/utils';
import { TEXT } from 'src/locales/text';
import { FacilityEmissionService } from 'src/store/services/facility-emission.service';
import { FacilityService } from 'src/store/services/facility.service';
import { QuotaService } from 'src/store/services/quota.service';
import { TransactionService } from 'src/store/services/transaction.service';
import { RoViewModel } from 'src/pages/ro-balance-page/view-model';
import { LoaderService } from 'src/modules/core/services/loader.service';
import { AuthService } from 'src/modules/core/services/auth.service';
import { OrganizationService } from 'src/store/services/organization.service';

@Component({
    selector: 'carbon-trail-ro-balance',
    templateUrl: './ro-balance-page.component.html',
    styleUrls: ['./ro-balance-page.component.less']
})
export class RoBalancePageComponent implements AfterViewInit {
    TEXT = TEXT;

    currentYear = REPORT_YEAR;
    years = YEARS_FUTURE;

    data: RoViewModel;

    showFacilities = false;

    isFalseNumber = isFalseNumber;

    orgId$: Observable<string>;

    constructor(
        readonly route: ActivatedRoute,
        private facilitySrv: FacilityService,
        private fEmissionsSrv: FacilityEmissionService<number>,
        private quotaSrv: QuotaService,
        private trSrv: TransactionService,
        private loaderService: LoaderService,
        private viewContainerRef: ViewContainerRef,
        authService: AuthService,
        organizationsService: OrganizationService,
    ) {
        const { role, organization } = authService.getUser();

        if (role === ROLE.ro && organization) {
            this.orgId$ = of(organization.toString());
        }

        if (this.orgId$) {
            this.orgId$ = this.orgId$.pipe(
                tap(id => organizationsService.getByKey(id + '/'))
            );
        } else {
            this.orgId$ = route.parent?.paramMap.pipe(
                map((params: ParamMap) => params.get('id')),
                filter(id => !!id)
            );
        }

        this.orgId$.pipe(
            tap(id => this.loadData(id)),
            switchMapTo(
                combineLatest([
                    facilitySrv.entityMap$,
                    fEmissionsSrv.entities$,
                    quotaSrv.entities$,
                    trSrv.entities$
                ]).pipe(
                    map((params) => new RoViewModel(this.years, ...params))
                )
            )
        ).subscribe((data) => this.data = data);

        this.loaderService.waitLoadingComplete(
            this.viewContainerRef,
            [
                this.facilitySrv,
                this.fEmissionsSrv,
                this.quotaSrv,
                this.trSrv,
            ]
        )
    }

    ngAfterViewInit() {
        document.getElementById('current_year_for_scrolling').scrollIntoView({inline: 'center'});
    }

    loadData(organization_id: string) {
        const params = {
            organization: organization_id,
        };

        this.facilitySrv.clearCache();
        this.fEmissionsSrv.clearCache();
        this.quotaSrv.clearCache();
        this.trSrv.clearCache();

        this.facilitySrv.getWithQuery(params);
        this.fEmissionsSrv.getWithQuery(params);
        this.quotaSrv.getWithQuery(params);
        this.trSrv.getWithQuery(params);
    }
}
