import {
    Component,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { TEXT } from 'src/locales/text';
import { LoaderService } from 'src/modules/core/services/loader.service';
import { DashboardReport } from 'src/store/models';
import { DashboardService } from 'src/store/services/dashboard.service';
import { EXECUTIVE_PAGES } from 'src/navigation/navigation-config';

@Component({
    selector: 'dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.less']
})
export class DashboardPageComponent implements OnInit {
    TEXT = TEXT;
    data: DashboardReport;

    EXECUTIVE_PAGES = EXECUTIVE_PAGES;

    constructor(
        protected dashboardSrv: DashboardService,
        protected loaderSrv: LoaderService,
        private viewContainerRef: ViewContainerRef,
    ) {
        loaderSrv.waitLoadingComplete(
            viewContainerRef,
            [this.dashboardSrv],
        );

        this.dashboardSrv.entities$.subscribe(data => this.data = data?.[0] || new DashboardReport());
    }

    ngOnInit(): void {
        this.dashboardSrv.getAll();
    }
}
