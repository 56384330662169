import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
    AfterViewInit
} from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    @Output() clickOutside: EventEmitter<Event> = new EventEmitter();

    constructor(private el: ElementRef) {}

    @HostListener('window:click', ['$event'])
    private onClickBody(event: Event) {
        if (typeof event !== 'undefined' && !this.el.nativeElement.contains(event.target)) {
            this.clickOutside.emit(event);
        }
    }
}

@Directive({
    selector: '[afterInitDirective]'
})
export class AfterInitDirective implements AfterViewInit {
    @Output() afterInitDirective: EventEmitter<Event> = new EventEmitter();

    ngAfterViewInit() {
        this.afterInitDirective.emit();
    }
}
