import {
    Component,
    Output,
    EventEmitter
} from '@angular/core';

import { SidebarStateService } from '../../sidebar-state.service';

@Component({
    selector: 'ca-sidebar-toggle-button',
    templateUrl: 'sidebar-toggle-button.component.html',
    styleUrls: ['sidebar-toggle-button.component.less']
})
export class SidebarToggleButtonComponent {
    @Output() toggle = new EventEmitter<boolean>();

    constructor(public stateService: SidebarStateService) {}

    togglePanel() {
        this.toggle.emit(!this.stateService.isActive);
    }
}
