<div class="loader_map {{hide ? 'loader_map-hide' : ''}}">
    <svg viewBox="0 0 100 100" style="width: 100px; height: 100px; margin: 0 auto;">
        <defs>
            <linearGradient id="Gradient" x1="50%" y1="0%" x2="50%" y2="100%">
                <stop offset="0%" stop-color="#5CC1E2">
                    <animate attributeName="stop-color" values="#5CC1E2; #6097F3; #5CC1E2" dur="4s"
                             repeatCount="indefinite"></animate>
                </stop>
                <stop offset="100%" stop-color="#6097F3">
                    <animate attributeName="stop-color" values="#6097F3; #5CC1E2; #6097F3" dur="4s"
                             repeatCount="indefinite"></animate>
                </stop>
            </linearGradient>
        </defs>
        <circle class="loader_map__circle" cx="50" cy="50" r="30" fill="none"></circle>
    </svg>
</div>