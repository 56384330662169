<div class="sidebar-menu">
    <a class="sidebar-menu-nav-item" routerLink="/">
        <ct-balance-65-logo></ct-balance-65-logo>
    </a>
    <div class="sidebar-menu-nav">
        <a class="sidebar-menu-nav-item"
            *ngFor="let p of pages"
            routerLink="/{{p.id}}"
            routerLinkActive="sidebar-menu-nav-item--active"
        >
            {{p.title}}
        </a>
    </div>
</div>
