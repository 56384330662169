import { Component, OnInit } from '@angular/core';

import { ReportsFacadeService } from '../../../services/facade/reports-facade.service';
import { CollectionService } from '../../../services/collection/collection.service';
import { FacilityEmission, STATUS_NAMES, STATUS_COLOR } from 'src/namespace';
import { TEXT, LANGUAGE } from '../../../../../locales/text';
import { swapArgsFirstWithSecond, formatDayMonthYear } from '../../../../../utils';

@Component({
    selector: 'coord-create-report',
    templateUrl: './coord-create-report.component.html',
    styleUrls: ['./coord-create-report.component.less']
})
export class CoordCreateReportComponent implements OnInit {
    TEXT = TEXT;

    facilityEmissionId: number = null;
    facilityEmission: FacilityEmission = null;
    statusType = STATUS_COLOR;
    STATUS_NAMES = STATUS_NAMES;
    isLoading = false;
    isLoadingToApprove = false;
    textarea: string;

    formatDayMonthYear = swapArgsFirstWithSecond(formatDayMonthYear).bind(null, LANGUAGE);

    constructor(public facade: ReportsFacadeService, private collection: CollectionService) {
    }

    ngOnInit(): void {
        this.facilityEmissionId = this.facade.getSelectedReport();
        this.facilityEmission = this.collection.getReport(this.facilityEmissionId);
    }

    accept = async () => {
        this.isLoading = true;
        await this.facade.updateReport({status: STATUS_NAMES.approved});
        this.isLoading = false;
    }

    rework = async () => {
        this.isLoading = true;
        await this.facade.updateReport(
            {
                comment: this.textarea,
                status: STATUS_NAMES.rework
            });
        this.isLoading = false;
    }
}
