import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    FacilityEmission,
    STATUS_NAMES,
    STATUS_COLOR,
    TABLE_COLUMNS_NAME
} from 'src/namespace';
import {
    formatDayMonthYear,
    isFalseNumber,
    swapArgsFirstWithSecond
} from 'src/utils';
import { LANGUAGE, TEXT } from 'src/locales/text';
import { SectorSlugEnum, Organization } from 'src/store/models';
import { SectorService } from 'src/store/services/sector.service';

@Component({
    selector: 'carbon-trail-table-manager',
    templateUrl: './table-manager.component.html',
    styleUrls: ['../../../../../../styles/table-common.less']
})
export class TableManagerComponent implements OnChanges, OnInit {
    @Input() filterYear: number;
    @Input() filterText: string;
    @Input() filterStatus: string | number;

    @Input() currentYear: number;

    @Input() collectionUpdate: Observable<FacilityEmission[]>;

    @Output() edit = new EventEmitter<number>();
    @Output() create = new EventEmitter<number>();
    @Output() revoke = new EventEmitter<number>();

    formatDayMonth2 = swapArgsFirstWithSecond(formatDayMonthYear).bind(null, LANGUAGE);
    statusName = STATUS_NAMES;
    statusType = STATUS_COLOR;
    TEXT = TEXT;
    tableData: FacilityEmission[];
    table: FacilityEmission[];
    SectorSlugEnum = SectorSlugEnum;

    parseFloat = parseFloat;

    tableColumns = [
        {name: TABLE_COLUMNS_NAME.id, sort: true},
        {name: TABLE_COLUMNS_NAME.sector, sort: true, alignment: 'center'},
        {name: TABLE_COLUMNS_NAME.facility, sort: true},
        {name: TABLE_COLUMNS_NAME.address, sort: false},
        {name: TABLE_COLUMNS_NAME.emission, sort: true, alignment: 'right'},
        {name: TABLE_COLUMNS_NAME.sent_at, sort: true},
        {name: TABLE_COLUMNS_NAME.approved_at, sort: true},
        {name: TABLE_COLUMNS_NAME.sender, sort: false},
        {name: TABLE_COLUMNS_NAME.comments, sort: false},
        {name: TABLE_COLUMNS_NAME.status, sort: true},
    ]

    header: {
        key: string;
        sort: -1 | 0 | 1 | undefined;
        name: string;
        props: {
            alignment: '' | 'left' | 'right' | 'center';
        };
    }[] = this.tableColumns.map(col => ({
            key: col.name,
            sort: col.sort ? (col.name === TABLE_COLUMNS_NAME.year ? 1 : 0) : undefined,
            name: TEXT.table[col.name],
            props: {
                alignment: col.alignment as '' | 'left' | 'right' | 'center'
            }
        }));

    sectorsMap: {
        [id: number]: SectorSlugEnum;
    } = {};

    organizationInfo: Organization;

    constructor(private sectorService: SectorService) {}

    ngOnInit() {
        this.collectionUpdate.subscribe((data: FacilityEmission[]) => {
            this.tableData = data;
            this.filterTable();
        });

        this.sectorService.getAll().pipe(
            // TODO: solvents are not available yet
            map((data) => data.filter(s => s.slug !== 'solvents'))
        ).subscribe(data => {
            this.sectorsMap = data.reduce((acc, s) => ({
                ...acc,
                [s.id]: s.slug as SectorSlugEnum
            }), {});
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filterTable();
    }

    filterTable() {
        if (!this.tableData)
            {return;}

        this.table = this.tableData.filter(t => {
            const text = (this.filterText || '').toLowerCase();
            const name = (t.facility.name || '').toLowerCase();

            if (text && !name.includes(text))
                {return false;}
            if (this.filterStatus && t.status !== this.filterStatus)
                {return false;}
            if (this.filterYear && t.year !== this.filterYear)
                {return false;}

            return true;
        });

        this.sort();
    }

    sortClick = (head) => {
        const currentSort = head.sort;
        this.header.forEach(h => {
            if (!isFalseNumber(h.sort))
                {h.sort = 0;}
        });

        head.sort = currentSort ? -currentSort : 1;

        this.sort(head);
    }

    sort(head?) {
        if (!head)
            {head = this.header.find(h => h.sort);}

        if (!head)
            {return;}

        const i = head.sort;
        const key = head.key;

        this.table.sort((_a, _b) => {
            let a;
            let b;

            if (typeof _a[key] === 'object' && _a[key] !== null) {
                a = _a[key].name || _a[key].email;
                b = _b[key].name || _b[key].email;
            } else if (key == 'organizationName') {
                a = _a.facility.organization.name;
                b = _b.facility.organization.name;
            } else {
                a = _a[key];
                b = _b[key];
            }

            if (a < b || a === null)
                {return i;}
            if (a > b || b === null)
                {return -1 * i;}

            return 0;
        })
    }
}
