import { createReducer, on } from '@ngrx/store';
import * as coreActions from './actions';

export interface ICoreState {
    del_it_later: string; // keep because of dummy interface
    isServerError: boolean;
}

export const initialState: ICoreState = {
    del_it_later: '',
    isServerError: false,
};

const _coreReducer = createReducer(
    initialState,
    // on(
    //     coreActions.onUserLoggedIn, (state: ICoreState, data: {response: Fadaster[]}) => {
    //         return {
    //             ...state,
    //             'collections': {
    //                 ...state.collections,
    //                 'fadaster': data.response.map(f => cast(Fadaster, f)),
    //             }
    //         };
    //     }
    // ),

    on(
        coreActions.onErrorCaught, (state: ICoreState) => ({
                ...state,
                isServerError: true
            })
    ),
);

export function coreReducers(state, action) {
    return _coreReducer(state, action);
}
