import {
    Component, ElementRef,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'ct-input',
    templateUrl: 'input.component.html',
    styleUrls: ['input.component.less']
})
export class InputComponent implements OnInit{
    @Input() form: FormGroup;
    @Input() controlName: string;
    @Input() prompt = '';
    @Input() label: string;
    @Input() valueForm;
    @Input() type = 'text';
    @Input() textInfo = '';
    @Input() disabled = false;
    @Input() textError = '';

    @ViewChild('inputText', { static: true }) inputText: ElementRef<HTMLInputElement>;
    @ViewChild('promptText', { static: true }) promptText: ElementRef<HTMLInputElement>;

    ngOnInit() {
        if (this.prompt != '') {
            const elInputText = this.inputText.nativeElement;
            const elPromptText = this.promptText.nativeElement;

            elPromptText.innerHTML = this.prompt;
            elInputText.style.paddingRight = (elPromptText.offsetWidth) + 'px';
        }
    }
}
