import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, ROUTES } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgxLetterImageAvatarModule } from 'ngx-letter-image-avatar';
import { ButtonModule } from 'src/components/button/button.module';
import { KebabMenuModule } from 'src/components/kebab-menu/kebab-menu.module';
import { SortListItemModule } from 'src/components/sort-list-item/sort-list-item.module';
import { TextButtonModule } from 'src/components/text-button/text-button.module';

import { CoreModule } from 'src/modules/core/core.module';
import { DataGridModule } from 'src/components/data-grid/data-grid.module';
import { PipesModule } from 'src/pipes/pipes.module';
import { SidebarModule } from 'src/components/sidebar/sidebar.module';
import { TooltipsModule } from 'src/components/tooltips/tooltips.module';

import { CrossButtonModule } from 'src/components/cross-button/cross-button.module';
import { LangSelectorModule } from 'src/components/lang-selector/lang-selector.module';
import { TagsListModule } from 'src/components/tags-list/tags-list.module';
import { ChevronModule } from 'src/components/chevron/chevron.module';
import { SearchResultPlaceholderModule } from 'src/components/search-result-placeholder/search-result-placeholder.module';

import { routesByRole } from 'src/navigation/routes-by-role';
import { AuthService } from 'src/modules/core/services/auth.service';
import { CommonPageComponent } from 'src/pages/common-page/common-page.component';
import { RoBalancePageComponent } from 'src/pages/ro-balance-page/ro-balance-page.component';
import { RoListPageComponent } from 'src/pages/ro-list-page/ro-list-page.component';
import { FacilitiesPageComponent } from 'src/pages/facilities-page/facilities-page.component';
import { BalancePageComponent } from 'src/pages/balance-page/balance-page.component';
import { StateMapModule } from 'src/modules/state-map/state-map.module';
import { MapPageComponent } from 'src/pages/map-page/map-page.component';
import { SidebarPageComponent } from 'src/pages/sidebar-page/sidebar-page.component';
import { BalanceExcerptPageComponent } from 'src/pages/balance-excerpt-page/balance-excerpt-page.component';
import { RoDetailsPageComponent } from 'src/pages/ro-details-page/ro-details-page.component';
import { LayoutRoComponent } from 'src/pages/layout-ro/layout-ro.component';
import { OuivReportsPageComponent } from 'src/pages/ouiv-reports-page/ouiv-reports-page.component';
import { ReportsFacadeService } from 'src/modules/core/services/facade/reports-facade.service';
import { RoReportsPageComponent } from 'src/pages/ro-reports-page/ro-reports-page.component';
import { TableNormativeActsModule } from 'src/pages/table-normative-acts-page/table-normative-acts.module';
import { DashboardPageComponent } from 'src/pages/dashboard-page/dashboard-page.component';
import { StrategyPageComponent } from 'src/pages/strategy-page/strategy-page.component';
import { OneRoReportsPageComponent } from 'src/pages/one-ro-reports-page/one-ro-reports-page.component';
import { PeriodStatusBadgeModule } from 'src/components/period-status-badge/period-status-badge.module';
import { BalanceChangeIndicatorModule } from 'src/components/balance-change-direction/balance-change-indicator.module';
import { ReportsPageComponent } from './reports-page/reports-page.component';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY2l0eWFpciIsImEiOiJja25wbjludjAwYjRtMnFuenR4YjdzbXJuIn0.qPPjh9k72mNzjs6NAG9SPQ';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DataGridModule,
        NgxMapboxGLModule.withConfig({
            accessToken: MAPBOX_ACCESS_TOKEN
        }),
        NgxLetterImageAvatarModule,
        PipesModule,
        TooltipsModule,
        SidebarModule,
        CoreModule,
        StateMapModule,
        FormsModule,
        CrossButtonModule,
        ChevronModule,
        TableNormativeActsModule,
        ReactiveFormsModule,
        LangSelectorModule,
        TagsListModule,
        PeriodStatusBadgeModule,
        ButtonModule,
        TextButtonModule,
        KebabMenuModule,
        SortListItemModule,
        SearchResultPlaceholderModule,
        DataGridModule,
        BalanceChangeIndicatorModule,
    ],
    declarations: [
        CommonPageComponent,
        LayoutRoComponent,
        RoListPageComponent,
        FacilitiesPageComponent,
        BalancePageComponent,
        MapPageComponent,
        SidebarPageComponent,
        BalanceExcerptPageComponent,
        RoDetailsPageComponent,
        RoBalancePageComponent,
        OuivReportsPageComponent,
        RoReportsPageComponent,
        DashboardPageComponent,
        StrategyPageComponent,
        OneRoReportsPageComponent,
        ReportsPageComponent,
    ],
    providers: [
        {
            provide: ROUTES,
            useFactory: configHandlerRoutes,
            deps: [AuthService],
            multi: true,
        },
        ReportsFacadeService
    ],
})
export class PagesModule {}

export function configHandlerRoutes(authService: AuthService) {
    return routesByRole(authService.getUser()?.role);
}
