import { animate, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { range } from 'src/utils';

export const ANIMATION_TIMEOUT = 200;

export const ANIMATION_TOP = [
    trigger('inUpAnimation', [
        transition(':enter', [
            style({transform: 'translate(0, 100%)'}),
            animate('0.3s ease-out', style({transform: 'translate(0, 0%)'}))
        ]),
        transition(':leave', [
            style({transform: 'translate(0, 0%)'}),
            animate('0.3s ease-in', style({transform: 'translate(0, 100%)'}))
        ])
    ])
];

export const ANIMATION_HIDE = [
    trigger('ANIMATION_HIDE', [
        transition(':leave', [
            style({opacity: 1}),
            animate('0.2s ease-in', style({opacity: 0}))
        ]),
    ])
];

export const ANIMATION_OPACITY = [
    trigger('inOutAnimation', [
        transition(':enter', [
            style({opacity: 0}),
            animate('0.2s ease-out', style({opacity: 1}))
        ]),
        transition(':leave', [
            style({opacity: 1}),
            animate('0.2s ease-in', style({opacity: 0}))
        ])
    ])
];

export const REPORT_YEAR = moment().get('year') - 1;
export const YEARS_FUTURE = range(2010, 2035);
export const YEARS_FOR_FILTER = range(REPORT_YEAR - 5, REPORT_YEAR).reverse();
