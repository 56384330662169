<div class="ro_menu">
    <a [routerLink]="'/' + PAGES.ro" class="ro_menu__back">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
            <path d="M0.293 6.70675L5.293 11.7068C5.684 12.0978 6.316 12.0978 6.707 11.7068C7.098 11.3158 7.098 10.6838 6.707 10.2928L3.414 6.99975L15 6.99975C15.553 6.99975 16 6.55175 16 5.99975C16 5.44775 15.553 4.99975 15 4.99975L3.414 4.99975L6.707 1.70675C7.098 1.31575 7.098 0.68375 6.707 0.29275C6.512 0.0977507 6.256 -0.000249863 6 -0.000249863C5.744 -0.000249863 5.488 0.0977507 5.293 0.29275L0.293 5.29275C-0.0979996 5.68375 -0.0979996 6.31575 0.293 6.70675Z" fill="#505F79"/>
        </svg>
    </a>

    <div class="ro_menu__content">
        <div class="ro_menu__title">
            <span class="ro_menu__id">ID:{{organization?.id}}</span>
            <span class="ro_menu__title_text">{{organization?.name}}</span>
        </div>

        <div class="ro_menu__tabs">
            <div *ngFor="let tab of tabs"
                caTooltip
                caTooltipPlacement="top"
                [caTooltipTemplate]="tab.isDisabled ? dummyButton : null"
            >
                <div class="ro_menu__tab"
                    [class.ro_menu__tab-disabled]="tab.isDisabled"
                    [routerLink]="tab.id"
                    routerLinkActive="ro_menu__tab-active"
                >
                    {{tab.title}}
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #dummyButton>
    <ca-tooltip [text]="TEXT.uoivBalance.dummyButton"></ca-tooltip>
</ng-template>
