import { Routes } from '@angular/router';

import { ROLE } from 'src/namespace';
import { roleGovermentRoutes } from './role.goverment.routes';


// it just copies routes from the government role
export const roleExecutiveRoutes: Routes = roleGovermentRoutes.map((routeConfig) => ({
    ...routeConfig,
    data: routeConfig.data ? {
        allowed_roles: [ROLE.executive]
    } : null
}));
