<div class="tooltip__wrapper"
    *ngIf="tooltipsService.position"
    [style.top.px]="position.y"
    [style.left.px]="position.x"
>
    <div class="tooltip tooltip--{{tooltipsService.placement}}"
        [class.tooltip--floating]="tooltipsService.isFloating"
    >
        <ng-content></ng-content>
    </div>
</div>
