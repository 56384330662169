import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';

import { TEXT } from 'src/locales/text';
import { ROSummaryReport, Organization } from 'src/store/models';

@Component({
    selector: 'organizations-list',
    templateUrl: 'organizations-list.component.html',
    styleUrls: ['organizations-list.component.less']
})
export class OrganizationsListComponent implements OnChanges {
    @Input() organizations: ROSummaryReport[];
    @Input() selectedOrganizations: ROSummaryReport[];

    @Output() add = new EventEmitter<number>();
    @Output() remove = new EventEmitter<number>();
    @Output() selectAll = new EventEmitter<boolean>();

    TEXT = TEXT;

    isScrolled = false;

    allSelected: boolean;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.organizations || changes.selectedOrganizations) {
            this.allSelected = this.organizations.length === this.selectedOrganizations.length;
        }
    }

    selectAllItems() {
        this.allSelected = !this.allSelected;
        this.selectAll.emit(this.allSelected);
    }

    selectItem(id: number, state: boolean) {
        this.allSelected = false;

        if (state) {
            this.add.emit(id);
        } else {
            this.remove.emit(id);
        }
    }

    byName(org: Organization) {
        return org.name;
    }
}
