import { EMISSIONS, STATUS_NAMES, TABLE_COLUMNS_NAME } from 'src/namespace';
import { SectorSlugEnum } from 'src/store/models';

export const UNITS = {
    ton: {
        zero: 'тонн',
        one: 'тонна',
        two: 'тонны',
        few: 'тонны',
        many: 'тонн',
        other: 'тонны'
    }
};

export const table: {
    [key in TABLE_COLUMNS_NAME]: string
} = {
    id: 'ID',
    roId: 'roId',
    num: '№',
    year: 'Период',
    emission: `Тонн&nbsp;CO<sub>2</sub>&nbsp;экв.`,
    emissions: `Tons of&nbsp;CO<sub>2</sub>&nbsp;equiv`,
    status: 'Статус',
    created_at: `Приказ&nbsp;от`,
    sent_at: `Дата&nbsp;подачи`,
    approved_at: `Дата&nbsp;согласования`,
    facility: 'Объект',
    address: 'Адрес',
    sender: 'Загружено',
    sendReport: `Подать отчёт`,
    blame: 'Обработано',
    comments: 'Комментарий',
    name: 'Название',

    sector: 'Сектор',
    emissionMass: 'Масса выбросов, тонн',
    organizationName: 'Наименование организации',
    targetEmissionMass: 'Целевой поакзатель, тонн',
    quota: 'Квота, тонн',
    balance: 'Баланс, тонн',
    bill: 'Счёт УЕ',
    carbonCapacity: 'Углеродоемкость',
    source: 'Углеродоемкость, источники',
}

export const common = {
    emission: 'Эмиссия',
    absorption: 'Поглощение',
    noData: 'Нет данных',
};

export const form = {
    title: 'Отчёт',
    object: 'Объект',
    emission: 'Эмиссия',
    send: 'Отправить отчёт',
    sendCorrect: 'Отправить скорректированный отчёт',
    errorEmission: 'Неверное значение'
}

export const status = {
    [STATUS_NAMES.all]: 'Все',
    [STATUS_NAMES.rework]: 'На доработку',
    [STATUS_NAMES.awaiting]: 'На согласовании',
    [STATUS_NAMES.approved]: 'Согласовано'
}

export const statusCoord: {[key in STATUS_NAMES]: string} = {
    ...status,
    [STATUS_NAMES.empty]: 'Не подали отчёт'
}

export const statusManager: {[key in STATUS_NAMES]: string} = {
    ...status,
    [STATUS_NAMES.empty]: 'Отчёт не подан'
}

export const pageNames = {
    reports: 'Отчётность',
    normative: 'Нормативные акты',
    map: 'Карта',
    ro: 'Список РО',
    balance: 'Баланс',
    facilities: 'Объекты',
    dashboard: 'Дашборд',
    strategy: 'Стратегия',
}

export const menuBalance = {
    map: 'Карта',
    table: 'Баланс'
};

export const balancePage = {
    calculations: 'Расчёт'
};

export const cardType = {
    sources: 'Эмиссия',
    regulatedSources: 'РО',
    absorbers: 'Поглотители ПГ',
    climateProjects: 'Климатические проекты'
};

export const cardName = {
    sources: 'Все прочие источники',
    regulatedSources: 'Все регулируемые организации',
    absorbers: 'Все поглотители ПГ',
    climateProjects: 'Все КП'
};

export const cardCategory = {
    inventory: 'Кадастр',
    regulation: 'Регулирование'
};

export const header = {
    balance: `Баланс&nbsp;65`,
    askAQuestion: `Задать&nbsp;вопрос`,
    logOff: 'Выйти',
    periodInWork: 'Период в работе',
    createReport: 'Создать отчёт',
    submitReport: 'Подать отчёт',
}

export const messageReportSend = {
    [STATUS_NAMES.approved]: 'согласован',
    [STATUS_NAMES.rework]: 'отправлен на доработку',
    [STATUS_NAMES.awaiting]: 'отправлен на согласование',
    [STATUS_NAMES.empty]: 'отозван'
}

export const others = {
    widgetUnit: 'тонн CO<sub>2</sub>&nbsp;экв',
    balance: 'Баланс',
    revoke: 'Отозвать',
    change: 'Изменить',
    tg: 'тонн',
    year: 'год',
    edit: 'Редактировать',
    emissionReport: 'Отчёт об эмиссии',
    reportingPeriod: 'Отчётный период',
    commentaryNature: 'Комментарий мин.природы',
    clears: 'Очистить',
    searchManager: 'Искать объект',
    searchCoord: 'Искать объект, юридическое лицо',
    searchName: 'Искать по названию',
    searchDoc: 'Искать документ',
    sendForRevision: 'Отправить на доработку',
    toApprove: 'Согласовать',
    reportWillRequireRevision: 'Если отчёт потребует доработок, укажите причину. Так будет проще разобраться. Спасибо!',
    regionAll: 'Весь Сахалин',
    emissionsAll: 'Все',
    tonsYear: 'тонн/год',
    messageReportSendError: 'Произошла ошибка',
    messageReportSendStart: 'Отчёт об эмиссии источника ',
    messageReportSendErrorEnd: 'при отправке отчёта об эмиссии источника ',
    messageReportSendErrorDefault: ' при отправке данных',
    nothing: 'К сожалению, ничего',
    weCantSeemToFind: `Похоже, мы&nbsp;не&nbsp;можем найти никаких результатов по&nbsp;вашему запросу`,
    mobileVersion: `Мобильная версия недоступна`,
    openTheSiteOn: `Откройте сайт на&nbsp;ноутбуке или на&nbsp;настольном компьютере`,
    copyright: `Сахалинская область, Сахалинский климатический центр СахГУ, разработано CityAir`,
    copyright1: [`Сахалинская область, `, 'https://sakhalin.gov.ru/'],
    copyright2: ['Сахалинский климатический центр СахГУ', 'http://sakhgu.ru/'],
    copyright3: [`Разработано CityAir`, 'https://cityair.io/'],
    all: 'всё',
    collapse: 'свернуть',
    expand: 'ещё',
    documentation: 'Документы',
    search: 'Искать',
    notSelected: 'Все',
    notSelectedReport: 'Не выбрано',
    addOrganization: 'Добавить организацию',
    weDidNotFind: 'С данными параметрами ничего не нашли',
    weAreAlsoAwaitingReceipt: 'Мы также в ожидании поступления данных, но по какой-то причине они еще не пришли...',
}

export const listPageTitle = {
    emissions: 'Источники ПГ',
    regions: 'География',
    organizations: 'Источники ПГ',
}

export const loginPage = {
    carbonBalance: 'Углеродный баланс Сахалинской области',
    login: 'Логин',
    pass: 'Пароль',
    sign: 'Войти',
    loginProblems: 'Проблемы с входом?',
    devCityAir: 'Разработано CityAir',
    wrong: 'Неправильный логин или пароль',
    developedByCityAir: 'Разработано CityAir',
    ministryOfEcology: 'Министерство экологии Сахалинской области'
}

export const emissionsList = [
    {name: 'Энергетика', id: EMISSIONS.emission_reports, children: []},
    {
        name: 'Промышленные процессы и использование продукции', id: null,
        children: [
            {name: 'Производство продукции из минерального сырья', id: null},
            {name: 'Химическая промышленность', id: null},
            {name: 'Металлургическая промышленность', id: null},
            {name: 'Неэнергетическое использование топлив и использование растворителей', id: null},
            {name: 'Электронная промышленность', id: null},
            {name: 'Использование продукции как заменителей озоноразрушающих веществ', id: null},
            {name: 'Производство и использование другой продукции', id: null},
        ]
    },

    {name: 'Сельское хозяйство', id: null, children: []},
    {name: 'ЗИЗЛХ', id: null, children: []},
    {name: 'Отходы', id: null, children: []},
    {name: 'Прочее', id: EMISSIONS.other_emission, children: []},
];

export const uoivBalance = {
    kiloCO2Equiv: 'тыс.&nbsp;т<br/>CO<sub>2</sub>-экв.',
    isPlanned: 'План',
    isFactual: 'Факт',
    dummyButton: 'В разработке',
    planJustification: 'Обоснование плана',
    edit: 'Редактировать',
    balance: 'Баланс',
    summaryTitles: ['Поглощение', 'Выбросы РО', 'Фактические выбросы'],
    summaryTableTitles: [
        'Поглотители',
        'Эмиссия. Регулируемые организации',
        'Эмиссия',
        'Поглотители. Климатические проекты'
    ],
    tableColumns: {
        name: 'Название',
        emission: 'Выбросы, тонн',
        absorption: 'Поглощение, тонн'
    },
    regulatedSequestrationLabels: {
        ghg_storage: 'Улавливание и хранение CO<sub>2</sub>',
        reforestation: 'Лесовосстановление и лесоразведение',
        wildfire_management: 'Борьба и профилактика лесных пожаров',
        pest_control: 'Борьба и профилактика болезней леса'
    },
    regulatedEmissionLabels: {
        emission_ro: 'Регулируемые организации',
        regional_program_emissions: 'Региональная программа',
        climate_projects_emissions: 'Климатические проекты',
    },
};

export const periodStatus = {
    opened: 'Период в работе',
    closed: 'Период закрыт',
    planned: 'План',
};

export const ORGANIZATIONS = {
    zero: 'организаций',
    one: 'организация',
    two: 'организации',
    few: 'организации',
    many: 'организаций',
    other: 'организации'
};

export const FACILITIES = {
    zero: 'объектов',
    one: 'объект',
    two: 'объекта',
    few: 'объекта',
    many: 'объектов',
    other: 'объектов'
};

export const PAGE_TITLES = {
    regulatedOrganizations: 'Регулируемые организации',
    facilities: 'Объекты, содержащие источники выбросов ПГ'
};

export const ORGANIZATION_TYPE_NAMES: {
    [name in SectorSlugEnum]: string
} = {
    [SectorSlugEnum.ENERGY]: 'Энергетика',
    [SectorSlugEnum.INDUSTRY]: 'Промышленные процессы и использование продукции',
    [SectorSlugEnum.ARGICULTURE]: 'Сельское хозяйство',
    [SectorSlugEnum.LAND_AND_FORESTRY]: 'Землепользование, изменения землепользования и лесное хозяйство',
    [SectorSlugEnum.WASTE]: 'Отходы',
    [SectorSlugEnum.OTHER]: 'Прочее'
};

export const ORGANIZATION_TYPE_NAMES_SHORT: {
    [name in SectorSlugEnum]: string
} = {
    [SectorSlugEnum.ENERGY]: 'Энергетика',
    [SectorSlugEnum.INDUSTRY]: 'Пром. процессы и использование продукции',
    [SectorSlugEnum.ARGICULTURE]: 'Сельское хозяйство',
    [SectorSlugEnum.LAND_AND_FORESTRY]: 'ЗИЗЛХ',
    [SectorSlugEnum.WASTE]: 'Отходы',
    [SectorSlugEnum.OTHER]: 'Прочее'
};

export const BALANCE_RO = {
    quota: ['Квота', 'К'],
    facilities: ['Итого выбросов', 'Э'],
    carbonUnits: ['Углеродные Единицы', 'УЕ'],
    balance: ['Баланс', 'Б'],
    plan: 'План',
    awaiting: 'На согласовании',
};

export const RO_DETAILS_PAGE = {
    title: 'Общая информация',
    details: {
        organizationName: 'Название организации',
        sources: 'Источников эмиссий',
        carbonCapacity: 'Ситуация с углеродоемкостью источников',
        legalAddress: 'Юридический адрес',
        postalAddress: 'Фактический и почтовый',
        inn: 'ИНН',
        ogrn: 'ОГРН',
        phoneNumber: 'Телефон',
        email: 'Email',
    },
    contactsTitle: 'Контактная информация',
    contacts: {
        fullName: 'ФИО',
        position: 'Должность',
        phoneNumber: 'Телефон',
        email: 'Email',
    },
};

export const LIST_OM = {
    uncheckAll: '__uncheck_all_not_translated__',
    checkAll: '__check_all_not_translated__',
}

export const COMMON = {
    save: 'сохранить',
    cancel: 'отмена',
}

export const OTHER = {
    noSelect: '__no_select_not_translated',
}

export const DASHBOARD_WIDGET = {
    quote: 'Квота',
    emission: 'Выбросы',
    boughtUnits: 'Куплено УЕ',
    soldUnits: 'Продано УЕ',
    measureUnit: 'УЕ',
    titleDynamic: 'Динамика работы <br>с отчётами за ',
    title2tp: 'Отчёты по форме <br> 2-ТП (воздух)',
    titlePg: 'Отчёты <br> о выбросах ПГ',
    titleKp: 'Отчёты <br> о реализации КП',
    titleUnits: 'Отчёты о покупке <br> Углеродных Единиц',
}

export const STRATEGY = {
    title: 'Стратегия Сахалинской области  на период до 2025 года с перспективой до 2035 года',
    nameDoc: 'Стратегия Сахалинской области  на период до 2025 года с перспективой до 2035 года.pdf',
    updated: 'Обновлено',
    subTitle: 'Слово Губернатора Сахалинской области В.И.Лимаренко',
    valeryLimarenko: 'Валерий Лимаренко',
    governorOfTheSakhalinRegion: 'Губернатор Сахалинской области',
}

export const ERROR_POPUP = {
    alasWeCouldNot: 'Ой! Произошла ошибка на сервере',
    tryRefreshingThePage: 'Попробуйте обновить страницу.',
    didNotHelp: 'Не помогло? ',
    writeToUs: 'Напишите нам',
}
