import { Identifiable } from 'src/libs/models';

export type SortDirection = -1 | 0 | 1;

export type GridSortingColumns<T> = {
    /**
     * List of supporters pipes you can see in the
     * DataGridComponent providers sections
     */
    format?: string;
    key?: any;
    title: string;
    predicate?: (d: T) => string | number | boolean;
    direction?: SortDirection;
    sort?: boolean;
    align?:  '' | 'left' | 'right' | 'center';
};

export class Sorter<T extends Identifiable> {

    constructor(columns: GridSortingColumns<T>[]) {
        this.columns = columns.map(c => ({
                ...c,
                direction: c.direction ? c.direction : 0,
                sort: c.sort !== false,
            }));
    }

    public columns: GridSortingColumns<T>[] = [];

    getPipe(column) {
        if (column?.pipe) {
            return column.pipe;
        }
        return null;
    }

    selectedColumn: GridSortingColumns<T> = {
        title: '',
        key: null,
        predicate: null,
        direction: 0
    };

    getPredicate(): (i: T) => any {
        if (this.selectedColumn.predicate) {
            return this.selectedColumn.predicate;
        }

        return (i) => i[this.selectedColumn.key];
    }


    sortBy(column: GridSortingColumns<T>) {
        this.columns.map(sc => {
            if (sc.key != column.key) {
                sc.direction = 0;
            }
        });
        column.direction = Sorter.changeDirection(
            this.selectedColumn === column ? column.direction : 0,
        );
        this.selectedColumn = column;
        return column;
    }

    private static changeDirection(direction: SortDirection) {
        return direction === 0 ? 1 : -direction as SortDirection;
    }

    trackById(_: number, item: T) {
        return item.id;
    }

}
