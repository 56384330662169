import { Injectable, TemplateRef, ElementRef } from '@angular/core';

export interface ITooltipComponent {
    text?: string | string[];
}

export type TooltipPlacement = 'left' | 'right' | 'top' | 'left-top' | 'right-top' | 'top-left' | 'top-right';

const POINTER_POSITION = {
    x: 19,
    y: 16
};

@Injectable({
    providedIn: 'root'
})
export class TooltipsService {
    currentTarget: ElementRef;

    template: TemplateRef<ITooltipComponent>;

    placement: TooltipPlacement = 'right';

    position: {
        x: number;
        y: number;
    };

    isFloating: boolean;

    show(
        target: ElementRef,
        template: TemplateRef<ITooltipComponent>,
        placement: TooltipPlacement
    ) {
        this.currentTarget = target;
        this.template = template;
        this.placement = placement;

        const rect = target.nativeElement.getBoundingClientRect();

        if (placement === 'right') {
            this.position = {
                x: rect.right,
                y: rect.top + rect.height / 2
            };
        }

        if (placement === 'left') {
            this.position = {
                x: rect.left,
                y: rect.top + rect.height / 2
            };
        }

        if (placement === 'right-top') {
            this.position = {
                x: rect.right,
                y: rect.top
            };
        }

        if (placement === 'left-top') {
            this.position = {
                x: rect.left,
                y: rect.top
            };
        }

        if (placement === 'top-left') {
            this.position = {
                x: rect.left,
                y: rect.top
            };
        }

        if (placement === 'top-right') {
            this.position = {
                x: rect.right,
                y: rect.top
            };
        }

        if (placement === 'top') {
            this.position = {
                x: rect.right - rect.width / 2,
                y: rect.top
            };
        }

        if (placement.startsWith('top-')) {
            const horizontalOffset = POINTER_POSITION.x - rect.width / 2;

            if (horizontalOffset > 0) {
                if (placement === 'top-right') {
                    this.position.x += horizontalOffset;
                }

                if (placement === 'top-left') {
                    this.position.x -= horizontalOffset;
                }
            }
        }

        if (placement.endsWith('-top')) {
            const verticalOffset = POINTER_POSITION.y - rect.height / 2;

            if (verticalOffset > 0) {
                this.position.y -= verticalOffset;
            }
        }
    }

    clearBy(target: ElementRef) {
        if (target === this.currentTarget) {
            this.clear();
        }
    }

    clear() {
        this.template = null;
        this.position = null;
    }

    setFloating(isFloating: boolean) {
        this.isFloating = isFloating;
    }
}
