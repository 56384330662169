import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { throwError, ObservableInput } from 'rxjs';
import jwt_decode from 'jwt-decode';

import { User } from 'src/namespace';
import { onUserLoggedIn } from '../../../store/actions';
import { ProviderApi } from './api/provider';
import { environment } from '../../../environments/environment';

type TokenData = {
    access: string;
    refresh: string;
};

type AuthData = TokenData & { user: User };

const USER_KEY = 'user';
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'token';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private user: User = null;
    private accessToken: string;
    private refreshToken: string;
    private userKey: string;

    constructor(private providerApi: ProviderApi, private store: Store, private errorHandler: ErrorHandler) {}

    async login(login: string, pwd: string) {
        const authData = await this.auth(login, pwd);

        if (authData?.user) {
            this.setAccessToken(authData.access);
            this.setRefreshToken(authData.refresh);
            this.setUser(authData.user);
        } else {
            this.removeAccessToken();
            this.removeRefreshToken();
        }

        return !!authData?.user;
    }

    setUserKey(key: string) {
        this.userKey = key;
    }

    getUserKey(): string {
        const res = this.userKey || USER_KEY;
        return res;
    }

    logout() {
        this.setUser(null);
    }

    isLoggedIn() {
        return !!(this.getUser() && this.getRefreshToken());
    }

    getUser() {
        return this.user || (this.user = this.getSavedUser());
    }

    setUser(user: User) {
        this.user = user;
        localStorage.setItem(this.getUserKey(), JSON.stringify(user));
        this.store.dispatch(onUserLoggedIn());
    }

    private getSavedUser() {
        let user: User;

        try {
            user = JSON.parse(localStorage.getItem(this.getUserKey()));
        } catch (err) {
            this.errorHandler.handleError(err);
        }

        return user && user.id && user.role ? user : null;
    }

    getAccessToken() {
        return this.accessToken || (this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY));
    }

    setAccessToken(accessToken: string) {
        this.accessToken = accessToken;
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    }

    removeAccessToken() {
        this.accessToken = '';
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    }

    getRefreshToken() {
        return this.refreshToken || (this.refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY));
    }

    private setRefreshToken(refreshToken: string) {
        this.refreshToken = refreshToken;
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    }

    removeRefreshToken() {
        this.refreshToken = '';
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    private getJWT() {
        const idToken = this.getAccessToken();
        return idToken ? jwt_decode<{ exp: number }>(idToken) : { exp: 0 };
    }

    isTokenExpired() {
        return new Date(this.getJWT().exp * 1000).getTime() < Date.now();
    }

    async updateToken() {
        const data: TokenData = await this.providerApi.post(
            environment.url_auth + 'refresh/',
            {
                refresh: this.getRefreshToken()
            },
            async () => {},
            (error: HttpErrorResponse) => this.gotoLogin(error)
        );

        this.setAccessToken(data.access);
    }

    async auth(username: string, password: string): Promise<AuthData> {
        try {
            const data: AuthData = await this.providerApi.post(
                environment.url_auth,
                {
                    username,
                    password
                },
                async () => {
                },
                (error: HttpErrorResponse): ObservableInput<any> => throwError(error)
            );

            return data;
        } catch (err) {
            this.errorHandler.handleError(err);
            return null;
        }
    }

    gotoLogin(error?: HttpErrorResponse) {
        this.removeRefreshToken();
        const err = throwError(error);
        location.reload();

        return err;
    }
}
