import { LngLatBoundsLike } from 'mapbox-gl';

export const MAP_SETTINGS = {
    zoom: 5,
    minzoom: 5,
    maxzoom: 22,
    center: {
        lng: 138.812,
        lat: 50.893
    },
    bounds: [
        {
            lng: 108.872,
            lat: 42
        },
        {
            lng: 167.752,
            lat: 55.4
        }
    ] as LngLatBoundsLike
};
