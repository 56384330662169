import { Component, Input, OnInit } from '@angular/core';
import { STATUS_NAMES } from 'src/namespace';

@Component({
    selector: 'reports-status',
    templateUrl: './reports-status.component.html',
    styleUrls: ['./reports-status.component.less']
})
export class ReportsStatusComponent implements OnInit {
    @Input() arrOfStatuses: { [key in STATUS_NAMES]: string };
    @Input() awaitingSum: number;
    @Input() emptySum: number;
    @Input() approvedSum: number;
    @Input() reworkSum: number;
    @Input() isVertical = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
