import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ChartPoint } from 'chart.js';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { REPORT_YEAR } from 'src/config';
import { TEXT } from 'src/locales/text';
import { TabModel, ROLE } from 'src/namespace';
import { cast } from 'src/utils';
import { BALANCE_CHART_TYPE } from 'src/modules/core/components/balance-chart/balance-chart.settings';
import { Fadaster } from 'src/store/models';
import { FadasterService } from 'src/store/services/fadaster.service';
import { AuthService } from 'src/modules/core/services/auth.service';
import { LoaderService } from 'src/modules/core/services/loader.service';
import { AbsorberSequestrationService } from 'src/store/services/absorber-sequestration.service';
import { BalanceViewModel } from 'src/pages/balance-page/view-model';
import { SectorEmissionFadasterService } from 'src/store/services/sector-emission-fadaster.service';
import { ChangeDirectionType } from 'src/components/balance-change-direction/balance-change-indicator.component';

enum SummaryType {
    ABSORPTION,
    EMISSIONS_RO,
    EMISSIONS_NRO,
    CLIMATE_PROJECTS,
}

const CHART_CONFIG = {
    [BALANCE_CHART_TYPE.TOTAL_SEQUESTRATION]: 'total_sequestration',
    [BALANCE_CHART_TYPE.NO_ACTION_SEQUESTRATION]: 'no_action_sequestration',
    [BALANCE_CHART_TYPE.TOTAL_EMISSIONS]: 'total_emission',
    [BALANCE_CHART_TYPE.NO_ACTION_EMISSION]: 'no_action_emission',
};

const HEADER_UI_CONFIG = [
    {
        type: SummaryType.ABSORPTION,
        key: 'total_sequestration'
    },
    {
        type: SummaryType.EMISSIONS_NRO,
        key: 'total_emission'
    },
    {
        type: SummaryType.EMISSIONS_RO,
        key: 'emission_ro'
    },
];

interface ChartData {
    data: ChartPoint[];
    options: {
        [key: string]: any;
    };
}

@Component({
    selector: 'balance-page',
    templateUrl: 'balance-page.component.html',
    styleUrls: ['balance-page.component.less'],
})
export class BalancePageComponent implements OnInit {
    TEXT = TEXT;
    chartData: ChartData[];

    yearsList: number[];
    selectedYear = REPORT_YEAR;
    selectedYear$ = new BehaviorSubject(this.selectedYear);

    fadaster: Fadaster[];
    selectedFadaster = new Fadaster();
    previousFadaster = new Fadaster();
    balanceChangeDirection: ChangeDirectionType = 'unknown';

    headerUiConfig = HEADER_UI_CONFIG;
    REPORT_YEAR = REPORT_YEAR;

    data: BalanceViewModel;

    tabs: TabModel[] = [
        {
            id: 'inventory',
            title: TEXT.cardCategory.inventory
        },
        {
            id: 'regulation',
            title: TEXT.cardCategory.regulation
        }
    ];

    activeTab: TabModel;

    canEdit = false;

    constructor(
        private fadasterService: FadasterService,
        private authService: AuthService,
        private viewContainerRef: ViewContainerRef,
        private loaderService: LoaderService,
        private absSeqService: AbsorberSequestrationService,
        private secEmFadasterSrv: SectorEmissionFadasterService,
    ) {

        loaderService.waitLoadingComplete(
            viewContainerRef,
            [this.fadasterService, this.absSeqService, this.secEmFadasterSrv]
        );

        fadasterService.entities$.subscribe(data => this.onFadastersUpdated(data as Fadaster[]));

        combineLatest([
            absSeqService.entities$,
            secEmFadasterSrv.entities$,
            fadasterService.entities$,
            this.selectedYear$.asObservable(),
        ]).pipe(
            map((params) => new BalanceViewModel(...params))
        ).subscribe((data: BalanceViewModel) => this.data = data);

        const { role } = this.authService.getUser();
        this.canEdit = role === ROLE.executive;
    }

    ngOnInit() {
        this.fadasterService.getAll();
        this.absSeqService.getWithQuery({year: this.selectedYear.toString()});
        this.secEmFadasterSrv.getWithQuery({year: this.selectedYear.toString()});
        this.selectTab(this.tabs[0]);
        this.selectedYear$.next(this.selectedYear);
    }

    selectTab(tab: TabModel) {
        this.activeTab = tab;
    }

    changeYear(year: number) {
        this.selectedYear = year;
        this.selectedYear$.next(year);
        this.updateSelectedFadaster();

        const params = {year: year.toString()};
        this.absSeqService.getWithQuery(params);
        this.secEmFadasterSrv.getWithQuery(params);
    }

    updateSelectedFadaster() {
        if ( !this.selectedYear || ! (this.fadaster.length > 0)) {
            return;
        }

        const result = this.fadaster.find(f => f.year === this.selectedYear);
        this.selectedFadaster = result ? cast(Fadaster, result) : this.selectedFadaster;

        const prev = this.fadaster.find(f => f.year === this.selectedYear - 1);
        this.previousFadaster = prev ? cast(Fadaster, prev) : new Fadaster();
    }

    onFadastersUpdated(data: Fadaster[]) {
        const buildChart = (type: string, data: ChartPoint[]) => ({ data, options: { type } });
        const chartData: ChartData[] = new Array(Object.keys(CHART_CONFIG).length);

        Object.keys(CHART_CONFIG).forEach(key => {
            const lineDataForKey = data.map(f => Fadaster.toPoint(f, CHART_CONFIG[key]));
            chartData[key] = buildChart(key, lineDataForKey);
        });

        this.yearsList = data.map(f => f.year).reverse();
        this.chartData = chartData;
        this.fadaster = data;
        this.updateSelectedFadaster();
    }
}
