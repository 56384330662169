import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TEXT } from 'src/locales/text';
import { Region } from 'src/namespace';

@Component({
    selector: 'popup-subdivision',
    templateUrl: 'popup-subdivision.component.html',
    styleUrls: ['popup-subdivision.component.less']
})
export class PopupSubdivisionComponent {
    @Input() region: Region;

    @Input() emission: number;

    @Output() closeAction = new EventEmitter<void>();

    TEXT = TEXT;
}
