import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { PAGES } from '../../../../navigation/navigation-config';
import { detectMobile } from '../../../../utils';

@Injectable({
    providedIn: 'root'
})
export class MobileGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const isMobile = detectMobile();
        const isMobilePage = route.routeConfig.path === PAGES.mobile;

        if (!isMobile && isMobilePage) {
            return this.router.parseUrl('');
        }

        if (isMobile && !isMobilePage) {
            return this.router.parseUrl(PAGES.mobile);
        }

        return true;
    }
}
