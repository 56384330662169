import { Component, Input } from '@angular/core';

@Component({
    selector: 'ct-sort-list-item',
    templateUrl: './sort-list-item.component.html',
    styleUrls: ['./sort-list-item.component.less']
})
export class SortListItemComponent {
    @Input() sortingDirection = 0;
    @Input() text = '';
    @Input() align: '' | 'left' | 'right' | 'center' = '';
}
