<div class="emissions__item no_highlight_touch"
     (click)="selectedEmissions.length !== 0 ? removeAll.emit() : ''">
    <ca-checkbox
            [checked]="selectedEmissions.length === 0"
    ></ca-checkbox>
    <div class="emissions__item_text">{{TEXT.others.emissionsAll}}</div>
</div>


<div *ngFor="let f of TEXT.emissionsList; let i = index;">
    <div class="emissions__item no_highlight_touch"
         (click)="f.id && (selectedEmissions.includes(f.id) ? remove.emit(f.id) : add.emit(f.id))"
    >
        <ca-checkbox
                [checked]="selectedEmissions.includes(f.id)"
                [disabled]="!f.id"
        ></ca-checkbox>
        <div [ngClass]="{'emissions__item_text': true, 'emissions__item_text-disabled': !f.id}">{{f.name}}</div>
    </div>

    <div *ngFor="let f2 of f.children"
         class="emissions__item emissions__item-2 no_highlight_touch">
        <ca-checkbox
                [checked]="selectedEmissions.includes(f2.id)"
                [disabled]="true"
        ></ca-checkbox>
        <div [ngClass]="{'emissions__item_text emissions__item_text-sub': true, 'emissions__item_text-disabled': true}">{{f2.name}}</div>
    </div>

</div>