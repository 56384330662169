import { Component } from '@angular/core';
import { ReportsFacadeService } from 'src/modules/core/services/facade/reports-facade.service';
import { POPUPS, ROLE } from 'src/namespace';
import { TEXT } from 'src/locales/text';
import { ANIMATION_HIDE, ANIMATION_TOP } from 'src/config';
import { RO_MENU_ITEMS } from 'src/navigation/navigation-config';
import { AuthService } from 'src/modules/core/services/auth.service';

@Component({
    selector: 'ouiv-reports',
    templateUrl: './ouiv-reports-page.component.html',
    styleUrls: ['../../styles/reports.less'],
    animations: [ANIMATION_TOP, ANIMATION_HIDE]
})
export class OuivReportsPageComponent {
    TEXT = TEXT;
    POPUPS = POPUPS;
    RO_MENU_ITEMS = RO_MENU_ITEMS;

    canEdit = false;

    constructor(public facade: ReportsFacadeService, authService: AuthService) {
        const { role } = authService.getUser();
        this.canEdit = role === ROLE.executive;

        facade.showLoader();
        facade.mainStart();
        facade.getFacilities();

    }
}
