import { locale } from 'moment';
import 'moment/locale/ru';

import * as text_ru from './text_ru';
import * as text_en from './text_en';
import { swapArgsFirstWithSecond } from 'src/utils';

const lang = localStorage.getItem('lang') || 'ru';
export let isRU = !!~(lang || navigator.language).indexOf('ru');

export const TEXT = isRU ? text_ru : text_en;

export const setLangRu = () => isRU = true;

export const LANGUAGE = isRU ? 'ru' : 'en';

export const LANGUAGE2 = isRU ? 'ru-RU' : 'en-US';

export function getLocalized(fn: (a: any, locale: string) => string) {
    return swapArgsFirstWithSecond(fn).bind(null, LANGUAGE);
}

locale(LANGUAGE);
