import { Component, Input, OnInit } from '@angular/core';

import { OrgType } from '../../organization-type';
import { Sortable, SortingColumn } from '../../directives/sortable/sortable.directive';
import { TEXT } from 'src/locales/text';
import { TABLE_COLUMNS_NAME } from 'src/namespace';
import { Facility, FacilityYearlyViewModel } from 'src/store/models';
import { EXECUTIVE_PAGES } from 'src/navigation/navigation-config';

export type FacilityType = {
    facilityId: string;
    name: string;
    address: string;
    organization: string;
    organizationTypes: OrgType[];
    emission: number;
    targetEmission: number;
    carbonCapacity: number[];
};

@Component({
    selector: 'facilities-table',
    templateUrl: 'facilities-table.component.html',
    styleUrls: [
        '../../../../styles/table-common.less',
        'facilities-table.component.less',
    ],
})
export class FacilitiesTableComponent extends Sortable<FacilityYearlyViewModel> implements OnInit {
    @Input() facilities: FacilityYearlyViewModel[] = [];
    @Input() hideOrganization = false;

    columns: SortingColumn<FacilityYearlyViewModel>[];

    columns1: SortingColumn<FacilityYearlyViewModel>[] = [
        {
            direction: 0,
            predicate: (d: Facility) => d.id,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.id]
            }
        },
        {
            direction: 0,
            predicate: (d: Facility) => d.name,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.name]
            }
        },
        {
            direction: 0,
            predicate: null,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.address]
            }
        },
    ];

    columns2: SortingColumn<FacilityYearlyViewModel>[] = [
        {
            // can hide
            direction: 0,
            predicate: null,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.organizationName]
            }
        },
    ];

    columns3: SortingColumn<FacilityYearlyViewModel>[] = [
        {
            direction: 0,
            predicate: null,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.sector],
                alignment: 'center'
            }
        },
        {
            direction: 0,
            predicate: (f: FacilityYearlyViewModel) => f?.emission,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.emissionMass],
                alignment: 'right'
            }
        },
        {
            direction: 0,
            predicate: null,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.carbonCapacity],
                alignment: 'center'
            }
        }
    ];

    TEXT = TEXT;
    EXECUTIVE_PAGES = EXECUTIVE_PAGES;

    ngOnInit() {
        if (this.hideOrganization) {
            this.columns = [...this.columns1, ...this.columns3]
        } else {
            this.columns = [...this.columns1, ...this.columns2, ...this.columns3];
        }
    }

    trackById(_: number, item: FacilityType) {
        return item.facilityId;
    }
}
