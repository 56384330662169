import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import {
    filter,
    pairwise,
    take
} from 'rxjs/operators';
import { LoaderComponent } from '../components/loader/loader.component';
import { Observable } from 'rxjs';
import { EntityCollectionServiceBase } from '@ngrx/data';


@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    }

    waitLoadingComplete(viewContainerRef: ViewContainerRef, observables: EntityCollectionServiceBase<any>[]) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
        const loader = viewContainerRef.createComponent(componentFactory);

        const observable = this.waitLoading(observables);
        observable.subscribe(() => {
            loader.instance.setHide();

            setTimeout(() => loader.destroy(), 200);
        })
    }

    waitLoading(list: EntityCollectionServiceBase<any>[]): Observable<void> {
        return new Observable(subscriber  => {
            const arr = new Array(list.length).fill(false);

            list.forEach((observable, index) => {
                observable.loading$.pipe(
                    pairwise(),
                    filter(([prev, current]) => prev === true && current === false),
                    take(1)
                ).subscribe((val) => {
                    arr[index] = true;

                    if (arr.every(a => !!a)) {
                        subscriber.next();
                        subscriber.complete();
                    }
                })
            });
        });
    }
}
