import { Component, Input } from '@angular/core';

import { TooltipsService, ITooltipComponent } from 'src/components/tooltips/tooltips.service';
import { ANIMATION_OPACITY } from 'src/config';

@Component({
    selector: 'ct-comment-tooltip',
    templateUrl: 'comment-tooltip.component.html',
    styleUrls: ['comment-tooltip.component.less'],
    animations: ANIMATION_OPACITY
})
export class CommentTooltipComponent implements ITooltipComponent {
    @Input() text: string;
    @Input() description?: string;

    arrowColor: string;

    constructor(readonly tooltipsService: TooltipsService) {}

    get hasContent() {
        return this.text || this.description;
    }
}
