import { EntityMetadataMap } from '@ngrx/data';
import {
    Facility,
    ROSummaryReport,
    FacilityEmission,
    Document,
    Inventorization,
    DashboardReport,
} from './models';

const entityMetadata: EntityMetadataMap = {
    Fadaster: {},
    Quota: {},
    Facility: {
        filterFn: (fs: Facility[], pattern: { sector: number; text: string; organization: number }) => {
            const sector = pattern.sector || -1;
            return fs
                .filter(f => (!pattern.organization ? true : f?.organization.id == pattern.organization))
                .filter(f => sector === -1 || f.sector === sector)
                .filter(f => Facility.search(f, pattern.text));
        },
    },
    FacilityEmission: {
        filterFn: (facilities: FacilityEmission<Facility>[], pattern: { orgIds: number[] }) => {
            const fs = facilities.map(f => f.facility) as Facility[];
            const expanded = fs[0]?.id;
            if (expanded) {
                const ids = pattern.orgIds || fs.map(f => f.id);
                return facilities.filter(f => ids.includes((f.facility as Facility).organization.id));
            } else {
                return facilities;
            }
        },
    },
    Sector: {},
    ROSummaryReport: {
        filterFn: (summaries: ROSummaryReport[], pattern: { sector: number; name: string; orgIds: number[] }) => {
            const sector = pattern.sector || -1;
            const ids = pattern.orgIds || summaries.map(v => v.id);
            const name = pattern.name?.toLowerCase();
            return summaries
                .filter(s => sector === -1 || s.sectors.includes(sector))
                .filter(s => name ? s.name.toLowerCase().includes(name) : true)
                .filter(s => ids.includes(s.id));
        },
    },
    Transaction: {},
    Organization: {},
    Contact: {},
    DashboardReport: {
        selectId: (v: DashboardReport) => v.year
    },
    AbsorberSequestration: {},
    SectorEmissionFadaster: {},
    Document: {
        filterFn: (docs: Document[], pattern: {txt: string}) => {
            const needle = pattern.txt.toLowerCase();
            if (! needle) {
                return docs;
            }

            return docs.filter(d => d.name.toLowerCase().includes(needle) || d.num.toString().toLowerCase().includes(needle));
        }
    },
    Inventorization: {
        selectId: (inv: Inventorization) => inv.region,
    },
    Region: {},
};

const pluralNames = {
    Fadaster: 'Fadaster',
    Facility: 'Facilities',
    FacilityEmission: 'Facility_Emissions',
    Sector: 'Sectors',
    ROSummaryReport: 'Report_RO_Summary',
    DashboardReport: 'Report_Dashboard',
    Quota: 'Quotas',
    Organization: 'Organizations',
    Contact: 'Contacts',
    AbsorberSequestration: 'Absorber_Sequestrations',
    SectorEmissionFadaster: 'Sector_Emission_Fadaster',
    Document: 'Documents',
    Inventorization: 'Inventorization',
    Region: 'Regions',
};

export const entityConfig = {
    entityMetadata,
    pluralNames,
};
