import { Routes } from '@angular/router';

import { ROLE } from 'src/namespace';
import { roleExecutiveRoutes } from './role.executive.routes';
import { roleGovermentRoutes } from './role.goverment.routes';
import { roleRoRoutes } from './role.ro.routes';

export function routesByRole(role: string = 'default'): Routes {
    return {
        [ROLE.ro]: roleRoRoutes,
        [ROLE.executive]: roleExecutiveRoutes,
        [ROLE.government]: roleGovermentRoutes,
        default: [],
    }[role] || [];
}
