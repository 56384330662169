import { Component } from '@angular/core';
import { DashboardChartComponent } from '../dashboard-chart/dashboard-chart.component';

@Component({
    selector: 'dashboard-chart-big',
    templateUrl: './dashboard-chart-big.component.html',
    styleUrls: ['./dashboard-chart-big.component.less']
})
export class DashboardChartBigComponent extends DashboardChartComponent {

}
