import { Component } from '@angular/core';
import { ReportsFacadeService } from '../../../services/facade/reports-facade.service';
import { CollectionService } from '../../../services/collection/collection.service';
import { FacilityEmission } from 'src/namespace';
import { CommonTableClass } from '../common-table.class';

@Component({
    selector: 'manager-table-container',
    templateUrl: './ro-facility-reports.component.html',
    styleUrls: ['./ro-facility-reports.component.less'],
})
export class RoFacilityReportsContainer extends CommonTableClass {
    constructor(public facade: ReportsFacadeService, public collection: CollectionService) {
        super();

        this.collection.onUpdateReports$.subscribe((data: FacilityEmission[]) => {
            this.update(data);
        });
    }

    async revoke(id: number) {
        await this.facade.revokeReport(id);
        this.facade.loadReports();
    }
}
