<div class="organizations-list">
    <div class="organizations-list__item no_highlight_touch"
        (click)="selectAllItems()"
    >
        <ca-checkbox [checked]="allSelected"></ca-checkbox>
        <div class="organizations-list__text">
            {{TEXT.others.emissionsAll}}
        </div>
    </div>

    <div *ngFor="let org of organizations | sorting : byName">
        <div class="organizations-list__item no_highlight_touch"
            (click)="selectItem(org.id, !selectedOrganizations.includes(org))"
        >
            <ca-checkbox [checked]="selectedOrganizations.includes(org)"></ca-checkbox>
            <div class="organizations-list__text">
                {{org.name}}
            </div>
        </div>
    </div>
</div>
