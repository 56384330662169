import { Routes } from '@angular/router';
import { ReportsPageComponent } from 'src/pages/reports-page/reports-page.component';

import { ROLE } from 'src/namespace';
import { PAGES, RO_PAGES } from './navigation-config';
import { CommonPageComponent } from 'src/pages/common-page/common-page.component';
import { FacilitiesPageComponent } from 'src/pages/facilities-page/facilities-page.component';
import { RoDetailsPageComponent } from 'src/pages/ro-details-page/ro-details-page.component';
import { RoBalancePageComponent } from 'src/pages/ro-balance-page/ro-balance-page.component';
import { RoReportsPageComponent } from 'src/pages/ro-reports-page/ro-reports-page.component';
import { TableNormativeActsComponent } from 'src/pages/table-normative-acts-page/table-normative-acts.component';

export const roleRoRoutes: Routes = [
    {
        path: '',
        redirectTo: PAGES.ro,
        pathMatch: 'full'
    },
    {
        path: PAGES.ro,
        component: CommonPageComponent,
        children: [
            {
                path: RO_PAGES.details,
                component: RoDetailsPageComponent
            },
            {
                path: RO_PAGES.balance,
                component: RoBalancePageComponent
            },
            {
                path: RO_PAGES.facilities,
                component: FacilitiesPageComponent,
                data: {
                    hideOrganization: true
                }
            },
            {
                path: RO_PAGES.reports,
                component: RoReportsPageComponent
            },
            {
                path: 'reports_new',
                component: ReportsPageComponent,
            },
            {
                path: RO_PAGES.normative,
                component: TableNormativeActsComponent
            },
            {
                path: '**',
                redirectTo: RO_PAGES.details,
                pathMatch: 'full'
            }
        ],
        data: {
            allowed_roles: [ROLE.ro]
        }
    }
];
