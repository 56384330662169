import { Component } from '@angular/core';

import { MAP_MENU_ITEMS } from 'src/navigation/navigation-config';

@Component({
    selector: 'sidebar-menu',
    templateUrl: 'sidebar-menu.component.html',
    styleUrls: ['sidebar-menu.component.less'],
})
export class SidebarMenuComponent {
    pages = MAP_MENU_ITEMS;
}
