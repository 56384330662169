import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Region } from 'src/namespace';
import { TEXT } from '../../../../locales/text';

@Component({
    selector: 'regions-list',
    templateUrl: './regions-list.component.html',
    styleUrls: ['./regions-list.component.less']
})
export class RegionsListComponent  {
    @Input() regions: Region[];
    @Input() selectedRegionIds: number[];

    @Output() add: EventEmitter<number> = new EventEmitter();
    @Output() remove: EventEmitter<number> = new EventEmitter();
    @Output() removeAll: EventEmitter<void> = new EventEmitter();

    TEXT = TEXT;

    isScrolled = false;

    constructor() {
    }
}
