import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { AbsorberSequestration } from '../models';

@Injectable({ providedIn: 'root' })
export class AbsorberSequestrationService extends EntityCollectionServiceBase<AbsorberSequestration> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('AbsorberSequestration', serviceElementsFactory);
    }
}
