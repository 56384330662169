import { Component, Input } from '@angular/core';

import { TEXT } from 'src/locales/text';

export type BalanceValueSize = 'small' | 'medium' | 'large';

@Component({
    selector: 'balance-value',
    templateUrl: 'balance-value.component.html',
    styleUrls: ['balance-value.component.less']
})
export class BalanceValueComponent {
    @Input() value: number;
    @Input() size: BalanceValueSize = 'medium';
    @Input() isBalance = true;

    TEXT = TEXT;
}
