<dropdown-menu
    [label]="TEXT.table.status"
    [value]="statusItem?.label"
    [(showDropdown)]="statusDropdownOpened"
    (clickOutside)="statusDropdownOpened = false"
>
    <ca-selectbox-radio
        [(listItems)]="listStatus"
        (listItemsChange)="filterBy($event); statusDropdownOpened = false"
    ></ca-selectbox-radio>
</dropdown-menu>
