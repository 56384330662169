<div [ngClass]="{
        'input_for_dropdown__block': true,
        'input_for_dropdown__block-active': isOpenDropdown,
        'input_for_dropdown__block-error': isError,
        'input_for_dropdown__block-disable': isDisable
    }"
    (click)="toggleShow()"
>
    <div class="input_for_dropdown__text">{{inputValue ? inputValue : TEXT.others.notSelectedReport}}</div>
    <div class="input_for_dropdown__title">{{label}}</div>
    <ct-chevron
        class="input_for_dropdown__wrapper_arrow"
        [direction]="isOpenDropdown ? 'up' : 'down'"
    ></ct-chevron>
</div>
