import { enableProdMode, NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/browser';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EntityDataModule, DefaultDataServiceConfig, HttpUrlGenerator } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';

import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { TooltipsModule } from './components/tooltips/tooltips.module';
import { SpinnerModule } from './components/spinner/spinner.module';
import { LangSelectorModule } from './components/lang-selector/lang-selector.module';

import { environment } from './environments/environment';
import { CarbonTrailRoutingModule } from './carbon-trail-routing.module';
import { CoreModule } from './modules/core/core.module';
import { httpInterceptorProviders } from './services/http/interceptors';
import { LoginComponent } from './modules/core/containers/login/login.component';
import { MainPageComponent } from './modules/core/containers/main-page/main-page.component';

import { PagesModule } from './pages/pages.module';
import { LanguageService } from './services/language/language.service';
import { CoreEffects } from './store/effects';
import { entityConfig } from './store/entities.meta';
import { PluralHttpUrlGenerator } from './store/services/transport/plural-http-generator';
import { coreReducers } from 'src/store/reducers';

registerLocaleData(localeRu, 'ru');

if (environment.production) {
    enableProdMode();
}

if (environment.sentry) {
    Sentry.init({
        dsn: 'https://1f16129c4e604ccf9aa1225a1c29d109@o204789.ingest.sentry.io/5718907'
    });

    // @ts-ignore
    window.sentryCaptureException = Sentry.captureException;
    // @ts-ignore
    window.sentryAddBreadcrumb = Sentry.addBreadcrumb;
}

const defaultDataServiceConfig: DefaultDataServiceConfig = {
    root: environment.url_carbon,
};

@NgModule({
    imports: [
        HttpClientModule,
        StoreModule.forRoot({core: coreReducers}),
        EffectsModule.forRoot([CoreEffects]),
        EntityDataModule.forRoot(entityConfig),
        StoreDevtoolsModule.instrument(),

        CarbonTrailRoutingModule,
        PagesModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,

        DirectivesModule,
        PipesModule,
        TooltipsModule,
        LangSelectorModule,
        CoreModule,
        SpinnerModule,
    ],
    declarations: [
        LoginComponent,
        MainPageComponent,
    ],
    providers: [
        httpInterceptorProviders,
        {
            provide: DefaultDataServiceConfig,
            useValue: defaultDataServiceConfig
        },
        {
            provide: HttpUrlGenerator,
            useClass: PluralHttpUrlGenerator
        },
        {
            provide: LOCALE_ID,
            deps: [LanguageService],
            useFactory: (languageService: LanguageService) => languageService.getLanguage()
        },
    ],
    bootstrap: [MainPageComponent],
})
export class CarbonTrailModule {}
