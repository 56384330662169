import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TEXT } from 'src/locales/text';
import { CheckboxItem } from 'src/namespace';

@Component({
    selector: 'selectbox-status',
    templateUrl: './selectbox-status.component.html'
})
export class SelectboxStatusComponent implements OnInit {

    @Input() list: CheckboxItem[] = [];

    @Output() filterByItem = new EventEmitter<CheckboxItem>();

    TEXT = TEXT;

    statusItem: CheckboxItem;

    statusDropdownOpened = false;

    listStatus: CheckboxItem[] = [];

    ngOnInit() {
        this.listStatus = this.list.map((y, i) => ({
                id: y.id,
                label: y.label,
                selected: i === 0
            }));

        this.statusItem = this.listStatus[0];
    }

    filterBy(items: CheckboxItem[]) {
        this.statusItem = items.find(it => it.selected);
        this.filterByItem.emit(this.statusItem);
    }
}
