<ca-tooltip-placement>
    <div class="comment-tooltip"
        *ngIf="tooltipsService.currentTarget && hasContent"
        [@inOutAnimation]
    >
        <div class="comment-tooltip__text"
            [innerHTML]="text | safeHtml"
        ></div>
        <div class="comment-tooltip__secondary-text"
            *ngIf="description"
            [innerHTML]="description | safeHtml"
        ></div>
    </div>
</ca-tooltip-placement>
