import {
    Component,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import {
    Sortable,
    SortingColumn
} from 'src/modules/core/directives/sortable/sortable.directive';
import { LoaderService } from 'src/modules/core/services/loader.service';

import { TABLE_COLUMNS_NAME } from 'src/namespace';
import { TEXT, getLocalized } from 'src/locales/text';
import { Document } from 'src/store/models';
import { DocumentService } from 'src/store/services/document.service';
import { formatDayMonthYear } from 'src/utils';

@Component({
    selector: 'table-normative-acts',
    templateUrl: './table-normative-acts.component.html',
    styleUrls: ['./table-normative-acts.component.less']
})
export class TableNormativeActsComponent extends Sortable<Document> implements OnInit{
    TEXT = TEXT;

    documents: Document[];

    sortingColumns: SortingColumn<Document>[] = [
        {
            direction: 0,
            predicate: d => d.id,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.num],
            }
        },
        {
            direction: 0,
            predicate: d => d.order_date,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.created_at],
            }
        },
        {
            direction: 0,
            predicate: d => d.name,
            props: {
                title: TEXT.table[TABLE_COLUMNS_NAME.name],
            }
        },
    ];

    formatDayMonth = getLocalized(formatDayMonthYear);

    constructor(
        private docSrv: DocumentService,
        private viewContainerRef: ViewContainerRef,
        private loaderService: LoaderService,
    ) {
        super();
        this.setFilter('');
        this.docSrv.filteredEntities$.subscribe(docs => this.documents = docs);
        loaderService.waitLoadingComplete(
            viewContainerRef,
            [this.docSrv]
        );
    }

    ngOnInit() {
        this.docSrv.getAll();
    }

    trackById(_: number, item: Document) {
        return item.id;
    }

    onSearchTextChanged(searchText: string) {
        this.setFilter(searchText);
    }

    setFilter(txt = '') {
        this.docSrv.setFilter({txt})
    }
}
