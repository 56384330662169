import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CrossButtonModule } from '../cross-button/cross-button.module';
import { TagsListComponent } from './tags-list.component';

@NgModule({
    imports: [
        CommonModule,
        CrossButtonModule,
    ],
    declarations: [TagsListComponent],
    exports: [TagsListComponent]
})
export class TagsListModule {}
