<div class="carbon-capacity">
    <div class="carbon-capacity-bar carbon-capacity-bar--unknown"
        [style.width.%]="unknownWidth"
    ></div>
    <div class="carbon-capacity-bar carbon-capacity-bar--available"
        [style.width.%]="availableRatio"
    ></div>
    <div class="carbon-capacity-bar carbon-capacity-bar--used"
        [style.width.%]="usedRatio"
    ></div>
</div>
